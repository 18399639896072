import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickImageOnly = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'product_image_only',
    label: `image ${label.toLocaleLowerCase()}`,
  });
};

export default tagClickImageOnly;
