import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagSaveProductToList = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'add_wishlist',
    label: label?.toLowerCase(),
  });
};
export default tagSaveProductToList;
