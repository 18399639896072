import { useEffect } from 'react';
import { useUserPortal } from './context';
import GetStarted from './pages/GetSarted';
import Join from './pages/Join';
import Member from './pages/Member';

/* eslint-disable import/prefer-default-export */
export function UserPortalPager() {
  const [{ currentPage }] = useUserPortal();

  const hideIntercom = () => {
    if (typeof window !== 'undefined' && typeof (window as any)?.zE !== 'function') return;
    (window as any)?.zE('messenger', 'hide');
  };

  useEffect(() => {
    hideIntercom();
  }, []);

  switch (currentPage) {
    case 'GET_STARTED':
      return <GetStarted />;
    case 'JOIN_MEMBER':
      return <Join />;
    case 'ADDITIONAL_INFO':
    case 'MEMBER':
      return <Member />;
    default:
      return <GetStarted />;
  }
}
