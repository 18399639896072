import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickProductCard = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'product_card',
    label: label?.toLowerCase(),
  });
};

export default tagClickProductCard;
