/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */

import { ArrowLeft, Cross } from '@components/icons';
import { useUserPortal } from '../context';
import Logo from './Logo';

interface Props {
  onBack?: () => void;
  onClose?: () => void;
  hideBack?: boolean;
}

export const Header = ({ hideBack = false, onBack }: Props) => {
  const [_, { onCloseSidebar }] = useUserPortal();
  return (
    <header className="flex flex-row justify-between items-center px-1.8 bg-brand-dark-red text-white sticky top-0 z-50">
      {!hideBack && onBack ? (
        <button type="button" className="appearance-none" onClick={onBack}>
          <ArrowLeft fill="white" />
        </button>
      ) : (
        <div />
      )}
      <div className="flex flex-col items-center">
        <div className="pt-2.5">
          <Logo />
        </div>
        <p className="text-13 font-semibold pb-2">Your Account</p>
      </div>
      <button type="button" className="appearance-none" onClick={onCloseSidebar}>
        <Cross fill="#fff" />
      </button>
    </header>
  );
};
