import { triggerGTMEvent } from '../..';

const tagClickOrderOnline = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'online_order',
    label: 'order online',
  });
};

export default tagClickOrderOnline;
