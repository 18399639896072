const ChevronDown = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9055 0.285786L11.7145 0.0948137C11.5881 -0.0316046 11.3837 -0.0316046 11.2573 0.0948137L6.00151 5.35327L0.743044 0.0948137C0.616626 -0.0316046 0.412205 -0.0316046 0.285786 0.0948137L0.0948138 0.285786C-0.0316046 0.412204 -0.0316046 0.616625 0.0948138 0.743043L5.77019 6.4211C5.89661 6.54752 6.10103 6.54752 6.22745 6.4211L11.9028 0.743043C12.0319 0.616625 12.0319 0.412204 11.9055 0.285786Z"
        fill={props?.fill ?? '#464544'}
      />
    </svg>
  );
};

export default ChevronDown;
