import tagUpdateAddress from './tags/location/tagUpdateAddress';
import tagChangeStore from './tags/location/tagChangeStore';
import tagClickChangeStore from './tags/location/tagClickChangeStore';
import tagRemoveFromWishList from './tags/wishlist/tagRemoveFromWishList';
import tagSaveToWishList from './tags/wishlist/tagSaveToWishList';
import tagClickCarousel from './tags/carousel/tagClickCarousel';
import tagSearchTerm from './tags/search/tagSearchTerm';
import tagSearchResult from './tags/search/tagSearchResult';
import tagClickSearchBox from './tags/search/tagClickSearchBox';
import tagClickSearchButton from './tags/search/tagClickSearchButton';
import tagMouseOverMenu from './tags/navigation/tagMouseOverMenu';
import tagViewPDP from './tags/product/tagViewPDP';
import tagToggleWishList from './tags/navigation/tagToggleWishList';
import tagClickBrandLogo from './tags/navigation/tagClickBrandLogo';
import tagClickBreadCrumb from './tags/navigation/tagClickBreadCrumb';
import tagClickImageWithLink from './tags/product/tagClickImageWithLink';
import tagClickImageOnly from './tags/product/tagClickImageOnly';
import tagClickStoreFinder from './tags/navigation/tagClickStoreFinder';
import tagClickDeliveryFaq from './tags/navigation/tagClickDeliveryFaq';
import tagClickOrderOnline from './tags/navigation/tagClickOrderOnline';
import tagClickFollowOnInstagram from './tags/socials/tagClickFollowOnInstagram';
import tagClickMyProfile from './tags/navigation/tagClickMyProfile';
import tagClickBanner from './tags/banner/tagClickBanner';
import tagClickMenuItem from './tags/navigation/tagClickMenuItem';
import tagClickPromoCard from './tags/product/tagClickPromoCard';
import tagClickProductCampaign from './tags/product/tagClickProductCampaign';
import tagClickProductCard from './tags/product/tagClickProductCard';
import tagClickCatalogue from './tags/product/tagClickCatalogue';
import tagClickCategory from './tags/product/tagClickCategory';
import tagViewWishList from './tags/wishlist/tagViewWishList';
import tagClickPriceFilter from './tags/search/tagClickPriceFilter';
import tagClickFilterOptions from './tags/search/tagClickFilterOptions';
import tagClickSubscribeFlow from './tags/subscription/tagClickSubscribeFlow';
import tagClickPopularSearchTerm from './tags/search/tagClickPopularSearchTerm';
import tagClickRelatedSearchCategory from './tags/search/tagClickRelatedSearchCategory';
import tagClickSuggestedSearchTerm from './tags/search/tagClickSuggestedSearchTerm';
import tagSignupSuccess from './tags/signup/tagSignupSuccess';

declare global {
  interface Window {
    dataLayer: ITriggerGTMEvent[];
  }
}

interface ITriggerGTMEvent extends IGTMTagsProps {
  event: string;
  userId?: string;
}

export interface IGTMTagsProps {
  category?: string;
  label?: string;
}

export interface IGTMTags {
  tagUpdateAddress: (props: IGTMTagsProps) => void;
  tagChangeStore: (props: IGTMTagsProps) => void;
  tagRemoveFromWishList: (props: IGTMTagsProps) => void;
  tagSaveToWishList: (props: IGTMTagsProps) => void;
  tagToggleWishList: (props: IGTMTagsProps) => void;
  tagSearchTerm: (props: IGTMTagsProps) => void;
  tagSearchResult: (props: IGTMTagsProps) => void;
  tagMouseOverMenu: (props: IGTMTagsProps) => void;
  tagViewPDP: (props: IGTMTagsProps) => void;
  tagViewWishList: () => void;
  tagClickChangeStore: () => void;
  tagClickBrandLogo: (props: IGTMTagsProps) => void;
  tagClickStoreFinder: () => void;
  tagClickDeliveryFaq: (props: IGTMTagsProps) => void;
  tagClickOrderOnline: () => void;
  tagClickFollowOnInstagram: () => void;
  tagClickMyProfile: () => void;
  tagClickSearchBox: () => void;
  tagClickCarousel: (props: IGTMTagsProps) => void;
  tagClickSearchButton: (props: IGTMTagsProps) => void;
  tagClickImageOnly: (props: IGTMTagsProps) => void;
  tagClickBreadCrumb: (props: IGTMTagsProps) => void;
  tagClickImageWithLink: (props: IGTMTagsProps) => void;
  tagClickBanner: (props: IGTMTagsProps) => void;
  tagClickMenuItem: (props: IGTMTagsProps) => void;
  tagClickPromoCard: (props: IGTMTagsProps) => void;
  tagClickProductCampaign: (props: IGTMTagsProps) => void;
  tagClickProductCard: (props: IGTMTagsProps) => void;
  tagClickCatalogue: (props: IGTMTagsProps) => void;
  tagClickCategory: (props: IGTMTagsProps) => void;
  tagClickPriceFilter: (props: IGTMTagsProps) => void;
  tagClickFilterOptions: (props: IGTMTagsProps) => void;
  tagClickSubscribeFlow: (props: IGTMTagsProps) => void;
  tagClickPopularSearchTerm: (props: IGTMTagsProps) => void;
  tagClickRelatedSearchCategory: (props: IGTMTagsProps) => void;
  tagClickSuggestedSearchTerm: (props: IGTMTagsProps) => void;
  tagSignupSuccess: (props: IGTMTagsProps) => void;
}

const GTMTags: IGTMTags = {
  tagUpdateAddress,
  tagChangeStore,
  tagClickChangeStore,
  tagRemoveFromWishList,
  tagSaveToWishList,
  tagToggleWishList,
  tagClickCarousel,
  tagSearchTerm,
  tagSearchResult,
  tagClickSearchBox,
  tagClickSearchButton,
  tagMouseOverMenu,
  tagViewPDP,
  tagViewWishList,
  tagClickBrandLogo,
  tagClickBreadCrumb,
  tagClickImageWithLink,
  tagClickImageOnly,
  tagClickStoreFinder,
  tagClickDeliveryFaq,
  tagClickOrderOnline,
  tagClickFollowOnInstagram,
  tagClickMyProfile,
  tagClickBanner,
  tagClickMenuItem,
  tagClickPromoCard,
  tagClickProductCampaign,
  tagClickProductCard,
  tagClickCatalogue,
  tagClickCategory,
  tagClickPriceFilter,
  tagClickFilterOptions,
  tagClickSubscribeFlow,
  tagClickPopularSearchTerm,
  tagClickRelatedSearchCategory,
  tagClickSuggestedSearchTerm,
  tagSignupSuccess
};

export const triggerGTMEvent = (data: ITriggerGTMEvent) => {
  const userId = window.localStorage.getItem('userId');
  data = !userId || userId === 'null' ? data : { ...data, userId };
  window.dataLayer.push(data);

  if (process.env.NEXT_PUBLIC_ENABLE_GTM_DEBUG === 'true') {
    const styles = ['color: white', 'background: green', 'padding: 5px', 'border-radius: 5px'].join(
      ';'
    );
    const title = `GTMEvent - ${data.event}:${data.category}`;
    console.log('%c%s', styles, title, data);
  }
};

export default GTMTags;
