import { Component } from 'react';
import * as Sentry from '@sentry/react';
import TextOnlyContent from '@containers/Careers/TextOnlyContent';

type Props = {
  className?: string;
  fallbackComponent?: React.ReactNode;
  message?: React.ReactNode;
};

type State = {
  error: Error | null;
};

class ErrorBoundary extends Component<Props, State> {
  private _isMounted = false;

  state: State = {
    error: null,
  };

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({ error: null });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      // Based on https://github.com/getsentry/sentry-javascript/blob/6f4ad562c469f546f1098136b65583309d03487b/packages/react/src/errorboundary.tsx#L75-L85
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = errorInfo.componentStack;

      error.cause = errorBoundaryError;

      scope.setExtra('errorInfo', errorInfo);
      Sentry.captureException(error);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { error } = this.state;

    if (!error) {
      // when there's not an error, render children untouched
      return this.props.children;
    }

    const { fallbackComponent, message } = this.props;

    if (typeof fallbackComponent !== 'undefined') {
      return fallbackComponent;
    }

    return <TextOnlyContent>{message || error.toString()}</TextOnlyContent>;
  }
}

export default ErrorBoundary;
