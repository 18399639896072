import { triggerGTMEvent } from '../..';

const tagClickStoreFinder = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'store_finder',
    label: 'store finder',
  });
};

export default tagClickStoreFinder;
