const PhoneIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0367 11.6227L13.4578 10.0877C12.8074 9.81747 12.0445 10.0043 11.6033 10.5476L10.4382 11.9704C8.58516 10.9617 7.0418 9.41837 6.03281 7.56564L7.45734 6.3988C8.00824 5.94458 8.18965 5.19786 7.90875 4.54114L6.37734 0.962229C6.07148 0.255904 5.30156 -0.131378 4.55625 0.0405716L1.23645 0.810705C0.508711 0.976643 0 1.61368 0 2.35969C0 10.9828 7.01719 18 15.641 18C16.3874 18 17.0265 17.4924 17.1939 16.765L17.9603 13.4438C18.1301 12.6949 17.7434 11.9285 17.0367 11.6227ZM16.098 16.5129C16.0497 16.7228 15.8574 16.8754 15.6417 16.8754C7.63664 16.8754 1.1257 10.3644 1.1257 2.35934C1.1257 2.14179 1.27455 1.95575 1.48922 1.90653L4.80832 1.13625C4.84348 1.12801 4.87863 1.12417 4.91323 1.12417C5.09723 1.12417 5.26866 1.23294 5.34389 1.40651L6.876 4.9819C6.95742 5.17501 6.90469 5.39649 6.74297 5.53009L4.96758 6.98556C4.76719 7.15079 4.70391 7.43204 4.81992 7.66759C5.99344 10.0515 7.9527 12.0094 10.3359 13.1836C10.5694 13.3001 10.8538 13.2374 11.0194 13.0353L12.4745 11.2599C12.6047 11.0995 12.8317 11.0467 13.0194 11.1248L16.5913 12.6562C16.7989 12.7463 16.9132 12.9715 16.8627 13.1912L16.098 16.5129Z"
        fill="#464544"
      />
    </svg>
  );
};

export default PhoneIcon;
