const Envelope = ({ className = '', ...props }) => (
  <>
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M15.75 0.25H2.25C1.00723 0.25 0 1.25723 0 2.5V11.5C0 12.7428 1.00723 13.75 2.25 13.75H15.75C16.9928 13.75 18 12.7428 18 11.5V2.5C18 1.25723 16.991 0.25 15.75 0.25ZM2.25 1.375H15.75C16.3702 1.375 16.875 1.87984 16.875 2.5V3.76598L10.0125 8.91285C9.41484 9.36004 8.5841 9.36004 7.98645 8.91285L1.125 3.76562V2.5C1.125 1.88125 1.62984 1.375 2.25 1.375ZM16.875 11.5C16.875 12.1202 16.3702 12.625 15.75 12.625H2.25C1.62984 12.625 1.125 12.1202 1.125 11.5V5.14023L7.3125 9.8125C7.80891 10.1855 8.40445 10.3757 9 10.3757C9.59555 10.3757 10.1918 10.1852 10.6875 9.8125L16.875 5.14023V11.5Z"
        fill="#464544"
      />
    </svg>
  </>
);

export default Envelope;
