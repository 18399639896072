import css from 'styled-jsx/css';
import breakPoints from 'utils/styleConfig';

const styles = css`
  .profile-control {
    /* margin: 0 15px; */
  }

  .profile-control-button {
    background-color: transparent;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .profile-control-title {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  .profile-control-wrapper:hover {
    opacity: 0.75;
  }

  @media (max-width: ${breakPoints.medium}) {
    .profile-control-title {
      display: none;
    }
  }

  .profile-control-wrapper {
    position: relative;
  }
`;

export default styles;
