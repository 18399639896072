/* eslint-disable valid-typeof */
import { Button } from '@components/ui';
import { StoreSidebarSearchInput, useStoreSidebar } from '@lib/store-finder-sidebar';
import { FC, useCallback, useEffect, useState } from 'react';
import { Props as SwitchListViewProps } from './components/SwitchList';

type ListView = SwitchListViewProps['activeTab'];
interface Props {
  listComponent: any;
  deliveryOptionListComponent: any;
}

const AppLayout: FC<Props> = ({
  listComponent: ListComponent,
  deliveryOptionListComponent: DeliveryOptionListComponent,
}) => {
  const [hasPermissionStatus, setPermissionStatus] = useState<'prompt' | 'granted' | any>();
  const [currentViewList, setCurrentViewList] = useState<ListView>('FIND_STORE');
  const { sortByNearestStore, sortByLocation, isCurrentLocationFetching } = useStoreSidebar();

  const handleGetCurrentLocation = useCallback(() => {
    if (typeof window === 'undefined') return;
    if (hasPermissionStatus === 'granted') {
      sortByNearestStore();
    } else {
      // ask permission to location service
      navigator.geolocation.getCurrentPosition(
        () => sortByNearestStore(),
        (err: any) => {
          console.warn(`ERROR(${err.code}): ${err.message}`);
        }
      );
    }
  }, [hasPermissionStatus]);

  const handleSortByLocation = (value: any) => sortByLocation(value);

  // const handleChangeView = (view: ListView) => {
  //   setCurrentViewList(view);
  // };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((PermissionStatus) => setPermissionStatus(PermissionStatus.state));
  }, []);

  return (
    <>
      <style jsx global>
        {`
          ::-webkit-scrollbar {
            width: 5px;
          }
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          ::-webkit-scrollbar-thumb {
            background: #888;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      <div className="p-5 flex flex-col">
        {currentViewList === 'FIND_STORE' && (
          <>
            <Button
              variant="slim"
              onClick={handleGetCurrentLocation}
              loading={isCurrentLocationFetching}
              className="w-full"
            >
              {hasPermissionStatus ? 'Use my current location' : 'Allow location service'}
            </Button>
            <span className="my-2 text-center">or</span>
          </>
        )}
        {currentViewList === 'DELIVERY_OPTION' && (
          <>
            <h3 className="uppercase mb-4 text-14">Your delivery options</h3>
            <p className="leading-snug mb-2">
              Enter your address to see delivery options for doordash and UberEats
            </p>
          </>
        )}
        <StoreSidebarSearchInput onSelect={handleSortByLocation} searchScopes={['au']} />
        <div className="flex flex-col mt-8">
          {/* {stores && stores?.length !== 0 && (
            <SwitchList activeTab={currentViewList} onSelect={handleChangeView} />
          )} */}
          {currentViewList === 'FIND_STORE' && <ListComponent />}
          {currentViewList === 'DELIVERY_OPTION' && <DeliveryOptionListComponent />}
        </div>
      </div>
    </>
  );
};

export default AppLayout;
