/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import StoreProvider from '@lib/store-finder-page/provider';
import { StoreSidebarContextProvider } from '@lib/store-finder-sidebar/StoreSidebarProvider';
import { PropsWithChildren, useCallback, useContext } from 'react';
import EventEmitter from './EventEmitter';
import { LocalisationConnectorContext } from './LocalisationConnectorContext';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';

interface Props {}

type Function = () => void;

/* eslint-disable import/prefer-default-export */
export function LocalisationConnector({ children }: PropsWithChildren<Props>) {
  const [, setStore] = useStoreFinderStorage();
  const { setAsStore } = useContext(StoreProvider);
  const storeSidebarHandlers = useContext(StoreSidebarContextProvider);

  const emitter = new EventEmitter();

  const callback = useCallback((data) => {
    storeSidebarHandlers.setMyStore(data);
    setAsStore(data);
    setStore(data);
  }, []);

  emitter.on('store_finder_page_set_store', callback);
  emitter.on('store_finder_sidebar_set_store', callback);

  const contextValues = {
    emit: (event, payload) => emitter.emit(event, payload),
    on: (event, callback: Function) => emitter.on(event, callback),
  };

  return (
    <LocalisationConnectorContext.Provider value={contextValues}>
      {children}
    </LocalisationConnectorContext.Provider>
  );
}
