import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickSearchButton = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'search_button',
    label: label?.toLowerCase(),
  });
};
export default tagClickSearchButton;
