import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Ribbon from './Ribbon';
import MainV2 from './MainV2';
import Message from './Message';

const Header = ({ headerData }) => {
  const { banner_message, link_type, link } = headerData || {};

  return (
    <header>
      <Ribbon />
      <MainV2 />
      {banner_message && <Message message={banner_message} linkType={link_type} link={link} />}
    </header>
  );
};

Header.propTypes = {
  headerData: PropTypes.object,
};

Header.defaultProps = {
  headerData: null,
};

export default memo(Header);
