/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable indent */
import Link from 'next/link';
import PropTypes from 'prop-types';

export const getCollectionLocalPath = (link) => {
  if (!link) return null;
  return '/c/[...all]';
};

// collection landing page
export const getCLPLocalPath = (link) => {
  if (!link) return null;
  return '/c/l/[handle]';
};

// event landing page
export const getELPLocalPath = (link) => {
  if (!link) return null;
  return '/e/l/[handle]';
};

export const getProductLocalPath = (link) => {
  if (!link) return null;
  const linkHref = '/p/[handle]';
  return linkHref;
};

export const getPageLocalPath = (link) => {
  return '/pages/[handle]';
};

export const getAboutLocalPath = () => {
  return '/about/[handle]';
};

export const getCareersLocalPath = () => {
  return '/careers/[handle]';
};

export const getBrandLocalPath = () => {
  return '/brands';
};

export const getBlogLocalPath = (link) => {
  if (!link) return null;
  let linkHref = '/blog/[handle]';
  if (link.split('/').length === 2) {
    linkHref = '/blog';
  }
  return linkHref;
};

const SSRLink = ({
  children,
  linkType,
  linkUrl,
  title,
  gtmIdentifier,
  gtmTitle,
  gtmPosition,
  gtmValue,
  styles,
  className,
  onClick,
  target,
}) => {
  if (!linkUrl) return null;
  let localLinkPath = linkUrl;
  const newLink = linkUrl;

  switch (linkType) {
    case 'Collection':
      localLinkPath = getCollectionLocalPath(linkUrl);
      break;
    case 'Event':
      localLinkPath = getELPLocalPath(linkUrl);
      break;
    case 'Product':
      localLinkPath = getProductLocalPath(linkUrl);
      break;
    case 'Collection Landing Page':
      localLinkPath = getCLPLocalPath(linkUrl);
      break;
    case 'Brand':
      localLinkPath = getBrandLocalPath(linkUrl);
      break;
    case 'Blog':
      localLinkPath = getBlogLocalPath(linkUrl);
      break;
    case 'About':
      localLinkPath = getAboutLocalPath(linkUrl);
      break;
    case 'Careers':
      localLinkPath = getCareersLocalPath(linkUrl);
      break;
    case 'Page':
      if (linkUrl === '/') localLinkPath = '/';
      else localLinkPath = getPageLocalPath(linkUrl);
      break;
    case 'Catalogue':
    case 'External':
    default:
      return (
        <Link href={linkUrl} prefetch={false} legacyBehavior>
          <a
            title={title || ''}
            id={gtmIdentifier}
            data-gtm-id={gtmIdentifier}
            data-gtm-title={gtmTitle}
            data-gtm-position={gtmPosition}
            data-gtm-value={gtmValue}
            style={{ ...styles, display: 'block' }}
            className={className}
            onClick={onClick}
            target={target || 'self'}
          >
            {children}
          </a>
        </Link>
      );
  }

  return (
    <Link href={linkUrl} as={newLink} prefetch={false} legacyBehavior>
      <a
        title={title || ''}
        data-gtm-id={gtmIdentifier}
        data-gtm-title={gtmTitle}
        data-gtm-position={gtmPosition}
        data-gtm-value={gtmValue}
        style={styles}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    </Link>
  );
};

SSRLink.propTypes = {
  children: PropTypes.any,
  linkType: PropTypes.string,
  linkUrl: PropTypes.string,
  title: PropTypes.string,
  gtmIdentifier: PropTypes.string,
  gtmTitle: PropTypes.string,
  gtmPosition: PropTypes.string,
  gtmValue: PropTypes.string,
  styles: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

SSRLink.defaultProps = {
  children: null,
  linkType: null,
  linkUrl: null,
  title: null,
  gtmIdentifier: null,
  gtmTitle: null,
  gtmPosition: null,
  gtmValue: null,
  styles: null,
  className: null,
  onClick: null,
  target: 'self',
};

export default SSRLink;
