import { useUI } from '@components/ui';
import Image from 'next/image';
import styles from './styles';
import { useUserPortal } from '@lib/userportal';
import useSignupPopup from '@lib/hooks/useSignupPopup';
interface Props {
  onToggle?: () => void;
}

const SubscribeAndSave = ({ onToggle }: Props) => {
  const { isHideSubscribeFlow } = useUI();
  const [{ user, signupAlready }] = useUserPortal();
  if ((user && user.id) || signupAlready) {
    return null;
  }
  if (isHideSubscribeFlow) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        data-event-action="click"
        data-event-category="navigation"
        data-event-title="Subscribe and Save"
        className="transition-all duration-200 py-1 px-3 rounded bg-[#9508104D] hover:bg-[#950811] h-10.5 w-full relative"
        onClick={onToggle}
      >
        <div className="flex flex-row items-center justify-start gap-3">
          <Image width={16} height={18} src="/icons/header-user.svg" alt="Profile" />
          <div className="flex flex-col gap-0 leading-tight text-left w-full lg:w-[200px]">
            <p className="text-white text-body2 font-medium">Join now</p>
            <div className="flex justify-between items-center">
              <p className="text-brand-yellow text-body2 font-medium">Subscribe & save</p>
              <Image
                width={10}
                height={10}
                src="/icons/chevron-down-small-white.svg"
                alt="Profile"
              />
            </div>
          </div>
        </div>
      </button>
      <style jsx>{styles}</style>
    </>
  );
};

export default SubscribeAndSave;
