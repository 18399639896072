const ArrowRight = ({ fill, ...props }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.69434 0.160892L8.44084 0.414392C8.27303 0.582203 8.27303 0.853556 8.44084 1.02137L14.8141 7.39102H1.39281C1.15716 7.39102 0.964355 7.58382 0.964355 7.81947V8.17651C0.964355 8.41216 1.15716 8.60496 1.39281 8.60496H14.8141L8.44084 14.9782C8.27303 15.146 8.27303 15.4173 8.44084 15.5852L8.69434 15.8387C8.86215 16.0065 9.1335 16.0065 9.30131 15.8387L16.8385 8.30505C17.0063 8.13724 17.0063 7.86589 16.8385 7.69807L9.30131 0.164462C9.12993 -0.00691858 8.86215 -0.00691877 8.69434 0.160892Z"
        fill={fill ?? 'currentColor'}
      />
    </svg>
  );
};

export default ArrowRight;
