import Image from 'next/image';
import { useWishlist } from 'providers/Wishlist';
import styles from './styles';

const YourList = () => {
  const { wishlistCount, toggleListUI } = useWishlist();

  return (
    <>
      <div className="your-list-control">
        <button type="button" className="your-list-control-button" onClick={toggleListUI}>
          <div className="your-list-control-wrapper">
            <Image
              className="your-list-control-icon"
              width={20}
              height={20}
              src="/icons/header-save.svg"
              alt="My saved list"
            />
            {!!wishlistCount && <div className="your-list-control-number">{wishlistCount}</div>}
          </div>
        </button>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default YourList;
