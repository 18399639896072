import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickPriceFilter = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'search',
    category: 'price_filter',
    label: label?.toLowerCase(),
  });
};
export default tagClickPriceFilter;
