import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickBreadCrumb = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'breadcrumbs',
    label: label?.toLowerCase(),
  });
};

export default tagClickBreadCrumb;
