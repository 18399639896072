import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { useYupValidationResolver } from '@lib/hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import cn from 'clsx';
import API from '@lib/userportal/services/api';
import { EventContext } from 'providers/EventProvider';
import useLocalStorage from '@lib/hooks/useLocalStorage';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';
import { useUserPortal } from '@lib/userportal';
import { SIGN_STATUS } from '../type';
import dataLayer from '@lib/userportal/utils/dataLayer';

type EventType = 'store-finder' | 'store-detail';
type EventObj = {
  title: string;
  description: string;
};
type EventMappingType = {
  [key in EventType]?: EventObj;
};

type UserType = {
  name: string;
  email: string;
};

const events: EventMappingType = {
  'store-finder': {
    title: 'Save your store for next time by signing up to our newsletter',
    description: `We'll share the latest news, products and offers for your favourite store to help you get the most value out of every shop when you sign up today!`,
  },
  'store-detail': {
    title: 'Save your store for next time by signing up to our newsletter',
    description: `We'll share the latest news, products and offers for your favourite store to help you get the most value out of every shop when you sign up today!`,
  },
};

const validationSchema = yup.object<UserType>({
  email: yup.string().email().required('Please provide your email address'),
  name: yup.string().required('Please provide your first name'),
});

interface SignupProps {
  eventType: EventType;
}

function SignupWidget({ eventType }: SignupProps) {
  const [loading, setLoading] = useState(false);
  const [signStatus, setSignStatus] = useState<SIGN_STATUS>('Init');
  const [storeInfo] = useStoreFinderStorage(null);
  const [userId, saveUserId] = useLocalStorage('userId', null, { withExpiry: false });
  const [{ user, signupAlready }, { setUser, setSignupAlready }] = useUserPortal();
  const { gtm } = useContext(EventContext);
  const { emit } = useContext(LocalisationConnectorContext);
  const resolver = useYupValidationResolver(validationSchema);
  const event: EventObj = events[eventType];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserType>({ resolver });

  const handleSignup = (data: UserType) => {
    // setHasSession(true);
    validationSchema.isValid(data).then(async () => {
      // check email exist on omneo
      setLoading(true);
      const savedStore = storeInfo
        ? {
            id: storeInfo?.id,
            name: storeInfo?.name,
          }
        : null;
      API.getUserAvailability(data.email)
        .then(async (result) => {
          // new ??  sign up here
          if (result?.email?.available) {
            try {
              const response = await API.createUser({
                email: data.email,
                name: data.name,
                preferred_location_id: storeInfo?.id ?? undefined,
              });
              if (response.id && response.email) {
                saveUserId(response.id);
                setUser({
                  ...user,
                  id: response.id,
                  email: data.email,
                  name: data.name,
                  store: savedStore,
                });
                setSignupAlready(true);
                setSignStatus('Success');
                if (dataLayer) {
                  const eventName = eventType.replaceAll('-', '_');
                  gtm.tagSignupSuccess({ label: eventName });
                }
              }
            } catch (error) {
              setSignStatus('Failed');
              console.error('error', error);
            } finally {
              setLoading(false);
            }
          } else {
            setSignStatus('Already');
            setSignupAlready(true);
            setLoading(false);
          }
        })
        .then(() => {
          // if (dataLayer) {
          //   gtm.tagClickSubscribeFlow({ label: 'Signup_Check_Account' });
          // }
        });
    });
  };

  if ((user && user.id) || signupAlready) {
    if (!(signStatus === 'Already' || signStatus === 'Success')) {
      return null;
    }
  }

  const renderTitle = () => {
    if (eventType === 'store-finder') {
      if (user && user.store && user.store.id) {
        return `Last time you were here you set ${user.store.name} as your store. Sign up to the newsletter to save your store for next visit`;
      }
      return event.title;
    }
    return event.title;
  };

  const renderSignup = () => {
    return (
      <div>
        {(signStatus === 'Init' || signStatus === 'Failed') && (
          <form>
            <h1 className="signup-widget-title">{renderTitle()}</h1>
            <p className="signup-widget-desc">{event?.description}</p>
            <div>
              <input
                type="text"
                {...register('name')}
                className={cn('signup-widget-input', {
                  'signup-widget-inputNormal': !errors?.name,
                  'signup-widget-inputError': errors?.name,
                })}
                placeholder="First Name"
              />
              {errors?.name && <p className="signup-widget-errorTips">{errors?.name.message}</p>}
            </div>
            <div>
              <input
                type="text"
                {...register('email')}
                className={cn('signup-widget-input', {
                  'signup-widget-inputNormal': !errors?.email,
                  'signup-widget-inputError': errors?.email,
                })}
                placeholder="Email"
              />
              {errors?.email && <p className="signup-widget-errorTips">{errors?.email.message}</p>}
            </div>
            <button
              disabled={loading}
              className="signup-widget-button"
              onClick={handleSubmit(handleSignup)}
            >
              {loading ? 'Sign up, wait...' : 'Sign me up!'}
            </button>
          </form>
        )}
        {(signStatus === 'Already' || signStatus === 'Success') && (
          <>
            <h1 className="signup-widget-title">
              {signStatus === 'Success' ? 'You’re all signed up!' : 'You’re already signed up!'}
            </h1>
            <p className="signup-widget-desc">
              We'll share the latest news, products and offers for your favourite store to help you
              get the most value out of every shop when you sign up today!
            </p>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {eventType !== 'store-detail' && renderSignup()}
      {eventType === 'store-detail' && (
        <div className="mb-3 border border-solid border-black">
          <div className="flex items-center ">
            <span className="p-2 text-center bg-black text-white w-full">My store</span>
          </div>
          <div className="px-4 py-2">{renderSignup()}</div>
        </div>
      )}
    </>
  );
}

export default SignupWidget;
