const LeftArrow = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2349 4.16106L12.4884 4.41456C12.6562 4.58237 12.6562 4.85372 12.4884 5.02153L6.11514 11.3912H19.5364C19.772 11.3912 19.9648 11.584 19.9648 11.8196V12.1767C19.9648 12.4123 19.772 12.6051 19.5364 12.6051H6.11514L12.4884 18.9784C12.6562 19.1462 12.6562 19.4175 12.4884 19.5853L12.2349 19.8388C12.067 20.0066 11.7957 20.0066 11.6279 19.8388L4.0907 12.3052C3.92289 12.1374 3.92289 11.8661 4.0907 11.6982L11.6279 4.16463C11.7993 3.99325 12.067 3.99325 12.2349 4.16106Z"
        fill="#464544"
      />
    </svg>
  );
};

export default LeftArrow;
