import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import { breakpointSmall } from 'common/styles/breakpoints';
import Button from 'components/Button';
import TextField from 'components/Fields/TextField';
import { useRouter } from 'next/router';
import { useWishlist } from 'providers/Wishlist';
import { useEffect, useState, useContext } from 'react';
import API from '@lib/userportal/services/api';
import useLocalStorage from '@lib/hooks/useLocalStorage';
import { useUserPortal } from '@lib/userportal';
import { EventContext } from 'providers/EventProvider';
import dataLayer from '@lib/userportal/utils/dataLayer';

const validEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

const SaveList = () => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [message, setMessage] = useState();
  const [storeInfo] = useStoreFinderStorage(null);
  const [userId, saveUserId] = useLocalStorage('userId', null, { withExpiry: false });
  const [{ user }, { setUser }] = useUserPortal();
  const [sendReady, setSendReady] = useState(false);
  const [sending, setSending] = useState(false);
  const { saveList } = useWishlist();
  const { asPath } = useRouter();
  const { gtm } = useContext(EventContext);

  useEffect(() => {
    const sendReady = validEmail(email);
    setSendReady(sendReady);
  }, [email]);

  const signupWithEmail = async () => {
    const savedStore = storeInfo
      ? {
          id: storeInfo?.id,
          name: storeInfo?.name,
        }
      : null;
    API.getUserAvailability(email).then(async (result) => {
      // new ??  sign up here
      if (result?.email?.available) {
        try {
          const response = await API.createUser({
            email: email,
            name: name,
            preferred_location_id: storeInfo?.id ?? undefined,
          });
          if (response.id && response.email) {
            saveUserId(response.id);
            setUser({
              ...user,
              id: response.id,
              email: email,
              name: name,
              store: savedStore,
            });
            setSending(false);
            if(dataLayer){
              gtm.tagSignupSuccess({label: 'Wish_List' })
            }
          }
        } catch (error) {
          console.error('error', error);
        } finally {
        }
      }
    });
  };

  const sendList = async (email) => {
    const currentUrl = `${process.env.NEXT_PUBLIC_SITE_URL}${asPath}`;
    const sendingResult = await saveList(email, currentUrl);

    const message = sendingResult ? (
      <p>
        <strong>Thank you!</strong> <br /> We have emailed your list link to {email}.
      </p>
    ) : (
      <p>Sorry, unfortunately we were unable to send the list link to {email}.</p>
    );

    setMessage(message);
    if(sendingResult) {
      setEmail(null);
      setName(null);
    }
    setSending(false);
  };

  const onSend = (email) => {
    setSending(true);
    setMessage(null);

    signupWithEmail();
    return sendList(email);
  };

  return (
    <>
      <div className="save-list">
        <h4 className="save-list-title">
          Email your list to you so you have it saved when you visit your favourite store.
        </h4>
        <div className="save-list-row save-list-controls">
          <div className="save-list-group">
            <div className="save-list-input">
              <TextField
                placeholder="Your name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="save-list-input">
              <TextField
                type="email"
                placeholder="Your email address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="save-list-button">
            <Button
              onClick={() => onSend(email)}
              isDisabled={!sendReady}
              isLoading={sending}
              secondary
            >
              Send my list
            </Button>
          </div>
        </div>
        {message && <div className="save-list-row save-list-message">{message}</div>}
        <p>
          By doing this you are agreeing to sign up to our newsletter. Don't worry we will not spam
          you we just want to get value in your inbox
        </p>
      </div>
      <style jsx>
        {`
          .save-list {
            padding: 0 0 15px;
            display: flex;
            align-content: center;
            border-bottom: 1px solid #e6e6e6;
            font-size: 0.9em;
            flex-direction: column;
          }
          .save-list-title {
            padding: 0.6rem 0 0.3rem;
          }

          @media (max-width: ${breakpointSmall}) {
            .save-list {
              flex: 1 0 100%;
            }
          }
          .save-list-message {
            padding: 15px;
            background-color: white;
          }
          .save-list h3 {
            text-align: center;
          }
          .save-name-input {
            padding: 0.8rem 0 0.5rem;
          }
          .save-list-row {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 15px 0;
          }
          .save-list-group {
            flex: 8;
          }
          .save-list-input {
            padding: 0.3rem 0 0.3rem;
            padding-right: 10px;
          }
          .save-list-button {
            flex: 2;
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

export default SaveList;
