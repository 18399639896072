import startCase from 'lodash/startCase';

const TEMPORARILY_CLOSED_TAG = 'temporarily-closed';
const OPENING_SOON_TBC = 'opening-tbc';
const OPENING_DATE_PREFIX = 'opening-date';

export const renderStoreTag = (tags?: string[]) => {
  if (tags?.includes(TEMPORARILY_CLOSED_TAG)) {
    return `${startCase(TEMPORARILY_CLOSED_TAG)}`;
  } else if (tags?.includes(OPENING_SOON_TBC)) {
    return `Opening: TBC`;
  } else if (tags) {
    const openingDateTag = tags.find((tag) => tag.startsWith(OPENING_DATE_PREFIX));
    if (openingDateTag) {
      const datePart = openingDateTag.replace(`${OPENING_DATE_PREFIX}-`, '');
      const formattedDate = datePart.split('-').map(startCase).join(' ');
      return `Opening: ${formattedDate}`;
    }
  }
  return;
};
