import React, { FC } from 'react';
import Image from 'next/image';
import { clsx } from 'clsx';

interface Props {
  variant: 'large' | 'small';
}
const AcknowledgementOfCountry: FC<Props> = ({ variant }) => {
  return (
    <div
      className={clsx({
        'mt-16 md:hidden': variant === 'small',
        'hidden md:grid grid-cols-6 -mt-[132px]': variant === 'large',
      })}
    >
      <div className={clsx({ 'col-start-4 col-span-3': variant === 'large' })}>
        <p
          className={clsx('text-white font-medium', {
            ' text-13 ': variant === 'small',
            'text-base': variant === 'large',
          })}
        >
          Acknowledgement of Country
        </p>
        <div
          className={clsx({
            'flex flex-col lg:flex-row items-start mt-3 mb-20': variant === 'large',
          })}
        >
          <div
            className={clsx({
              'my-5': variant === 'small',
              ' w-1/5': variant === 'large',
            })}
          >
            <div className="block md:hidden">
              <Image
                src="/icons/footer-country.svg"
                width={80}
                height={31}
                alt="Acknowledgement of Country"
              />
            </div>
            <div className="hidden md:block md:mb-5 lg:mb-0">
              <Image
                src="/icons/footer-country-desktop.svg"
                width={104}
                height={40}
                alt="Acknowledgement of Country"
              />
            </div>
          </div>
          <p
            className={clsx('text-white text-[0.9em]', {
              'w-4/5 ml-0 md:ml-0 lg:ml-5  xl:ml-0 -mt-[5px]': variant === 'large',
            })}
          >
            We acknowledge the Traditional Owners of the land where we work and live. We pay our
            respects to Elders past, present, and emerging. We celebrate the stories, culture, and
            traditions of Aboriginal and Torres Strait Islander Elders of all communities who also
            work and live on this land.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AcknowledgementOfCountry;
