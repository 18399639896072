import { triggerGTMEvent } from '../..';

const tagClickMyProfile = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'my_profile',
    label: 'my profile',
  });
};

export default tagClickMyProfile;
