import { ShopifyClientContext } from '@lib/shopify/client';
import { ArticleSortKeys } from 'shopify-storefront-api-typings'; // Update with correct type for articles
import getClient from '@lib/shopify/shopify-gql-client-setting/ShopifyGQL';

import defaultArticleFilter from '../filters/default-article-filter';
import articlesByQuery from '@lib/shopify/queries/articles-by-query';

const getArticleSearchResults = async (
  clientSettings: ShopifyClientContext,
  term: string,
  filters: any[] = defaultArticleFilter,
  first: number = 10,
  after: string | null = null,
  sortKey: ArticleSortKeys | null = null,
  reverse: boolean = false
): Promise<any | null> => {
  try {
    const client = (context: ShopifyClientContext) => {
      return getClient(context.shopifyDomain, context.storefrontAccessToken);
    };

    const shopify = client(clientSettings);
    const query = decodeURI(term);

    const variables = {
      query,
      first,
      filters,
      after,
      sortKey,
      reverse,
      next: { tags: ['search', 'articles'] },
    };

    const searchResponse = await shopify(articlesByQuery, variables);

    if (!searchResponse?.articles) return null;
    const { articles } = searchResponse;
    const results = {
      id: 'search-article-results',
      handle: 'search-article-results',
      title: `Search article results for "${term}"`,
      description: `Search article results for "${term}"`,
      descriptionHtml: `Search article results for "${term}"`,
      updatedAt: new Date().toISOString(),
      relatedArticles: {
        edges: articles.edges,
        pageInfo: articles.pageInfo,
        filters: articles.articleFilters,
      },
    };
    return results;
  } catch (error) {
    console.log(`Could not fetch results for search: ["${term}"]`, error);
    return null;
  }
};

export default getArticleSearchResults;
