import React, { useRef, useEffect, forwardRef } from 'react';
import Field from '../BaseField';
import validation from '../validation';

const getCleanProps = (props) => {
  let inputProps = { ...props };
  delete inputProps.className;
  delete inputProps.iconBefore;
  delete inputProps.iconAfter;
  delete inputProps.onClear;

  return inputProps;
};

const onChange = (e, props, el) => {
  const { mask, onChange, value, disabled, loading } = props;
  if (!onChange || disabled || loading) {
    if (el.current) {
      el.current.blur();
    }

    return false;
  }

  if (mask) {
    if (!validation.mask(e.target.value, mask)) {
      e.target.value = value;
    }
  }

  onChange(e);
};

const onBlur = (e, props) => {
  const { onBlur } = props;
  if (onBlur) {
    onBlur(e);
  }
};

const onFocus = (e, props) => {
  const { onFocus } = props;
  if (onFocus) {
    onFocus(e);
  }
};

const TextField = forwardRef((props, ref) => {
  const { className, autoFocus, value, size } = props;
  const el = ref || useRef(null);

  // for location popup input font size
  const fontSize = size || 16;

  useEffect(() => {
    if (autoFocus) {
      el.current.focus();
    }
  }, []);

  let classNames = ['field-text'];
  if (className) {
    classNames.push(className);
  }

  return (
    <Field {...props} className={classNames.join(' ')}>
      <input
        {...getCleanProps(props)}
        value={value || ''}
        className="input"
        onChange={(e) => onChange(e, props, el)}
        onBlur={(e) => onBlur(e, props)}
        onFocus={(e) => onFocus(e, props)}
        ref={el}
      />
      <style jsx>
        {`
          .input {
            font-size: ${fontSize}px;
          }
        `}
      </style>
    </Field>
  );
});

export default TextField;
