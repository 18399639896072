import brandConfig from 'brandConfig';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

const Context = createContext(null);

function GoogleMapsAPIProvider({ children }) {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  const apiKey = brandConfig.brand.googleApiKey ?? null;

  useEffect(() => {
    const gmdom = document.getElementById('loadgoogleapis');
    if (gmdom == null) {
      const gmapScript = document.createElement('script');
      gmapScript.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
      gmapScript.id = 'loadgoogleapis';
      document.body.appendChild(gmapScript);
    }

    window.initMap = () => {
      setGoogleMapsLoaded(true);
    };
  }, []);

  return <Context.Provider value={{ googleMapsLoaded, apiKey }}>{children}</Context.Provider>;
}

GoogleMapsAPIProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

const useGoogleMapsAPI = () => useContext(Context);

export { GoogleMapsAPIProvider, useGoogleMapsAPI };
