const Chat = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9981 7.39286C15.5175 7.39286 15.9354 7.82277 15.9354 8.35714C15.9354 8.89152 15.5175 9.32143 14.9981 9.32143C14.4786 9.32143 14.0607 8.89152 14.0607 8.35714C14.0607 7.82277 14.4786 7.39286 14.9981 7.39286ZM9.9987 7.39286C10.5182 7.39286 10.9361 7.82277 10.9361 8.35714C10.9361 8.89152 10.5182 9.32143 9.9987 9.32143C9.47924 9.32143 9.06132 8.89152 9.06132 8.35714C9.06132 7.82277 9.47924 7.39286 9.9987 7.39286ZM4.99935 7.39286C5.51882 7.39286 5.93673 7.82277 5.93673 8.35714C5.93673 8.89152 5.51882 9.32143 4.99935 9.32143C4.47989 9.32143 4.06197 8.89152 4.06197 8.35714C4.06197 7.82277 4.47989 7.39286 4.99935 7.39286ZM9.9987 0C15.5214 0 19.9974 3.74063 19.9974 8.35714C19.9974 10.2696 19.2202 12.0214 17.9313 13.4317C18.5132 15.0147 19.724 16.3567 19.7435 16.3728C20.0013 16.654 20.0716 17.0638 19.9232 17.4174C19.7748 17.771 19.435 18 19.06 18C16.658 18 14.7637 16.9674 13.6271 16.1397C12.4984 16.5054 11.2798 16.7143 9.9987 16.7143C4.47598 16.7143 0 12.9737 0 8.35714C0 3.74063 4.47598 0 9.9987 0ZM9.9987 15.4286C11.104 15.4286 12.1976 15.2558 13.2483 14.9143L13.842 14.7214L14.3497 15.0911C15.248 15.746 16.6346 16.5094 18.357 16.6821C17.8883 16.0754 17.1931 15.0589 16.7635 13.8857L16.4861 13.1344L17.0212 12.5478C18.15 11.3183 18.7476 9.86786 18.7476 8.35714C18.7476 4.45982 14.8223 1.28571 9.9987 1.28571C5.17511 1.28571 1.24984 4.45982 1.24984 8.35714C1.24984 12.2545 5.17511 15.4286 9.9987 15.4286Z"
        fill="#464544"
      />
    </svg>
  );
};

export default Chat;
