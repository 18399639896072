import { triggerGTMEvent } from '../..';

const tagClickFollowOnInstagram = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'social_network',
    label: 'follow us on instagram',
  });
};

export default tagClickFollowOnInstagram;
