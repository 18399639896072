// import { useCustomer } from '@framework/customer'
import { Chat, Envelope, FAQ, PhoneIcon } from '@components/icons';
import ProfileSidebarItem from '../ProfileSidebarItem';
import { LinkType } from '../ProfileSidebarItem/ProfileSidebarItem';

type HelpSupportProps = {
  heading?: string;
};

const HelpSupport = ({ heading }: HelpSupportProps) => {
  // const { data: isCustomerLoggedIn } = useCustomer()

  const helpdata = {
    title: heading || 'HELP & SUPPORT',
    phone: '1800 861 021',
    email: 'service@rejectshop.com.au',
    faqtitle: 'FAQs',
    faqlink: '/customerservice/faq',
    livechat: 'Live chat',
    getHelp: 'Get help',
    getHelpLink: '/customerservice/contact-information',
  };
  return (
    <div className="px-6 flex flex-col">
      {heading ? (
        <p className="font-normal mb-4 text-label text-text-subdued">{heading}</p>
      ) : (
        helpdata.title && (
          <p className="font-normal mb-4 text-label text-text-subdued">{helpdata.title}</p>
        )
      )}

      <ProfileSidebarItem
        icon={PhoneIcon}
        link={`tel: ${helpdata?.phone}`}
        title={helpdata?.phone}
        underline={false}
        showChevRight={false}
        linkType={LinkType.PHONE}
      />
      <ProfileSidebarItem
        icon={Envelope}
        link={`mailto: ${helpdata?.email}`}
        title={helpdata?.email}
        underline={false}
        linkType={LinkType.EMAIL}
      />
      <ProfileSidebarItem
        icon={FAQ}
        link={helpdata?.faqlink}
        title={helpdata?.faqtitle}
        underline={false}
        linkType={LinkType.INTERNAL}
      />
      <ProfileSidebarItem
        icon={Chat}
        link={helpdata?.getHelpLink}
        title={helpdata?.getHelp}
        underline={false}
        linkType={LinkType.INTERNAL}
      />
      {/* <ProfileSidebarItem icon={Chat} title={helpdata?.livechat} underline={false} /> */}
    </div>
  );
};

export default HelpSupport;
