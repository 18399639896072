import React from 'react';
import Image from 'next/image';

const index = () => {
  return (
    <div>
      <div className="loader">
        <span className="loader__dot">.</span>
        <span className="loader__dot">.</span>
        <span className="loader__dot">.</span>
      </div>
    </div>
  );
};

export default index;
