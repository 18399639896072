import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickPopularSearchTerm = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'search',
    category: 'popular_search_term',
    label: label?.toLowerCase(),
  });
};

export default tagClickPopularSearchTerm;
