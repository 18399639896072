import { useContext } from 'react';
import StoreProvider from '../provider';

const useStore = () => {
  const context = useContext(StoreProvider);

  if (context === null) throw new Error('useStore must be inside StoreProvider');

  return context;
};

export default useStore;
