import { gql } from 'graphql-request';
import { SEARCH_PRODUCT_FIELDS } from '@lib/shopify/queries/fragments/search-product-fields';
const productsByQuery = gql`
  query search(
    $query: String!
    $filters: [ProductFilter!]
    $first: Int
    $after: String
    $sortKey: SearchSortKeys
    $reverse: Boolean
  ) {
    search(
      query: $query
      productFilters: $filters
      first: $first
      after: $after
      sortKey: $sortKey
      reverse: $reverse
      types: [PRODUCT, PAGE, ARTICLE]
    ) {
      edges {
        cursor
        node {
          ... on Product {
            ...SearchProductFields
          }
        }
      }
      pageInfo {
        hasNextPage
      }
      productFilters {
        id
        label
        type
        values {
          count
          id
          input
          label
        }
      }
    }
  }
  ${SEARCH_PRODUCT_FIELDS}
`;
export default productsByQuery;
