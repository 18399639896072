const CrossGrey = (props) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.05.343a.5.5 0 1 0-.707.707L5.293 6l-4.95 4.95a.5.5 0 1 0 .707.707L6 6.707l4.95 4.95a.5.5 0 1 0 .706-.707L6.706 6l4.95-4.95a.5.5 0 1 0-.707-.707L6 5.293 1.05.343Z"
      fill="#464544"
    />
  </svg>
);

export default CrossGrey;
