import React from 'react';

import { LatLng, State, StoreItem, SuggestionType, View } from './types';

export interface StoreContextValues extends State {
  changeView: (view: View) => void;
  setCurrentPosition: (position: LatLng) => void;
  setStatusCurrentPosition: (s: boolean) => void;
  setAsStore: (s: StoreItem) => void;
  setSearchLocation: (s: SuggestionType) => void;
  getStoreSorted: (s: StoreItem[]) => Promise<void>;
}

const StoreProvider = React.createContext<StoreContextValues>(
  {
    googleMapApiKey: null,
    componentRestrictions: { country: 'au' },
    view: 'list',
    currentPosition: null,
    currentPositionStatus: {
      loading: false,
    },
    stores: [],
    components: {
      mapUI: null,
      sidebarUI: null,
      tableUI: null,
    },
    yourStore: null,
    changeView: (view: View) => null,
    setCurrentPosition: (position: LatLng) => null,
    setStatusCurrentPosition: (s: boolean) => null,
    setAsStore: (s: StoreItem) => null,
    setSearchLocation: (s: SuggestionType) => null,
    getStoreSorted: async (s: StoreItem[]) => Promise.resolve(),
  }
);

export default StoreProvider;
