/* eslint-disable react/jsx-no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useUI } from '@components/ui';
import { useContext, useEffect, useState } from 'react';
import { EventContext } from 'providers/EventProvider';
import Button from '../components/Button';
import { Header } from '../components/Header';
import HeroSection from '../components/HeroSection';
import PromotionAccordion from '../components/PromotionAccordion';
import Spinner from '../components/Spinner';
import { useUserPortal } from '../context';
import API from '../services/api';
import dataLayer from '../utils/dataLayer';

export default function Member() {
  const [{ user, promotions, pageHeaders }, { setPage, setAuthenticatedUser }] = useUserPortal();
  const [loading, setLoading] = useState(false);
  const { closeSidebar } = useUI();
  const { gtm } = useContext(EventContext);

  const initialCurrentUser = async () => {
    try {
      setLoading(true);
      const currentUser = await API.getCurrentUser(user.id);
      if (currentUser) {
        // Set logged in user
        setAuthenticatedUser(currentUser);
        setLoading(false);
      }

      if (dataLayer) {
        gtm.tagClickSubscribeFlow({ label: 'Account_Created' });
      }
    } catch (error) {
      // catch errors
      setLoading(false);
    }
  };

  useEffect(() => {
    initialCurrentUser();
  }, []);

  const handleNext = () => {
    // setPage('MEMBER');
    closeSidebar();
  };

  const handleVisitProfile = () => setPage('JOIN_MEMBER');

  const handleBack = () => setPage('ADDITIONAL_INFO');

  return (
    <div className="flex flex-col">
      <Header onBack={handleBack} />
      {loading ? (
        <Spinner title="Loading your profile..." />
      ) : (
        <div className="flex flex-col gap-4 px-5 py-10">
          <HeroSection
            title={`WELCOME ${user?.name ?? 'NEW MEMBER'}`.toUpperCase()}
            subtitle={pageHeaders?.welcome_page_subtitle}
          />
          {/* <section className="my-4 flex w-full justify-center items-center">
            <MemberCard user={user} />
          </section> */}
          <section className="flex flex-col gap-2">
            <p className="my-2 text-13">CURRENT PROMOTIONS</p>
            <div className="flex flex-col gap-6">
              {promotions?.map((promotion, key) => {
                return <PromotionAccordion promotion={promotion} key={key} />;
              })}
            </div>
          </section>
          <footer className="flex flex-col gap-2 mt-8">
            <Button onClick={handleNext}>Continue shopping</Button>
            <Button variant="outline" onClick={handleVisitProfile}>
              Go to my profile
            </Button>
          </footer>
        </div>
      )}
    </div>
  );
}
