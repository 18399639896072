export default {
    mask(string, regex){
        if(!regex || !this.maskRegex.hasOwnProperty(regex) || string === ''){return true}
        var pattern = new RegExp(this.maskRegex[regex]);
        return pattern.test(string);
    },
    maskRegex : {
        number : /^[0-9]+\.?[0-9]?$/,
        float : /^[0-9]+\.?[0-9]*$/,
        integer : /^[1-9][0-9]*$/,
        phone : /^[0-9+\-()]+$/,
        mobile: /^0$|^04$|^04[0-9]{0,8}$/,
        email:/^[a-zA-Z0-9-_|.!#&+]*$|^[a-zA-Z0-9-_|.!#&+]+@[a-zA-Z0-9-.]*$/,
        url:/^[a-zA-Z0-9-_.!#&+!*'(),/;:@=?]*$/,
        day : /^[0-2][0-9]?$|^[3][0-1]?$/,
        month : /^0[1-9]?$|^1[0-2]?$/,
        year : /^[1-2]$|^[1-2][09]$|^[1-2][09][0-9]{0,2}$/,
        slug: /^[a-z0-9-]*$/,
        snake: /^[a-z0-9_]*$/
    },
    validate(string, regex){
        if(!regex || !this.validationRegex.hasOwnProperty(regex) || string === ''){return false}
        var pattern = new RegExp(this.validationRegex[regex]);
        return pattern.test(string);
    },
    validationRegex : { 
        date: /-?[0-9]+/,
        mobile: /^[0-9+]{12}$/,
        email: /^(([^<>()[\]\\.,;:\s"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }
};