import ShopifyBuy from 'shopify-buy';
import { pluck } from 'utils';
import config from 'brandConfig';

export default () => {
  const handle = pluck(config, 'contexts.0.shopifyHandle');
  const token = pluck(config, 'contexts.0.shopifyStorefrontToken');

  return ShopifyBuy.buildClient({
    domain: `${handle}.myshopify.com`,
    storefrontAccessToken: token,
  });
};
