/* eslint-disable consistent-return */
import * as Sentry from '@sentry/nextjs';
import { slugify } from '@lib/store-finder-sidebar/utils/slugify';
import { getApiBaseUrl } from '@utils/url';
import { StoreItem } from '../types';

// eslint-disable-next-line import/prefer-default-export
export async function getNearestStore({ lat, lng, returnItems = 999 }) {
  try {
    const response = await fetch(`${getApiBaseUrl()}/api/locations/nearest-store`, {
      method: 'POST',
      body: JSON.stringify({
        location: {
          lat,
          lng,
        },
        returnItems: returnItems,
      }),
    }).then((r) => r.json());

    const locations = response ?? [];

    const allStores: StoreItem[] = locations.map((s) => {
      return {
        id: s?.id,
        name: s?.name,
        tags: s?.tags,
        slug: slugify(s?.name),
        address: [
          s?.address?.address_line_1,
          s?.address?.address_line_2,
          s?.address?.city,
          s?.address?.state,
          s?.address?.country,
          s?.address?.postcode,
        ].join(', '),
        email: s?.email ?? 'email@domain.com',
        phone: s?.phone ?? '',
        handle: s?.external_id,
        location: {
          lat: s?.lat,
          lng: s?.lng,
        },
        state: s?.address.state ?? '',
        clickAndCollectAvailable: false,
        distance: 0,
      };
    });

    return allStores;
  } catch (error) {
    Sentry.captureException(error);
  }
}
