import React from 'react';
import Image from 'next/image';
import { useUI } from '@components/ui/context';

const index = () => {
  const { openSearchPanel, isSearchFormFocused, closeSidebar } = useUI();
  return (
    <div>
      <div className="mx-6 md:mx-0 pb-3 md:pb-0">
        <form>
          <div className="flex items-start">
            <div className="absolute px-[14px] py-[10px] mt-[3px] w-[50px]">
              <Image src="/icons/searchIcon.svg" width={18} height={18} alt="Search" />
            </div>

            <input
              onFocus={() => {
                closeSidebar();
                openSearchPanel();
              }}
              type="text"
              className=" w-full py-3 pr-0 pl-12 rounded-[50px] "
              placeholder="Search for a product, category or article…"
              disabled={isSearchFormFocused}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default index;
