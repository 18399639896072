import React, { useState, useRef, useEffect, useContext } from 'react';
import Image from 'next/image';
import { useUI } from '@components/ui/context';
import Link from 'next/link';
import { detectClickOutside } from '@lib/helpers/search-panel-detect-click-outside';
import { clsx } from 'clsx';
import ShopifySearchInputField from '@components/ShopifySearch/ShopifySearchInputField';
import ProductCard from '@components/ProductCard';
import { EventContext } from 'providers/EventProvider';
import { parseShopifyProduct } from '@utils/parseShopifyProduct';
import ShopifyArticleSearchCard from '@components/ShopifyArticleSearchCard/ShopifyArticleSearchCard';
import Highlighter from 'react-highlight-words';

import ThreeDotsLoader from 'components/ThreeDotsLoader';

const index = () => {
  const { closeSearchPanel, isSearchFormFocused, shopifySearchResults } = useUI();

  const [query, setQuery] = useState('');
  const [suggestedKeywords, setSuggestedKeywords] = useState<string[]>([]);
  const [suggestedCollections, setSuggestedCollections] = useState<string[]>([]);
  const [isGettingSearchResults, setIsGettingSearchResults] = useState<boolean>(false);

  const searchInput = useRef(null);

  const searchPanel = useRef(null);

  const { gtm } = useContext(EventContext);

  if (Boolean(detectClickOutside(searchPanel))) {
    closeSearchPanel();
  }

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, []);

  const seeAllResults = (e, type) => {
    e.preventDefault();
    gtm.tagSearchResult({ label: query });
    window.location.href = `/search?searchTerm=${query}&searchType=${type}`;
    closeSearchPanel();

    setTimeout(() => {
      setQuery('');
    }, 800);
  };

  const searchItemOnClickHandler = (suggestedSearchQuery) => {
    gtm.tagClickSuggestedSearchTerm({ label: suggestedSearchQuery });

    window.location.href = `/search?searchTerm=${suggestedSearchQuery}&searchType=Product`;
    closeSearchPanel();
  };

  return (
    <>
      <div
        className={clsx('searchPanelContainer  duration-500 ease-out transition-all', {
          ' -translate-y-full': !isSearchFormFocused,
          'translate-y-0': isSearchFormFocused,
        })}
        ref={searchPanel}
      >
        <div className="searchPanelContent">
          <div className="row">
            <p className="text-text-subdued text-13 font-medium uppercase md:text-16">Search</p>
            <div
              className="closeIcon"
              onClick={() => {
                closeSearchPanel();

                setTimeout(() => {
                  setQuery('');
                }, 800);
              }}
            >
              <Image
                src="/icons/crossIcon.svg"
                width={12}
                height={12}
                alt="close search panel icon"
              />
            </div>
          </div>
          {/* search form */}
          <div className="inputField">
            <div className="inputSearchIcon">
              <Image
                src="/icons/resultsPanelSearchIcon.svg"
                width={18}
                height={18}
                alt="search icon"
              />
            </div>

            <ShopifySearchInputField
              setQuery={setQuery}
              query={query}
              setSuggestedKeywords={setSuggestedKeywords}
              setSuggestedCollections={setSuggestedCollections}
              setIsGettingSearchResults={setIsGettingSearchResults}
            />

            {!isGettingSearchResults &&
              query.length >= 3 &&
              shopifySearchResults?.products?.length === 0 &&
              shopifySearchResults?.articles?.length === 0 && (
                <div className="mt-8">
                  Uh oh, we can’t seem to find a search result. Looking for our FAQs?{' '}
                  <span className="underline" onClick={() => closeSearchPanel()}>
                    <Link href="/customerservice/faq" prefetch={false}>
                      Right this way
                    </Link>
                  </span>
                  .
                </div>
              )}

            {query.length >= 3 && isGettingSearchResults && (
              <div className="my-3">
                <ThreeDotsLoader />
              </div>
            )}

            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/5">
                {/* suggested keywords */}
                {query !== '' && suggestedKeywords?.length !== 0 && (
                  <div className="mt-6">
                    <p className="text-text-subdued text-13 font-medium uppercase md:text-16 mb-6">
                      Suggested Keywords
                    </p>
                    <div className="flex md:flex-col overflow-x-auto scrollbar-hide">
                      {suggestedKeywords.map((item) => {
                        return (
                          <div className="text-13 mr-4 py-2 px-4 text-text-dark cursor-pointer max-w-max font-medium whitespace-nowrap bg-white md:mb-3 w-52">
                            <p
                              className="truncate capitalize"
                              onClick={() => searchItemOnClickHandler(item)}
                            >
                              <Highlighter
                                highlightClassName="highlightKeyword"
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={item}
                              />
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* suggested categories */}
                {query !== '' && suggestedCollections?.length !== 0 && (
                  <div className="mt-6">
                    <p className="text-text-subdued text-13 font-medium uppercase md:text-16 mb-6">
                      Categories
                    </p>
                    <div className="flex overflow-x-auto md:block hide-scrollbar ">
                      {suggestedCollections.map((item: any) => (
                        <div
                          className="text-13 mr-4 py-2 px-4 text-text-dark cursor-pointer max-w-max font-medium whitespace-nowrap capitalize bg-white md:mb-3"
                          onClick={() => {
                            gtm.tagClickRelatedSearchCategory({ label: item.title });
                            closeSearchPanel();
                            setTimeout(() => {
                              setQuery('');
                            }, 800);
                          }}
                          key={item.handle}
                        >
                          <Link href={`/c/${item.handle}`}>{item.title}</Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="md:w-4/5">
                {/* products search results */}
                {shopifySearchResults && shopifySearchResults.products.length !== 0 && (
                  <div className="mt-6">
                    <p className="text-text-subdued text-13 font-medium uppercase md:text-16 mb-6">
                      products
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                      {shopifySearchResults.products?.map((item) => (
                        <div
                          onClick={() => {
                            setTimeout(() => {
                              setQuery('');
                            }, 800);
                          }}
                        >
                          <ProductCard
                            product={parseShopifyProduct(item)}
                            insights={null}
                            hasMinHeightTitle={false}
                          />
                        </div>
                      ))}
                    </div>

                    <div
                      className="flex items-center cursor-pointer mt-8"
                      onClick={(e) => seeAllResults(e, 'Product')}
                    >
                      <span className="pr-2">See all products results</span>
                      <Image
                        alt="see all products results"
                        src="/icons/arrowRightBlack.svg"
                        width={12}
                        height={12}
                      />
                    </div>
                  </div>
                )}

                {/* article search results */}
                {shopifySearchResults && shopifySearchResults.articles.length !== 0 && (
                  <div className="mt-6">
                    <p className="text-text-subdued text-13 font-medium uppercase md:text-16 mb-6">
                      Blog Articles
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                      {shopifySearchResults.articles.map((article) => (
                        <div
                          onClick={() => {
                            closeSearchPanel();

                            setTimeout(() => {
                              setQuery('');
                            }, 800);
                          }}
                        >
                          <ShopifyArticleSearchCard
                            handle={article.node.handle}
                            name={article.node.title}
                            image={article.node.image.transformedSrc}
                            description={undefined}
                          />
                        </div>
                      ))}
                    </div>

                    <div
                      className="flex items-center cursor-pointer mt-8"
                      onClick={(e) => seeAllResults(e, 'Content')}
                    >
                      <span className="pr-2">See all blog articles results</span>
                      <Image
                        alt="see all blog articles results"
                        src="/icons/arrowRightBlack.svg"
                        width={12}
                        height={12}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
