const Grid6 = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="9.5" y="16" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="3" y="16" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="9.5" y="9.5" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="3" y="9.5" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="9.5" y="3" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="3" y="3" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="16" y="9.5" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="16" y="16" width="5" height="5" rx="0.5" fill="currentColor" />
      <rect x="16" y="3" width="5" height="5" rx="0.5" fill="currentColor" />
    </svg>
  );
};

export default Grid6;
