import { useUI } from '@components/ui/context';
import CompareAtPrice from 'components/Product/CompareAtPrice/index';
import Price from 'components/Product/Price';
import { StorefrontContext } from 'providers/Storefront';
import { useContext, useEffect, useState } from 'react';
import { pluck } from 'utils';
import { EventContext } from 'providers/EventProvider';
import ProductAvailability from '../ProductAvailability';
import SaveToList from '../SaveToList';
import Badges from './Badges';
import Image from './Image';
import Link from 'next/link';

const ProductCard = ({ product, insights, hasMinHeightTitle }) => {
  if (!product) return null;

  const { closeSearchPanel } = useUI();

  const { gtm } = useContext(EventContext);

  const { sku, handle, title, compareAtPrice, price, named_tags: namedTags, tags } = product;
  const multibuyOffer = namedTags?.multibuy_offer;

  let availabilityTag = namedTags?.availability || namedTags?.Availability;
  // For ShopifyProductHandles component
  if (!availabilityTag) {
    availabilityTag = tags
      ?.find((tag) => tag.toLowerCase().includes('availability'))
      ?.toLowerCase()
      ?.replace('availability:', '');
  }

  const { deliveryStore } = useContext(StorefrontContext);
  const [availableInStore, setAvailableInStore] = useState(false);
  const [storeLocationName, setStoreLocationName] = useState('');

  const availableStoreIds = product?.meta?.availablity?.store_location_ids || [];
  const titleMinHeight = hasMinHeightTitle ? '48px' : '24px';

  useEffect(() => {
    const storeLocationId = deliveryStore?.external_id;
    setStoreLocationName(deliveryStore?.name);

    if (availableStoreIds.includes(storeLocationId)) {
      setAvailableInStore(true);
    } else {
      setAvailableInStore(false);
    }
  }, [product]);

  const handleClickTracking = () => {
    if (insights) {
      insights();
    }
    gtm.tagClickProductCard({ label: `${sku}: ${title}` });
    closeSearchPanel();
  };

  return (
    <div className={`product-card`}>
      <Link href={`/p/${handle}`} onClick={handleClickTracking} prefetch={false}>
        {product.imageUrl ? (
          <Image src={product.imageUrl} />
        ) : (
          <Image src={pluck(product, 'images.0')} />
        )}

        <Badges product={product} multibuyOffer={multibuyOffer} />

        <div className="product-card-info">
          {availabilityTag && <ProductAvailability tag={availabilityTag} />}
          {availableInStore && (
            <div className="flex items-center text-sm mb-4 leading-4">
              <Image className="w-10" src="/icons/available-icon-green.svg" />
              <span>{`Available at ${storeLocationName}`}</span>
            </div>
          )}
          <div className="product-card-title">{title}</div>
          <div
            className="product-card-price-container"
            style={{ color: compareAtPrice > price ? '#df3c36' : '#474747' }}
          >
            <Price
              classNameMain="product-card-price-main"
              classNameSecondary="product-card-price-secondary"
              price={price}
              className=""
            />
            {compareAtPrice > price && <CompareAtPrice compareAtPrice={compareAtPrice} />}
          </div>
        </div>
      </Link>

      <div className="product-card-controls">
        <div className="product-card-controls-list">
          <SaveToList
            handle={handle}
            title={title}
            price={price}
            image={pluck(product, 'images.0') || null}
            sku={pluck(product, 'sku') || null}
          />
        </div>
      </div>
      <style jsx>
        {`
          .product-card-title {
            min-height: ${titleMinHeight};
          }
        `}
      </style>
    </div>
  );
};

export default ProductCard;
