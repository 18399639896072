const ChevronLeft = ({ ...props }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.97232 11.9055L7.16329 11.7145C7.28971 11.5881 7.28971 11.3837 7.16329 11.2573L1.90483 6.00151L7.16329 0.743044C7.28971 0.616626 7.28971 0.412205 7.16329 0.285786L6.97232 0.0948138C6.8459 -0.0316046 6.64148 -0.0316046 6.51506 0.0948138L0.837001 5.77019C0.710583 5.89661 0.710583 6.10103 0.837001 6.22745L6.51506 11.9028C6.64148 12.0319 6.8459 12.0319 6.97232 11.9055Z"
        fill="#464544"
      />
    </svg>
  );
};

export default ChevronLeft;
