import { Environment } from '@lib/store-context/Context';

const production: Environment = {
  contexts: [
    {
      countryCode: 'AU',
      language: 'en-AU',
      currencyCode: 'AUD',
      shopifyDomain: 'therejectshop.myshopify.com',
      shopifyBrandedDomain: 'therejectshop.myshopify.com',
      shopifyStorefrontToken: '49911a9ce0c164fb103d497efe652eac',
    },
  ],
  services: {
    algolia: {
      applicationID: 'AMPAW8YCCP',
      apiKey: '1f7344dde030cba3e03b7c2fccfe1b30',
    },
    zendesk: {
      key: '733c7a50-1509-4fa8-8caf-578c0f48dc3d',
    },
    google: {
      apiKey: 'AIzaSyBJueo_0M63sCgif_bTUV7ueAQOIKISQ60',
    },
    prismic: {
      prismicApiEndpoint: 'https://trs-storefront.cdn.prismic.io/api/v2',
      prismicAccessToken:
        'MC5ZejBSZnhFQUFDRUFnTjg5.Mu-_ve-_vUzvv70pKxsL77-9XO-_vUdvdlcTGu-_vVIwee-_ve-_vUlt77-977-9M--_vWEq',
    },
  },
};

export default production;
