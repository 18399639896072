const FormatPrice = (value = 0, code = 'AUD', lang = 'en-AU') => {
  const parts = [];

  parts.push({ type: 'currency', value: '$' });
  parts.push({ type: 'decimal', value: '.' });

  const [integer, fraction] = value.toString().split('.');

  parts.push({ type: 'integer', value: integer });
  parts.push({ type: 'fraction', value: (fraction ?? '').padEnd(2, 0).substr(0, 2) });

  return parts;
};

export default FormatPrice;
