import Link from '@components/ui/Link';
import { ImageField, RichTextField } from '@prismicio/types';
import config from '@config/seo_meta.json';
import Image from 'next/image';
import { PrismicRichText } from '@prismicio/react';
import { FC } from 'react';
import { useUI } from '@components/ui';

interface props {
  title: string;
  link?: string;
  text?: RichTextField;
  image?: ImageField;
  handleCloseMegaMenu?: Function;
}

const NavFeaturedCallout: FC<props> = ({ title, link, text, image, handleCloseMegaMenu }) => {
  const { closeSidebar } = useUI();

  const closeMenu = () => {
    handleCloseMegaMenu();
    closeSidebar();
  };

  const markup = (
    <div className="space-y-2">
      {image?.url && (
        <Image
          src={image?.url}
          alt={image?.alt || config.openGraph.images[0].alt}
          width={image?.dimensions?.width}
          height={image?.dimensions?.height}
        />
      )}

      <div className="hover:opacity-75">
        <div className="text-body3 font-medium text-dark-grey uppercase">{title}</div>
        <div className="text-body2 text-dark-grey">
          <PrismicRichText field={text} />
        </div>
      </div>
    </div>
  );

  if (link) {
    return (
      <Link href={link} legacyBehavior prefetch={false}>
        <a onClick={closeMenu}>{markup}</a>
      </Link>
    );
  }

  return markup;
};

export default NavFeaturedCallout;
