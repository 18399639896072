const FilterArrowDown = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.979492 0.937581L1.87533 0.041748L6.00033 4.16675L10.1253 0.041748L11.0212 0.937581L6.00033 5.95841L0.979492 0.937581Z"
        fill="#333333"
      />
    </svg>
  );
};

export default FilterArrowDown;
