import { ChevronDown, ChevronUp } from '@components/icons';
import { useUI } from '@components/ui';
import { useUserPortal } from '@lib/userportal';
import clsx from 'clsx';
import Image from 'next/image';
import { EventContext } from 'providers/EventProvider';
import { useContext, useEffect, useMemo, useState } from 'react';

const dataLayer = typeof window !== 'undefined' ? window?.dataLayer || null : null;

interface Props {
  title?: string;
  thumbnail?: string;
  slices?: {
    data: {
      subscription_title: string;
      subscription_thumbnail: { url: string };
      slices3: any[];
    };
  };
}

/* eslint-disable import/prefer-default-export */
export const SubscribeAndSaveFloatingBar = ({ slices }: Props) => {
  const [show, setShow] = useState(false);
  const { toggleSidebar, setSidebarView, isHideSubscribeFlow } = useUI();
  const { gtm } = useContext(EventContext);
  const [{ user, signupAlready }] = useUserPortal();

  const promotion = useMemo(() => {
    const _promotion = {
      title: slices?.data?.subscription_title,
      thumbnail: slices?.data?.subscription_thumbnail?.url,
    };

    const _slices = slices?.data?.slices3?.filter((d) => d.slice_type === 'accordion') ?? [];
    return {
      promotion: _promotion,
      accordions: _slices?.map((slice) => ({
        // id: slice.id,
        title: slice.primary.title?.[0]?.text,
        description: slice.primary.description?.[0]?.text,
        thumbnail: slice?.primary?.thumbnail?.url,
      })),
    };
  }, [slices]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof (window as any)?.zE !== 'function') return;
    if (show) (window as any)?.zE('messenger', 'hide');
    if (!show) (window as any)?.zE('messenger', 'show');
  }, [show]);

  const handleToggle = () => {
    setShow((prev) => !prev);
    if (dataLayer) {
      gtm.tagClickSubscribeFlow({ label: 'Toggle' });
    }
  };

  const handleSignup = () => {
    setSidebarView('USER_PORTAL_VIEW');
    toggleSidebar();
    if (dataLayer) {
      gtm.tagClickSubscribeFlow({ label: 'Open_SignUp_Form' });
    }
  };

  if ((user && user.id) || signupAlready) {
    return null;
  }

  if (isHideSubscribeFlow) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-20">
      <div
        className={clsx(
          'flex-col xl:flex-row items-center rounded-xl bg-brand-red m-4 md:mx-10 md:my-6 duration-300 ease-in transition-all translate-y-0',
          {
            flex: show,
            hidden: !show,
          }
        )}
      >
        <div className="hidden xl:flex">
          <div
            style={{
              backgroundImage: 'url(/images/subscribe-and-save.png)',
              width: 160,
              height: 160,
              objectFit: 'cover',
            }}
          />
        </div>
        <div className="flex flex-col w-full gap-6 text-white xl:h-[160px] p-4 md:p-6">
          <div className="flex flex-col gap-8 md:gap-6">
            <div className="flex items-start justify-between xl:items-center">
              <div className="flex items-center gap-4">
                <div className="flex xl:hidden">
                  {promotion.promotion.thumbnail ? (
                    <Image
                      src={promotion.promotion.thumbnail}
                      width="160"
                      height="160"
                      alt={promotion.promotion.title}
                    />
                  ) : (
                    <Image src="/icons/subscribe.svg" width="80" height="80" alt="subscribe" />
                  )}
                </div>
                <p className="text-20 md:text-24 w-11/12 md:w-full leading-relaxed md:leading-8 font-bold">
                  {promotion?.promotion?.title || `Subscribe & Save`}
                </p>
              </div>
              <div className="ml-auto pt-2">
                <button type="button" onClick={handleToggle}>
                  <ChevronDown width="18" height="12" fill="#FFFFFF" />
                </button>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-8 md:gap-10">
              {promotion?.accordions?.map((promotion, key) => {
                return (
                  <div className="flex flex-row" key={key}>
                    <div>
                      <Image
                        src={promotion.thumbnail}
                        width="32"
                        height="32"
                        alt={promotion.title}
                      />
                    </div>
                    <div className="flex flex-col gap-1 w-11/12 md:w-full pl-2 items-start justify-start text-left leading-normal whitespace-normal">
                      <p className="text-dark font-medium uppercase">{promotion.title}</p>
                      <p className="text-body2">{promotion.description}</p>
                    </div>
                  </div>
                );
              })}
              <div className="lg:ml-auto">
                <button
                  type="button"
                  className="bg-white text-labelButtonBold rounded-full text-darkest-grey py-3 px-10 font-medium w-full"
                  onClick={handleSignup}
                >
                  Sign me up!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className={clsx(
          'flex items-center px-4 md:px-12 py-2 bg-[#950810] rounded-tl-xl rounded-tr-xl cursor-pointer w-full appearance-none',
          {
            flex: !show,
            hidden: show,
          }
        )}
        onClick={handleToggle}
      >
        <div className="flex items-center gap-2 xl:text-center xl:justify-center w-full">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6054 3.54468L16.3489 7.39278C17.8837 8.97012 17.8837 11.5058 16.3489 13.0831L13.0682 16.455C12.7958 16.7348 12.3506 16.7377 12.0753 16.461C11.7999 16.1842 11.797 15.7318 12.0694 15.4521L15.3471 12.0801C16.3401 11.0593 16.3401 9.41951 15.3471 8.3987L11.6066 4.5506C11.3342 4.27085 11.3371 3.81848 11.6125 3.5417C11.8878 3.26492 12.333 3.2679 12.6054 3.54765V3.54468ZM2.5 9.21118V4.7619C2.5 3.97324 3.12976 3.33337 3.90598 3.33337H8.285C8.78295 3.33337 9.2604 3.53277 9.61189 3.88991L14.5328 8.88976C15.2651 9.63379 15.2651 10.8391 14.5328 11.5831L10.6224 15.5562C9.89016 16.3003 8.70387 16.3003 7.97159 15.5562L3.05067 10.5564C2.69625 10.1992 2.5 9.71712 2.5 9.21118ZM6.71793 6.66661C6.71793 6.13984 6.29906 5.71426 5.78061 5.71426C5.26216 5.71426 4.84329 6.13984 4.84329 6.66661C4.84329 7.19338 5.26216 7.61896 5.78061 7.61896C6.29906 7.61896 6.71793 7.19338 6.71793 6.66661Z"
              fill="white"
            />
          </svg>
          <p className="text-20 leading-relaxed font-medium text-white">
            {promotion?.promotion?.title || `Subscribe & Save`}
          </p>
        </div>
        <div className="appearance-none ml-auto">
          <ChevronUp width="16" height="8" fill="#FFFFFF" />
        </div>
      </button>
    </div>
  );
};
