import React from 'react';
import Icon from 'components/Icon';

const getIcon = (options, position) => {
  let classNames = ['field-icon'];
  classNames.push('field-icon-' + position);

  if (typeof options === 'string') {
    return <Icon outline size="small" icon={options} className={classNames.join(' ')} />;
  }

  if (options === 'object' && options.icon) {
    if (options.className) {
      classNames.push(options.className);
    }

    return <Icon outline size="small" {...options} className={classNames.join(' ')} />;
  }
};

const Header = (props) => {
  const { label, descriptionBefore, id } = props;
  if (!label && !descriptionBefore) {
    return null;
  }
  return (
    <div className="field-header">
      <Label id={id} className="field-label" children={label} />
      <Message className="field-description-before" children={descriptionBefore} />
    </div>
  );
};

const Message = (props) => {
  if (!props.children || props.children === true) {
    return null;
  }
  return <div {...props} />;
};

const Label = (props) => {
  const { id = '', className, children } = props;
  if (!props.children || props.children === true) {
    return null;
  }

  return (
    <label htmlFor={id} className={className}>
      {children}
    </label>
  );
};

const Clear = (props) => {
  const { onClear, value } = props;
  if (!onClear || !value) {
    return null;
  }

  return (
    <div className="field-clear" onClick={onClear}>
      <Icon icon="close" size="sm" />
    </div>
  );
};

const Index = (props) => {
  const {
    id,
    className,
    iconBefore,
    iconAfter,
    children,
    label,
    descriptionBefore,
    description,
    error,
    size,
    disabled,
  } = props;

  let classNames = ['field'];
  if (className) {
    classNames.push(className);
  }
  if (iconBefore) {
    classNames.push('has-icon-before');
  }
  if (iconAfter) {
    classNames.push('has-icon-after');
  }
  if (error) {
    classNames.push('has-error');
  }
  if (size) {
    classNames.push('size-' + size);
  }
  if (disabled) {
    classNames.push('is-disabled');
  }

  return (
    <div className={classNames.join(' ')}>
      <Header id={id} label={label} descriptionBefore={descriptionBefore} />
      <div className="field-container">
        {iconBefore && getIcon(iconBefore, 'before')}
        {children}
        {iconAfter && getIcon(iconAfter, 'after')}
        <Clear {...props} />
      </div>
      <Message className="field-description" children={description} />
      <Message className="field-error-message" children={error} />
    </div>
  );
};

export default Index;
