/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
const Cross = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.05.343a.5.5 0 1 0-.707.707L5.293 6l-4.95 4.95a.5.5 0 1 0 .707.707L6 6.707l4.95 4.95a.5.5 0 1 0 .707-.707L6.707 6l4.95-4.95a.5.5 0 1 0-.707-.707L6 5.293 1.05.343Z"
        fill={props.fill ?? '#000'}
      />
    </svg>
  );
};

export default Cross;
