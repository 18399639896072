/* eslint-disable no-undef */
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface Props {
  variant?: 'primary' | 'outline';
  onClick?: () => void;
  disabled?: boolean;
}

/* eslint-disable react/jsx-props-no-spreading */
export default function Button(props: PropsWithChildren<Props>) {
  const { variant = 'primary' } = props;
  return (
    <button
      type="button"
      className={clsx(
        'border border-solid h-12 w-full text-labelButtonBold p-4 rounded-sm font-medium cursor-pointer transition-all duration-200 ease-in-out disabled:bg-slate-300',
        {
          'bg-ui-darkest-grey text-white hover:bg-ui-dark-grey ': variant === 'primary',
          'bg-white text-text-subdued hover:bg-slate-200 ': variant === 'outline',
        }
      )}
      {...props}
    />
  );
}
