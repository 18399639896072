/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import { selectAllInterests, selectBarcode, selectStore, selectUserInterests } from './selectors';
import { IAction, IState } from './types';

export const INITIAL_STATE: IState = {
  isAuthenticated: false,
  signupCount: 0,
  signupAlready: false,
  user: {
    id: null,
    barcode: null,
    email: null,
    name: null,
    store: null,
    interests: [],
  },
  promotions: [],
  pageHeaders: null,
  interests: [],
  currentPage: 'GET_STARTED',
  storeSearchResults: {
    address: '',
    results: [],
  },
};

export const reducer = (state: IState = INITIAL_STATE, action: IAction): IState => {
  switch (action.type) {
    case 'SET_USER_INTERESTED':
      if (state.user.interests.includes(action.payload)) {
        return {
          ...state,
          user: {
            ...state.user,
            interests: state.user.interests.filter((ui) => ui !== action.payload),
          },
        };
      }
      return {
        ...state,
        user: {
          ...state.user,
          interests: [...state.user.interests, action.payload],
        },
      };
    case 'SET_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_AUTHENTICATED_USER':
      return {
        ...state,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          name: action.payload.first_name,
          barcode: selectBarcode(action.payload),
          store: selectStore(action.payload),
          interests: selectUserInterests(action.payload) ?? [],
        },
        interests: selectAllInterests(action.payload) ?? [],
        isAuthenticated: true,
      };
    case 'SET_USER_STORE':
      return {
        ...state,
        user: {
          ...state.user,
          store: action.payload,
        },
      };
    case 'SET_SEARCH_STORE_RESULT':
      return {
        ...state,
        storeSearchResults: action.payload,
      };
    case 'SET_SIGNUP_COUNT':
      return {
        ...state,
        signupCount: action.payload,
      };
    case 'SET_SIGNUP_ALREADY':
      return {
        ...state,
        signupAlready: action.payload,
      };
    default:
      return state;
  }
};
