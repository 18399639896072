import { FC, useContext, useCallback } from 'react';
import { useGoogleMapsAPI } from 'providers/GoogleMapsAPIProvider';
import { EventContext } from 'providers/EventProvider';
import { LoadingDots } from '@components/ui';
import { useStoreSidebar } from '@lib/store-finder-sidebar';
import { StoreLocation } from '@lib/store-finder-sidebar/types';
import { permanentlyClosedStores } from '@lib/permanently-closed-stores';

import StoreCard from './components/StoreCard';

interface Props {
  onClose?: () => void;
}

const AppList: FC<Props> = () => {
  const { stores, myStore, isFetching, setMyStore } = useStoreSidebar();
  const { gtm } = useContext(EventContext);

  const { googleMapsLoaded } = useGoogleMapsAPI();

  const handleSetStore = (store: StoreLocation) => {
    setMyStore(store);
    (window as any).dataLayer.push({
      event: 'gtm.customEvent',
      category: 'Location',
      action: 'Changed Store',
      label: store?.name,
    });

    (window as any).dataLayer.push({
      location: {
        suburb: store?.address,
        storeName: store?.name,
        storeId: store?.id,
        postcode: store?.postcode,
      },
    });

    gtm.tagChangeStore({ label: store?.name });
  };

  const handleOnShowNumber = (store: StoreLocation) => {
    // do something
  };

  const handleDirection = useCallback(
    (store: StoreLocation) => {
      if (!store?.formattedAddress) return;
      window.open(
        'https://www.google.com/maps/dir/?api=1&destination=' + encodeURI(store.formattedAddress)
      );
    },
    [googleMapsLoaded]
  );

  return (
    <div className="flex flex-col">
      {isFetching ? (
        <LoadingDots />
      ) : (
        stores
          .filter((store) => permanentlyClosedStores.indexOf(Number(store.id)) === -1)

          .map((store, k) => (
            <div key={k} className="mb-4">
              <StoreCard
                {...store}
                address={store.formattedAddress}
                isCurrent={store.id === myStore?.id}
                onSetStore={() => handleSetStore(store)}
                onShowNumber={() => handleOnShowNumber(store)}
                onDirection={() => handleDirection(store)}
              />
            </div>
          ))
      )}
    </div>
  );
};

export default AppList;
