import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickRelatedSearchCategory = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'search',
    category: 'related_search_category',
    label: label?.toLowerCase(),
  });
};

export default tagClickRelatedSearchCategory;
