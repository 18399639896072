const Menu = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7589 2.34642H0.241071C0.177135 2.34642 0.115818 2.32102 0.0706082 2.27581C0.0253985 2.2306 0 2.16929 0 2.10535L0 1.14107C0 1.07713 0.0253985 1.01581 0.0706082 0.970602C0.115818 0.925393 0.177135 0.899994 0.241071 0.899994H17.7589C17.8229 0.899994 17.8842 0.925393 17.9294 0.970602C17.9746 1.01581 18 1.07713 18 1.14107V2.10535C18 2.16929 17.9746 2.2306 17.9294 2.27581C17.8842 2.32102 17.8229 2.34642 17.7589 2.34642ZM17.7589 8.77499H0.241071C0.177135 8.77499 0.115818 8.7496 0.0706082 8.70439C0.0253985 8.65918 0 8.59786 0 8.53392L0 7.56964C0 7.5057 0.0253985 7.44438 0.0706082 7.39917C0.115818 7.35396 0.177135 7.32857 0.241071 7.32857H17.7589C17.8229 7.32857 17.8842 7.35396 17.9294 7.39917C17.9746 7.44438 18 7.5057 18 7.56964V8.53392C18 8.59786 17.9746 8.65918 17.9294 8.70439C17.8842 8.7496 17.8229 8.77499 17.7589 8.77499ZM17.7589 15.2036H0.241071C0.177135 15.2036 0.115818 15.1782 0.0706082 15.133C0.0253985 15.0877 0 15.0264 0 14.9625L0 13.9982C0 13.9343 0.0253985 13.873 0.0706082 13.8277C0.115818 13.7825 0.177135 13.7571 0.241071 13.7571H17.7589C17.8229 13.7571 17.8842 13.7825 17.9294 13.8277C17.9746 13.873 18 13.9343 18 13.9982V14.9625C18 15.0264 17.9746 15.0877 17.9294 15.133C17.8842 15.1782 17.8229 15.2036 17.7589 15.2036Z"
        fill="#464544"
      />
    </svg>
  );
};

export default Menu;
