import React from 'react';
import { ReactSVG } from 'react-svg';

const Icon = (props) => {
  const { className, icon, fill, outline, size } = props;
  if (!icon) {
    return null;
  }

  let classNames = ['icon'];

  // Appearance
  if (fill) {
    classNames.push('appearance-fill');
  }
  if (outline) {
    classNames.push('appearance-outline');
  }

  // Size
  if (size) {
    classNames.push(`size-${size}`);
  }

  if (className) {
    classNames.push(className);
  }

  let iconProps = { ...props };
  delete iconProps.className;
  delete iconProps.icon;
  delete iconProps.outline;
  delete iconProps.small;

  const file = `/icons/${icon}.svg`;

  return (
    <ReactSVG src={file} className={classNames.join(' ')} fallback={() => null} {...iconProps} />
  );
};

export default React.memo(Icon);
