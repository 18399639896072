/* eslint-disable import/prefer-default-export */
import Image from 'next/image';
// @ts-ignore
import DoorDash from '../assets/DoorDash_Logo.svg';
// @ts-ignore
import downloadPlaystore from '../assets/download_android.svg';
// @ts-ignore
import downloadIos from '../assets/download_ios.svg';
import { StoreLocation } from '../types';

interface Props extends StoreLocation {
  options: {
    doordash: boolean;
  };
}

export const DoorDashItem = () => {
  return (
    <>
      <figure className="w-36">
        <Image src={DoorDash} alt="doordash" layout="responsive" />
      </figure>
      <div className="flex items-center mt-4 gap-2">
        <div className="w-36">
          <a href="https://play.google.com/store/apps/details?id=com.dd.doordash" target="_blank">
            <Image src={downloadPlaystore} alt="download on playstore" layout="responsive" />
          </a>
        </div>
        <div className="w-36">
          <a
            href="https://apps.apple.com/us/app/doordash-food-delivery/id719972451"
            target="_blank"
          >
            <Image src={downloadIos} alt="download on app store" layout="responsive" />
          </a>
        </div>
      </div>
    </>
  );
};

export function DeliveryOptStoreCard(store: Props) {
  const { distance, title, address } = store;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between align-middle items-center font-semibold">
        <span>{title}</span>
        {distance && (
          <span>
            {Number.parseInt(distance).toLocaleString('en-US', {
              style: 'unit',
              unit: 'kilometer',
            })}
          </span>
        )}
      </div>
      <p className="text-13 opacity-70">{address}</p>
    </div>
  );
}
