import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagSignupSuccess = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'signup_success',
    label: label?.toLowerCase(),
  });
};

export default tagSignupSuccess;
