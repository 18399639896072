const ArrowRightWhite = ({ fill = '#fff', ...props }) => {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.69434 0.160571L8.44084 0.414072C8.27303 0.581882 8.27303 0.853235 8.44084 1.02105L14.8141 7.3907H1.39281C1.15716 7.3907 0.964355 7.5835 0.964355 7.81915V8.17619C0.964355 8.41184 1.15716 8.60464 1.39281 8.60464H14.8141L8.44084 14.9779C8.27303 15.1457 8.27303 15.417 8.44084 15.5848L8.69434 15.8383C8.86215 16.0062 9.1335 16.0062 9.30131 15.8383L16.8385 8.30473C17.0063 8.13692 17.0063 7.86556 16.8385 7.69775L9.30131 0.164142C9.12993 -0.00723901 8.86215 -0.00723921 8.69434 0.160571Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowRightWhite;
