/* eslint-disable */
import React from 'react';
import Head from 'next/head';

function ZendeskCustomHead({}) {
  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.zESettings = {
              webWidget: {
                color: { launcher: '#000' },
                helpCenter: {
                    originalArticleButton: false
                }
              }
            };
          `,
        }}
      />
    </Head>
  );
}
export default ZendeskCustomHead;
