import React from 'react';
import cn from 'classnames';
// import Link from 'next/link';
import Image from 'next/image';
import { useUI } from '@components/ui';
import s from './UserNav.module.css';
// import { Avatar } from '@components/common'
// import useCart from '@framework/cart/use-cart'
// import { Chat } from 'components/Icons'
// import CustomerMenuContent from './CustomerMenuContent'
// import useCustomer from '@framework/customer/use-customer'
// import { Button } from 'components/ui'

// import type { LineItem } from '@commerce/types/cart'

// const countItem = (count: number, item: LineItem) => count + item.quantity

const UserNav: React.FC<{ className?: string }> = ({ className }) => {
  // const { data } = useCart()
  // const { data: isCustomerLoggedIn } = useCustomer()
  const { toggleSidebar, closeSidebarIfPresent, openModal, setSidebarView, openSidebar } = useUI();

  // const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0

  // const DropdownTrigger = isCustomerLoggedIn
  //   ? DropdownTriggerInst
  //   : React.Fragment

  return (
    <nav className={cn(s.root, className)}>
      <ul className={s.list}>
        {/* service portal */}
        <li className={s.item}>
          <div
            className={s.item}
            onClick={() => {
              setSidebarView('SERVICE_PORTAL_VIEW');
              toggleSidebar();
            }}
          >
            <div className={s.chatIcon}>
              <Image src="/icons/header-chat.svg" alt="Chat" width={24} height={26} />
            </div>
          </div>
        </li>
        {/* <li className={s.mobileMenu}>
          <Button
            className={s.item}
            aria-label="Menu"
            variant="naked"
            onClick={() => {
              openSidebar()
              setSidebarView('MOBILE_MENU_VIEW')
            }}
          >
            <Menu />
          </Button>
        </li> */}
      </ul>
    </nav>
  );
};

export default UserNav;
