/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import { useRouter } from 'next/router';
import React from 'react';

interface LinkNoPrefetchProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  target?: any;
  rel?: string;
}

const LinkNoPrefetch: React.FC<LinkNoPrefetchProps> = ({ href, children, className, ...props }) => {
  const router = useRouter();

  const onClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    await router.push(href);
  };

  return (
    <a href={href} onClick={onClick} className={className} {...props}>
      {children}
    </a>
  );
};

export default LinkNoPrefetch;
