import Head from 'next/head';
import { SocialProfileJsonLd } from 'next-seo';
import gtm from './gtm';
import smartViewer from './smartViewer';

const icons = () => {
  const config = ['16', '32', '64', '128'];

  return config.map((size) => (
    <link
      rel="icon"
      key={size}
      size={`${size}x${size}`}
      href={`/images/favicon-icon-${size}x${size}.jpeg`}
    />
  ));
};

const LayoutHead = (props) => {
  // site metadata
  const title = 'The Reject Shop';
  const description =
    'The home of all your daily essentials. We help all Australians save money on everyday groceries, cleaning materials pet essentials and more.';
  const keywords = 'essentials, discount, grocery, saving, cleaning';
  const url = 'https://www.rejectshop.com.au/';

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=yes, viewport-fit=cover"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

        <meta name="robots" content="index,follow" />

        <meta key="og:title" property="og:title" content={title} />
        <meta key="description" name="description" content={description} />
        <meta key="og:description" property="og:description" content={description} />
        <meta key="keywords" name="keywords" content={keywords} />

        <meta key="og:url" property="og:url" content={url} />
        <meta key="twitter:site" name="twitter:site" content={url} />
        <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
        <meta key="twitter:image" name="twitter:image" content="/images/ogImage.png" />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:image" property="og:image" content="/images/ogImage.png" />

        {icons()}
        {gtm}
        {smartViewer}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organisation',
              name: title,
              url,
              sameAs: ['https://www.facebook.com/rejectshop', 'https://twitter.com/TheRejectShop'],
            }),
          }}
        />
      </Head>
      <SocialProfileJsonLd
        type="Organization"
        name={title}
        url={url}
        sameAs={[
          'https://www.instagram.com/therejectshop/',
          'https://www.facebook.com/rejectshop/',
          'https://www.pinterest.com.au/therejectshop/',
        ]}
      />
    </>
  );
};

export default LayoutHead;
