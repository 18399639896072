import { useEffect, useContext } from 'react';
import { useUserPortal } from '@lib/userportal';
import { useRouter } from 'next/router';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';
import { transformStoreLocationData } from '@lib/store-finder-sidebar/reducer';
import { StoreLocation } from '@lib/store-finder-sidebar/types';
import axios from 'axios';

export function useSyncAuthFromLink() {
  const [{ user }, { setUser }] = useUserPortal();
  const { query } = useRouter();
  const [, setStore] = useStoreFinderStorage();
  const { emit } = useContext(LocalisationConnectorContext);
  useEffect(() => {
    const fetchUserProfileById = async (id: string) => {
      return await axios.get(`/api/userportal/profiles/${id}`).then((res) => res.data);
    };
    const fetchLocationById = async (id: string) => {
      return await axios
        .get<{ location: StoreLocation }>(`/api/locations/get-location?id=${id}`)
        .then((res) => res.data);
    };

    const fetchSyncUserFromLink = async () => {
      let locationResp = null;
      let profileResp = null;

      if (query?.o_plc) {
        locationResp = await fetchLocationById(query?.o_plc as string);
      }
      if (query?.o_p) {
        profileResp = await fetchUserProfileById(query?.o_p as string);
      }
      if (locationResp && profileResp) {
        const location = locationResp.location as StoreLocation;
        const profile = profileResp as any;
        setUser({
          ...user,
          id: profile?.id,
          store: { id: location?.id, name: location?.name },
        });
        const updatedStore = transformStoreLocationData(location);
        setStore(updatedStore);
        if (emit) {
          emit('store_finder_page_set_store', updatedStore);
        }
      }
    };

    fetchSyncUserFromLink();
  }, [query?.o_plc, query?.o_p]);
  return null;
}

export default useSyncAuthFromLink;
