/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/prefer-default-export */
import Image from 'next/image';

interface Props {
  title: string;
  externallink: string;
  thumbnail: string;
  linklabel: string;
}

export const ShopItem = ({ title, externallink, thumbnail = '', linklabel }: Props) => {
  const open = (link: string) => {
    if (typeof window === 'undefined') return;
    window.open(link);
  };

  return (
    <div className="flex items-center gap-4 p-4 bg-ui-lightest-grey w-full shadow-sm">
      <div className="flex">
        <Image src={thumbnail} alt={title} width="76" height="76" />
      </div>
      <div className="flex flex-col gap-2 text-13 flex-1">
        <p className="font-medium text-dark-grey">{title}</p>
        <button
          type="button"
          className="border border-solid border-ui-dark-grey font-semibold rounded-full text-text-subdued hover:bg-ui-light-grey px-4"
          onClick={() => externallink && open(externallink)}
        >
          {linklabel || 'Shop groceries'}
        </button>
      </div>
    </div>
  );
};
