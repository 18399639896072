const ChevronRight = ({ ...props }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.02797 11.9055L0.837001 11.7145C0.710583 11.5881 0.710583 11.3837 0.837001 11.2573L6.09546 6.00151L0.837001 0.743044C0.710583 0.616626 0.710583 0.412205 0.837001 0.285786L1.02797 0.0948138C1.15439 -0.0316046 1.35881 -0.0316046 1.48523 0.0948138L7.16329 5.77019C7.28971 5.89661 7.28971 6.10103 7.16329 6.22745L1.48523 11.9028C1.35881 12.0319 1.15439 12.0319 1.02797 11.9055Z"
        fill="#464544"
      />
    </svg>
  );
};

export default ChevronRight;
