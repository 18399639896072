import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagSearchTerm = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'search',
    category: 'search_term',
    label: label?.toLowerCase(),
  });
};
export default tagSearchTerm;
