import { GraphQLClient } from 'graphql-request';

const getClient = (shopifyDomain, storefrontToken) => {
  const apiUrl = `https://${shopifyDomain}/api/2024-07/graphql.json`;

  const client = new GraphQLClient(apiUrl, {
    headers: {
      'X-Shopify-Storefront-Access-Token': storefrontToken,
      'Content-Type': 'application/json',
    },
  });

  return (query, variables = null) =>
    client
      .request(query, variables)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
};

export default getClient;
