import PropTypes from 'prop-types';

const PreviewMode = ({ preview }) => {
  if (!preview) return '';
  return (
    <>
      <div className="previewMode">
        <div className="previewModecontent wrapper-large">
          <p>Preview mode is on</p>
          <a href="/api/exit-preview" className="button">
            Exit Preview
          </a>
        </div>
      </div>
      <style jsx>
        {`
          .previewMode {
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 4;
            background-color: #272727;
            color: #fff;
            font-size: 1rem;
            letter-spacing: 0.5px;
          }
          .previewModecontent {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
            gap: 20px;
          }
          a {
            text-align: center;
          }
        `}
      </style>
    </>
  );
};

PreviewMode.propTypes = {
  preview: PropTypes.bool,
};

PreviewMode.defaultProps = {
  preview: false,
};

export default PreviewMode;
