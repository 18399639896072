import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import { FilledLinkToDocumentField } from '@prismicio/types';
import sm from '../sm.json';

export const repositoryName = prismic.getRepositoryName(sm.apiEndpoint);

export function linkResolver(doc: FilledLinkToDocumentField) {
  if (!doc || !doc?.uid || doc.uid === 'homepage') return '/';

  switch (doc.type) {
    case 'category_landing_page':
      return `/c/l/${doc.uid.replace('clp-', '')}`;
    case 'event_landing_page':
      return `/e/l/${doc.uid.replace('elp-', '')}`;
    case 'brands_page':
      return `/brands`;
    case 'doordash_page':
      return `/doordash`;
    case 'careers':
      return `/careers/${doc.uid.replace('careers-', '')}`;
    case 'main_navigation':
      return `/`;
    case 'footer_navigation':
      return `/`;
    default:
      return '/';
  }
}

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export function createClient(config: any = {}) {
  const client = prismic.createClient(sm.apiEndpoint, {
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config?.previewData,
    req: config?.req,
  });

  return client;
}

export const getDocByUid = async (
  doctype: string,
  uid: string,
  client: prismic.Client,
  locale: string | undefined,
  fallback: boolean = true
) => {
  if (!uid) return null;

  let doc = null;
  try {
    doc = await client.getByUID(doctype, uid, { lang: locale });
  } catch (e) {
    console.log('Error getting doc by uid', uid, e);
  }

  if (!doc && fallback) {
    try {
      doc = await client.getByUID(doctype, uid);
    } catch (e) {
      console.log('Error getting doc by uid', uid, e);
    }
  }

  return doc;
};

export const getDocByType = async (
  doctype: string,

  client: prismic.Client,
  locale: string | undefined,
  fallback: boolean = true
) => {
  let doc = null;
  try {
    doc = await client.getByType(doctype, { lang: locale });
  } catch (e) {
    console.log('Error getting doc by type', doctype, e);
  }

  if (!doc && fallback) {
    try {
      doc = await client.getByType(doctype);
    } catch (e) {
      console.log('Error getting doc by type', doctype, e);
    }
  }

  return doc;
};
