import { useState } from 'react';
import cn from 'classnames';
import SimpleLink from '@components/SimpleLink';
import useAcceptCookies from '@lib/useAcceptCookies';
import { Cross } from '@components/icons';

const Message = ({ message, link }) => {
  const [showBar, setShowBar] = useState(true);

  const { acceptedCookies: alertBarCookie, onAcceptCookies: closeAlertBar } = useAcceptCookies(
    'trs_announcement_bar',
    'closed'
  );

  const onCloseBox = () => {
    closeAlertBar();
    setShowBar(false);
  };

  return (
    <>
      {showBar && !alertBarCookie && (
        <div className={cn('hidden md:block w-full bg-ui-darkest-grey text-white text-body2 h-10')}>
          <div className="wrapper-large relative flex justify-center items-center h-full">
            <div className="text-center">
              <SimpleLink linkUrl={link}>{message}</SimpleLink>
            </div>
            <div
              className="wrapper-large absolute w-full flex justify-end cursor-pointer"
              onClick={onCloseBox}
            >
              <Cross />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
