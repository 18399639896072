/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable import/extensions */

import { getApiBaseUrl } from '@utils/url';
import axios from 'axios';

export interface APICheckEmailResponse {
  email: {
    available: boolean;
    profile: {
      full_name: string;
      id: string;
    };
  };
}

export interface APIGetCurrentUserResponse {
  id: string;
  name: string;
  email: string;
  full_name: string;
  first_name: string;
  last_name: string;
  custom_attributes: {
    comms: {
      interests: string[];
      user_interests?: string[];
    };
  };
  preferred_location_id: number;
  preferred_location: {
    name: string;
    // address: {
    //   id: string;
    //   address_line_1: string;
    // };
  };
  identities: {
    handle: 'card' | 'shopify';
    identifier: string;
  }[];
}

const instance = axios.create({
  baseURL: `${getApiBaseUrl()}/api/userportal`,
  timeout: 1000,
});

// get preferred location details
const fetchLocationByLocationId = async (id: string | number) => {
  return await axios.get(`/api/locations/get-location-by-id?id=${id}`).then((res) => res.data);
};

const API = {
  createUser: async ({
    email,
    name,
    preferred_location_id,
  }: {
    email: string;
    name: string;
    preferred_location_id?: number | string;
  }): Promise<APIGetCurrentUserResponse> => {
    try {
      const [first_name, last_name] = name.split(' ');

      const preferredLocationDetails = await fetchLocationByLocationId(preferred_location_id);

      // Create new user
      const createdUser = await instance.post(`/profiles`, {
        email,
        first_name,
        last_name: last_name ?? 'Unknown',
        preferred_location_id,
        region_state: preferredLocationDetails?.location.address.iso_state ?? '',
        region_country: preferredLocationDetails?.location.address.iso ?? '',
      });

      const user = createdUser.data;

      // Create new comms
      // await instance.put(`/user-interests`, {
      //   userId: user.id,
      //   namespace: 'comms',
      //   handle: 'user_interests',
      //   type: 'array',
      //   value: [],
      // });

      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getAuthenticatedUser: async () => {
    try {
      const authenticatedUser = await instance.get(`/me`);
      return Promise.resolve(authenticatedUser.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getCurrentUser: async (userId: string): Promise<APIGetCurrentUserResponse> => {
    try {
      const profileResponse = await instance.get(`/profiles/${userId}`);
      if (!profileResponse) throw new Error('user not found');
      return Promise.resolve(profileResponse.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getUserAvailability: async (email: string): Promise<APICheckEmailResponse> => {
    try {
      const response = await instance.post('/availability', { email });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updateProfile: async (
    userId: string,
    data: Record<string, string | any>
  ): Promise<{ data: { id: string } }> => {
    try {
      let first_name;
      let last_name;

      const preferredLocationDetails = await fetchLocationByLocationId(data.preferred_location_id);

      // Update first name/last name with ease
      if ('name' in data) {
        [first_name, last_name] = data.name.split(' ');
      }

      const updated = {
        ...data,
        first_name,
        last_name: last_name ?? 'Unknown',
        region_state: preferredLocationDetails?.location.address.iso_state ?? '',
        region_country: preferredLocationDetails?.location.address.iso ?? '',
      };

      const response = await instance.put(`/profiles/${userId}`, updated);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updateCustomAttributes: async (
    userId: string,
    namespace: string,
    data: Record<string, string | any>
  ): Promise<{ data: { id: string } }> => {
    try {
      const response = await instance.put(`/profiles/${userId}/attributes`, {
        ...data,
        namespace,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default API;
