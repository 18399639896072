import cn from 'classnames';
import { FC } from 'react';
import { MenuItem } from '@components/Common/types';
import { NavColumnList, NavFeaturedCallout } from '../MegaMenuItems';
import styles from './MegaMenu.module.css';

interface props {
  menuItems: MenuItem[];
  handleMegaMenuVisibility?: (visible: boolean) => void;
}

const MegaMenu: FC<props> = ({ menuItems, handleMegaMenuVisibility }) => {
  const handleCloseMegaMenu = () => {
    if (handleMegaMenuVisibility) {
      handleMegaMenuVisibility(false);
    }
  };

  const columnsData = menuItems?.filter((item) => item.type === 'mega_nav_column');

  const featuredData = menuItems?.filter((item) => item.type === 'mega_nav_callout');

  if (!columnsData) return null;

  return (
    <div className="bg-white">
      <div className={`${styles.wrapper} py-10`}>
        <div className="grid grid-cols-6 gap-3 lg:gap-8">
          {columnsData?.length > 0 &&
            columnsData.map((col) => (
              <div key={col.title} className="col-span-6 lg:col-span-1">
                <NavColumnList
                  handleCloseMegaMenu={handleCloseMegaMenu}
                  title={col.title}
                  link={col.link}
                  links={col.links}
                />
              </div>
            ))}
          {featuredData?.length > 0 &&
            featuredData.map((featured, index) => (
              <div
                key={featured.title}
                className={cn('col-span-3 mt-4 lg:mt-0 lg:col-span-1', {
                  'lg:col-start-5': index === 0,
                  'lg:col-start-6': index === 0 && featuredData.length === 1,
                })}
              >
                <NavFeaturedCallout
                  handleCloseMegaMenu={handleCloseMegaMenu}
                  title={featured.title}
                  text={featured.description}
                  image={featured.image}
                  link={featured.link}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
