const ArrowUpRight = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.43067 9.7784H9.13192C8.93415 9.7784 8.77425 9.6185 8.77425 9.42074L8.77636 1.91193L0.867808 9.82048C0.728951 9.95933 0.501729 9.95933 0.362872 9.82048L0.152483 9.61009C0.0136253 9.47123 0.0136258 9.24401 0.152483 9.10515L8.06103 1.1966L0.550119 1.1966C0.352352 1.1966 0.192456 1.03671 0.192456 0.83894L0.192456 0.540186C0.192456 0.34242 0.352352 0.182524 0.550119 0.182524L9.43067 0.18042C9.62844 0.18042 9.78833 0.340316 9.78833 0.538083L9.78623 9.41863C9.78623 9.62061 9.62844 9.7784 9.43067 9.7784Z"
        fill="#464544"
      />
    </svg>
  );
};

export default ArrowUpRight;
