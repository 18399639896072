const Instagram = ({ ...props }) => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)" fill="#fff">
        <path d="M8 1.441c2.136 0 2.389.009 3.233.047.78.036 1.203.166 1.485.275.348.129.663.333.921.6.266.257.47.572.599.92.11.282.24.706.275 1.486.039.843.047 1.096.047 3.232 0 2.137-.008 2.39-.047 3.233-.035.78-.166 1.204-.275 1.486a2.65 2.65 0 0 1-1.518 1.518c-.282.11-.706.24-1.486.275-.843.039-1.097.047-3.233.047s-2.39-.008-3.233-.047c-.78-.035-1.203-.165-1.485-.275a2.477 2.477 0 0 1-.921-.599 2.477 2.477 0 0 1-.599-.92c-.11-.283-.24-.706-.275-1.486-.038-.844-.047-1.097-.047-3.233s.009-2.389.047-3.233c.036-.78.166-1.203.275-1.485.129-.348.333-.663.599-.921.258-.266.573-.47.92-.599.283-.11.707-.24 1.486-.275.844-.039 1.097-.047 3.233-.047L8 1.441ZM8.001 0c-2.172 0-2.445.009-3.298.048-.854.04-1.435.175-1.943.373a3.928 3.928 0 0 0-1.417.923c-.407.4-.722.883-.923 1.417-.198.508-.333 1.09-.372 1.942C.01 5.554 0 5.827 0 8s.01 2.445.048 3.299c.04.853.174 1.433.372 1.94.2.534.516 1.018.923 1.418.4.407.883.722 1.417.923.508.198 1.09.333 1.942.372.851.039 1.126.048 3.299.048 2.172 0 2.445-.01 3.298-.048.853-.04 1.433-.174 1.94-.372a4.088 4.088 0 0 0 2.34-2.34c.199-.508.334-1.09.373-1.942.039-.851.048-1.126.048-3.299 0-2.172-.01-2.445-.048-3.298-.04-.853-.174-1.433-.372-1.94a3.923 3.923 0 0 0-.923-1.418A3.927 3.927 0 0 0 13.24.42c-.508-.198-1.09-.333-1.942-.372C10.447.01 10.173 0 8 0l.001-.001Z" />
        <path d="M8 3.892a4.108 4.108 0 1 0 0 8.216 4.108 4.108 0 0 0 0-8.216Zm0 6.776a2.668 2.668 0 1 1 0-5.336 2.668 2.668 0 0 1 0 5.336ZM12.27 4.69a.96.96 0 1 0 0-1.92.96.96 0 0 0 0 1.92Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
