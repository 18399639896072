/* eslint-disable no-case-declarations */
/* eslint-disable prefer-object-spread */
/* eslint-disable camelcase */
import { Action, State, StoreLocation } from './types';
import { slugify } from './utils/slugify';

export const INITIAL_STATE: State = {
  stores: [],
  myStore: null,
  isCurrentLocationFetching: false,
  isFetching: false,
  searchLocation: null,
};

function buildTime(hrs: string = '') {
  if (!hrs) return 'N/A';
  const time = hrs.split(':').map((i) => Number(i));
  return new Date(2022, 10, 10, time[0], time[1]).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
}

function buildOperationTime(normal_hours: any[] = []) {
  return normal_hours?.map((normal_hour) => ({
    day: normal_hour?.day_of_week,
    time: [
      // @ts-ignore
      buildTime(normal_hour?.open_at),
      // @ts-ignore
      buildTime(normal_hour?.close_at),
    ].join(' - '),
  }));
}

export const transformStoreLocationData = (store): StoreLocation => {
  const address = [
    store?.address?.address_line_1,
    store?.address?.address_line_2,
    store?.address?.suburb,
    store?.address?.state,
    store?.address?.city,
    store?.address?.postcode,
  ].filter((x) => x);

  return {
    ...store,
    postcode: store?.address?.postcode,
    title: store.name,
    email: store?.email && store?.email !== '' ? store?.email : null,
    id: store?.id ?? store?.objectID,
    open_hours: buildOperationTime(store?.normal_hours ?? []),
    slug: slugify(store.name ?? 'untitled'),
    phone_number: store.phone,
    formattedAddress: address.join(', '),
  };
};

function limit<T>(items: T[] = [], max = 0): Partial<T>[] {
  return items.splice(0, max);
}

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case 'INIT':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'LOAD_STORE':
      return Object.assign({}, state, {
        stores: limit(
          action.payload
            .map(transformStoreLocationData)
            .sort((s) => (s?.id === state.myStore?.id ? -1 : 0)),
          5
        ),
        isFetching: false,
      });
    case 'REQUEST_SORT_STORE_BY_LOCATION':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'REQUEST_SORT_STORE':
      return Object.assign({}, state, {
        isCurrentLocationFetching: true,
      });
    case 'SORT_STORE':
      return Object.assign({}, state, {
        stores: limit(action.payload, 4),
        isCurrentLocationFetching: false,
        isFetching: false,
      });
    case 'SEARCH_BY_LOCATION':
      return Object.assign({}, state, {
        searchLocation: action.payload,
      });
    case 'SET_MY_STORE':
      const myStore = state.stores.find((store) => store.id === action.payload.id);
      if (!myStore)
        return {
          ...state,
          isFetching: false,
        };
      return Object.assign({}, state, {
        myStore: action.payload,
        isFetching: false,
        stores: state?.stores.sort((s) => (s.id === myStore.id ? -1 : 0)),
      });
    default:
      return state;
  }
};

export default reducer;
