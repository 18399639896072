import UserNav from '@components/Common/UserNav';
import CustomSearchBoxV2 from '@components/CustomSearchBoxV2';
import SearchPanel from '@components/CustomSearchBoxV2/SearchPanel';
import { useUI } from '@components/ui/context';
import SubscribeAndSave from '@containers/Layout/Header/SubscribeAndSave';
import BrandLogo from 'components/BrandLogo';
import Icon from 'components/Icon/index';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Profile from '../Profile';
import YourList from '../YourList';
import YourListControl from '../YourListControl';
import { clsx } from 'clsx';
import { useUserPortal } from '@lib/userportal';

const MainV2 = () => {
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [{ user, signupAlready }] = useUserPortal();
  const showSubscribeAndSave = !((user && user.id) || signupAlready);
  const { toggleSidebar, setSidebarView, isSearchFormFocused } = useUI();

  const toggleMenu = () => {
    setSidebarView('MOBILE_MENU_VIEW');
    toggleSidebar();
  };

  const handleOpenSubscribeFlow = () => {
    setSidebarView('USER_PORTAL_VIEW');
    toggleSidebar();
  };

  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(!isMenuOpen);
    }
  }, [router.asPath]);

  return (
    <>
      <div className="bg-brand-dark-red">
        <div className="relative flex items-center justify-between max-w-[1440px] py-4 px-6 lg:px-16 mx-auto">
          {/* mobile - menu icon and profile icon */}
          <div className="block lg:hidden">
            <div className="flex items-center">
              <button
                type="button"
                onClick={toggleMenu}
                className="mr-6 bg-transparent cursor-pointer"
              >
                <Icon icon="menu-mobile" className="hover:opacity-80" />
              </button>
              <div className="block md:hidden">
                <Profile />
              </div>
            </div>
          </div>
          <div>
            <BrandLogo color="yellow" />
          </div>
          {/* desktop search form */}
          <div className="hidden md:block flex-grow mx-9 max-w-[680px]">
            <CustomSearchBoxV2 />
          </div>

          <div className="flex items-center">
            {showSubscribeAndSave && (
              <div className="hidden xl:flex flex-row xl:mr-4">
                <SubscribeAndSave onToggle={handleOpenSubscribeFlow} />
              </div>
            )}

            {/*  save list icon */}
            <div className="-mt-[3px] mr-5">
              <YourListControl />
            </div>

            {/* desktop profile icon */}
            <div className="hidden md:block mr-5">
              <Profile />
            </div>

            {/* desktop save list panel */}
            <div className="hidden md:block">
              <YourList />
            </div>
            {/* desktop customer service icon */}
            <div className="hidden md:block">
              <UserNav />
            </div>
          </div>
        </div>

        {/* mobile search input below logo */}
        <div className="md:hidden">
          <CustomSearchBoxV2 />
        </div>
        <div>
          <SearchPanel />

          <div
            className={clsx(
              'overlay fixed w-full h-full top-0 left-0 bottom-0 right-0 z-[2] bg-[#464544] opacity-0 cursor-pointer duration-350 ease-out transition-all',
              {
                'invisible ': !isSearchFormFocused,
                'visible opacity-40': isSearchFormFocused,
              }
            )}
          ></div>
        </div>
      </div>
      <div className="block md:hidden">
        <YourList />
      </div>
    </>
  );
};

export default MainV2;
