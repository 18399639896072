import { LoadingDots } from '@components/ui';

const Spinner = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-col justify-center items-center py-40">
      <LoadingDots />
      <p>{title}</p>
    </div>
  );
};

export default Spinner;
