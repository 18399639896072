import React, { useContext } from 'react';
import { EventContext } from 'providers/EventProvider';
import Image from 'next/image';
import { useUI } from '@components/ui';
import styles from './styles';

const Profile = () => {
  const { toggleSidebar, setSidebarView } = useUI();
  const { gtm } = useContext(EventContext);
  const handleOpenProfile = () => {
    setSidebarView('USER_PORTAL_VIEW');
    toggleSidebar();
  };

  const handleClick = () => gtm.tagClickMyProfile();

  return (
    <div className="profile-control">
      <a target="blank" onClick={handleClick}>
        <button type="button" className="profile-control-button" onClick={handleOpenProfile}>
          <div className="profile-control-wrapper">
            <Image width={20} height={20} src="/icons/header-user.svg" alt="Profile" />
          </div>
        </button>
      </a>
      <style jsx>{styles}</style>
    </div>
  );
};

export default Profile;
