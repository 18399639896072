const FilterAlt = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.6875 2.5H10V0.9375C10 0.417969 9.58203 0 9.0625 0H7.1875C6.66797 0 6.25 0.417969 6.25 0.9375V2.5H0.3125C0.140625 2.5 0 2.64062 0 2.8125V3.4375C0 3.60938 0.140625 3.75 0.3125 3.75H6.25V5.3125C6.25 5.83203 6.66797 6.25 7.1875 6.25H9.0625C9.58203 6.25 10 5.83203 10 5.3125V3.75H19.6875C19.8594 3.75 20 3.60938 20 3.4375V2.8125C20 2.64062 19.8594 2.5 19.6875 2.5ZM8.75 5H7.5V1.25H8.75V5ZM19.6875 8.125H16.25V6.5625C16.25 6.04297 15.832 5.625 15.3125 5.625H13.4375C12.918 5.625 12.5 6.04297 12.5 6.5625V8.125H0.3125C0.140625 8.125 0 8.26562 0 8.4375V9.0625C0 9.23438 0.140625 9.375 0.3125 9.375H12.5V10.9375C12.5 11.457 12.918 11.875 13.4375 11.875H15.3125C15.832 11.875 16.25 11.457 16.25 10.9375V9.375H19.6875C19.8594 9.375 20 9.23438 20 9.0625V8.4375C20 8.26562 19.8594 8.125 19.6875 8.125ZM15 10.625H13.75V6.875H15V10.625Z"
        fill="#464544"
      />
    </svg>
  );
};

export default FilterAlt;
