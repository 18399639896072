const Facebook = ({ ...props }) => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 8.049C16 3.604 12.418 0 8 0S0 3.604 0 8.049C0 12.066 2.925 15.396 6.75 16v-5.624H4.719V8.049H6.75V6.276c0-2.018 1.194-3.132 3.022-3.132.875 0 1.79.157 1.79.157v1.981h-1.008c-.994 0-1.304.62-1.304 1.258v1.509h2.219l-.355 2.327H9.25V16c3.825-.604 6.75-3.934 6.75-7.951Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Facebook;
