import { pluck } from 'utils';

const deg2rad = (degrees) => {
  var pi = Math.PI;
  return degrees * (pi / 180);
};

export const distanceMeters = (lat1, lon1, lat2, lon2) => {
  let x = deg2rad(lon1 - lon2) * Math.cos(deg2rad(lat1));
  let y = deg2rad(lat1 - lat2);
  let dist = 6371000.0 * Math.sqrt(x * x + y * y);
  return Math.round(dist);
};

export const sortLocations = (lat, lon, locations) => {
  const sortedLocations = locations.map((location) => ({
    ...location,
    distance: distanceMeters(lat, lon, location.lat, location.lng),
  }));

  sortedLocations.sort((a, b) => (a.distance < b.distance ? -1 : 1));

  return sortedLocations;
};

export const getStateByPostcode = (code) => {
  const int = parseInt(code);
  switch (code[0]) {
    case '0':
      return 'Northern Territory';
    case '2':
      if ((int >= 2600 && int <= 2618) || int >= 2900) {
        return 'Australian Capital Territory';
      }
      return 'New South Wales';
    case '4':
      return 'Queensland';
    case '5':
      return 'South Australia';
    case '6':
      return 'Western Australia';
    case '7':
      return 'Tasmania';
    case '3':
    default:
      return 'Victoria';
  }
};

export const getLocationQuery = (search) => {
  const postcode = parseInt(search);
  let state;

  if (!Number.isNaN(postcode)) {
    state = getStateByPostcode(search);
  }

  let query = [search];
  if (state) {
    query.splice(0, 0, state);
  }

  if (!query.join(' ').toLowerCase().includes('australia')) {
    query.push('Australia');
  }

  return `https://maps.googleapis.com/maps/api/geocode/json?address=${query.join(
    ','
  )}&key=AIzaSyBJueo_0M63sCgif_bTUV7ueAQOIKISQ60&region=au`;
};

export const getGeolocation = () => {
  return new Promise((resolve) => {
    const success = async (e) => {
      const lat = pluck(e, 'coords.latitude');
      const lng = pluck(e, 'coords.longitude');

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBJueo_0M63sCgif_bTUV7ueAQOIKISQ60`
      ).then((r) => r.json());
      const address = pluck(response, 'results.1.formatted_address');

      resolve({
        lat,
        lng,
        address,
      });
    };

    const error = () => resolve(null);

    navigator.geolocation.getCurrentPosition(success, error);
  });
};
