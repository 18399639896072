/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

const TextOnlyContent = ({ block }) => {
  const { background_color, content } = block.primary;

  return (
    <>
      <div style={{ backgroundColor: `${background_color}` }}>
        <div className="text-only-content wrapper-small">
          <section id="our-story" className="text-only-content-info">
            <div className="text-only-content-info-content">{RichText.render(content)}</div>
          </section>
        </div>
      </div>
    </>
  );
};

TextOnlyContent.propTypes = {
  block: PropTypes.object.isRequired,
};

export default TextOnlyContent;
