const ChevronUp = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9055 6.23013L11.7145 6.4211C11.5881 6.54752 11.3837 6.54752 11.2573 6.4211L6.00151 1.16264L0.743044 6.4211C0.616626 6.54752 0.412205 6.54752 0.285786 6.4211L0.0948138 6.23013C-0.0316046 6.10371 -0.0316046 5.89929 0.0948138 5.77287L5.77019 0.0948137C5.89661 -0.0316046 6.10103 -0.0316046 6.22745 0.0948137L11.9028 5.77287C12.0319 5.89929 12.0319 6.10371 11.9055 6.23013Z"
        fill={props?.fill ?? '#464544'}
      />
    </svg>
  );
};

export default ChevronUp;
