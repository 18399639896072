import React, { useEffect, useState, useContext } from 'react';
import Button from 'components/Button';
import { StorefrontContext } from '../../providers/Storefront';

const Support = ({ showErrorMessage }) => {
  const { deliveryStore } = useContext(StorefrontContext);
  const [hasDeliveryStore, setHasDeliveryStore] = useState(false);

  useEffect(() => {
    if (deliveryStore?.storeName) setHasDeliveryStore(true);
  }, [deliveryStore]);

  const handleClick = () => {
    if (typeof window !== 'undefined') document.querySelector('.delivery-location span').click();
  };

  return (
    <>
      <div className="wrapper support">
        {showErrorMessage && <div className="support-heading">SOMETHING WENT WRONG</div>}

        <div className="support-heading">STILL NEED HELP?</div>
        <div className="support-description">Please contact us, we are here to help!</div>
        <div className="support-buttons">
          <a href="tel:1800 958 316">
            <Button cta appearance="tertiary" iconBefore="phone" label="Show number" />
          </a>
          <a href="/stores">
            <Button cta appearance="tertiary" iconBefore="my-store" label="Store finder" />
          </a>
          {!hasDeliveryStore && (
            <Button
              onClick={handleClick}
              cta
              appearance="tertiary"
              iconBefore="cross-location"
              label="Set my local store"
            />
          )}
          <a href="mailto:service@rejectshop.com.au">
            <Button cta appearance="tertiary" iconBefore="mail-new" label="Email" />
          </a>
          <a href="/customerservice/faq">
            <Button cta appearance="tertiary" iconBefore="faqs" label="FAQ's" />
          </a>
          <Button
            onclick="zE.activate();"
            cta
            appearance="tertiary"
            iconBefore="help-chat"
            label="Help"
          />
        </div>
        <div className="support-border" />
      </div>

      <style jsx global>
        {`
          .support .buttonx {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        `}
      </style>
      <style jsx>
        {`
          .support {
            padding: 40px 30px;
          }
          .support-border {
            padding-top: 80px;
            border-bottom: 1px solid #f4f4f4;
          }
          .support-heading {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700;
            color: #272727;
            padding-bottom: 6px;
          }
          .support-description {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            color: #272727;
            padding-bottom: 20px;
          }
          .support-buttons {
            display: flex;
            overflow-x: auto;
          }
          .support-buttons::-webkit-scrollbar {
            display: none;
          }
          @media (max-width: 768px) {
            .support {
              padding: 40px 16px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Support;
