import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { useUI } from '../context';
import s from './Sidebar.module.css';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';

interface SidebarProps {
  children: any;
  onClose: () => void;
  slideInFromClassName: string;
  classNames?: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  children,
  onClose,
  slideInFromClassName,
  classNames,
}) => {
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { sidebarIsClosing } = useUI();
  
  const [_myStore] = useStoreFinderStorage(null);
  const isStoreSet = _myStore ? true : false;

  const handleClose = () => {
    onClose();
  };

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus();
    }

    const contentElement = contentRef.current;

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);
  return (
    <div className={cn(s.root)} ref={sidebarRef} onKeyDown={onKeyDownSidebar} tabIndex={1}>
      <div className="absolute inset-0 overflow-hidden">
        <div
          className={`${classNames.includes('mobileMenuCls') ? '' : s.backdrop}`}
          onClick={handleClose}
        />
        <section
          className={cn(
            'fixed flex outline-none',
            slideInFromClassName,
            { slideOut: sidebarIsClosing, slideIn: !sidebarIsClosing },
            {
              'right-0 inset-y-0': slideInFromClassName === 'slideInFromRight',
            },
            { 'left-0 inset-y-0': slideInFromClassName === 'slideInFromLeft' },
            {
              'bottom-0': slideInFromClassName === 'slideInFromBottom',
            },
            {
              'top-0': slideInFromClassName === 'slideInFromTop',
            },
            classNames
          )}
        >
          <div className="h-full w-full ">
            <div
              className={cn(s.sidebar, {
                'rounded-t-lg': slideInFromClassName === 'slideInFromBottom',
              })}
              ref={contentRef}
            >
              {children}
            </div>
          </div>
        </section>
      </div>
      <style jsx>
        {`
          .slideInFromRight.slideIn {
            right: -100vh;
            -webkit-animation: slideInFromRight 0.2s forwards;
            animation: slideInFromRight 0.2s forwards;
          }

          .slideInFromRight.slideOut {
            right: 0;
            -webkit-animation: slideOutToRight 0.2s forwards;
            animation: slideOutToRight 0.2s forwards;
          }

          @-webkit-keyframes slideInFromRight {
            100% {
              right: 0;
            }
          }

          @keyframes slideInFromRight {
            100% {
              right: 0;
            }
          }

          @-webkit-keyframes slideOutToRight {
            100% {
              right: -100vh;
            }
          }

          @keyframes slideOutToRight {
            100% {
              right: -100vh;
            }
          }

          .slideInFromLeft.slideIn {
            left: -100vh;
            -webkit-animation: slideInFromLeft 0.2s forwards;
            animation: slideInFromLeft 0.2s forwards;
            top: ${isStoreSet
              ? classNames.includes('mobileMenuCls')
                ? '161px'
                : '0px'
              : classNames.includes('mobileMenuCls')
              ? '152px'
              : '0px'};
          }
          @media (min-width: 507px) {
            .slideInFromLeft.slideIn {
              top: ${classNames.includes('mobileMenuCls') ? '152px' : '0px'};
            }
          }
          @media (min-width: 768px) {
            .slideInFromLeft.slideIn {
              top: ${classNames.includes('mobileMenuCls') ? '116px' : '0px'};
            }
          }

          @media (min-width: 1024px) {
            .slideInFromLeft.slideIn {
              top: ${classNames.includes('mobileMenuCls') ? '161px' : '0px'};
            }
          }

          .slideInFromLeft.slideOut {
            right: 0;
            -webkit-animation: slideOutToLeft 0.2s forwards;
            animation: slideOutToLeft 0.2s forwards;
          }

          @-webkit-keyframes slideInFromLeft {
            100% {
              left: 0;
            }
          }

          @keyframes slideInFromLeft {
            100% {
              left: 0;
            }
          }

          @-webkit-keyframes slideOutToLeft {
            100% {
              left: -100vh;
            }
          }

          @keyframes slideOutToLeft {
            100% {
              left: -100vh;
            }
          }

          .slideInFromBottom.slideIn {
            bottom: -100vh;
            -webkit-animation: slideInFromBottom 0.2s forwards;
            animation: slideInFromBottom 0.2s forwards;
          }

          .slideInFromBottom.slideOut {
            bottom: 0;
            -webkit-animation: slideOutToBottom 0.2s forwards;
            animation: slideOutToBottom 0.2s forwards;
          }

          @-webkit-keyframes slideInFromBottom {
            100% {
              bottom: 0;
            }
          }

          @keyframes slideInFromBottom {
            100% {
              bottom: 0;
            }
          }

          @-webkit-keyframes slideOutToBottom {
            100% {
              bottom: -100vh;
            }
          }

          @keyframes slideOutToBottom {
            100% {
              bottom: -100vh;
            }
          }

          .slideInFromTop.slideIn {
            top: -100vh;
            -webkit-animation: slideInFromTop 0.2s forwards;
            animation: slideInFromTop 0.2s forwards;
          }

          .slideInFromTop.slideOut {
            top: 0;
            -webkit-animation: slideOutToTop 0.2s forwards;
            animation: slideOutToTop 0.2s forwards;
          }

          @-webkit-keyframes slideInFromTop {
            100% {
              top: 0;
            }
          }

          @keyframes slideInFromTop {
            100% {
              top: 0;
            }
          }

          @-webkit-keyframes slideOutToTop {
            100% {
              top: -100vh;
            }
          }

          @keyframes slideOutToTop {
            100% {
              top: -100vh;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          body {
            ${!sidebarIsClosing ? 'position: relative !important;' : ''} {
            }
          }
        `}
      </style>
    </div>
  );
};

export default Sidebar;
