const MyStore = ({ className = '', ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M21.8178 11.5781C22.047 11.782 22.0679 12.109 21.8352 12.3727C21.665 12.6047 21.3107 12.6258 21.0815 12.3902L19.7825 11.2301V18.1875C19.7825 19.7414 18.5391 21 17.004 21H7.00134C5.46691 21 4.22282 19.7414 4.22282 18.1875V11.2301L2.92352 12.3902C2.69325 12.6258 2.34208 12.6047 2.13904 12.3727C1.93596 12.109 1.95791 11.782 2.18804 11.5781L11.6345 3.1407C11.8464 2.9531 12.159 2.9531 12.3708 3.1407L21.8178 11.5781ZM7.00134 19.875H9.22416V14.25C9.22416 13.6277 9.72082 13.125 10.3356 13.125H13.6698C14.2845 13.125 14.7812 13.6277 14.7812 14.25V19.875H17.004C17.9244 19.875 18.6711 19.1191 18.6711 18.1875V10.2668L12.0027 4.31273L5.33423 10.2668V18.1875C5.33423 19.1191 6.08096 19.875 7.00134 19.875ZM10.3356 19.875H13.6698V14.25H10.3356V19.875Z"
        fill="#333333"
      />
    </svg>
  );
};

export default MyStore;
