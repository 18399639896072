import React, { useContext, useEffect, useState } from 'react';
import s from './index.module.css';
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';
import { useUserPortal } from '@lib/userportal';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import { getNearestStore } from '@lib/store-finder-page/helpers/getNearestStore';
import useStore from '@lib/store-finder-page/hooks/useStore';
import getGeolocation from '@lib/helpers/getIpGeoLocation';
import { Cross } from '@components/icons';
import { useUI } from '@components/ui';

function StoreConfirmTip() {
  const { emit, on } = useContext(LocalisationConnectorContext);
  const [_store, setLocalStore] = useStoreFinderStorage(null);
  const { stores, setAsStore } = useStore();
  const [{ user }, { setUser }] = useUserPortal();
  const [latestStore, setLatestStore] = useState(null);

  on('store_finder_page_set_store', (store) => {
    setLatestStore(store);
  });

  const handleSetStore = () => {
    setAsStore(latestStore);
    setLocalStore(latestStore);
    if (emit) {
      emit('store_finder_page_set_store', latestStore);
    }
    setLatestStore(null);
    const savedStore = latestStore
      ? {
          id: latestStore?.id,
          name: latestStore?.name,
        }
      : null;
    setUser({
      ...user,
      store: savedStore,
    });
  };

  const handleClose = () => {
    setLatestStore(null);
  };

  useEffect(() => {
    const fetchPreferedStore = async () => {
      const geoLocation: any = await getGeolocation();
      if (geoLocation && geoLocation?.lng && geoLocation?.lat) {
        const sortedStore = await getNearestStore({
          lng: geoLocation?.lng,
          lat: geoLocation?.lat,
          returnItems: 5,
        });
        if (!_store && sortedStore && sortedStore.length > 0) {
          setLatestStore(sortedStore[0]);
        }
      }
    };
    fetchPreferedStore();
  }, []);

  if (user && user.id) {
    return null;
  }

  if (!latestStore) {
    return null;
  }

  return (
    <div className={s.container}>
      <div onClick={handleClose} className={s.close}>
        <Cross width={'10'} height={'10'} />
      </div>
      <p className={s.titleText}>
        Is <strong className={s.textStrong}>{latestStore?.name}</strong> still your preferred store.
      </p>
      <button onClick={handleSetStore} className={s.confirmButton}>
        <span className={s.buttonText}>Yes, set as my store</span>
      </button>
    </div>
  );
}

export default StoreConfirmTip;
