import { Cross, Store } from '@components/icons';
import React, { useContext, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useYupValidationResolver } from '@lib/hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import cn, { clsx } from 'clsx';
import API from '@lib/userportal/services/api';
import dataLayer from '@lib/userportal/utils/dataLayer';
import { EventContext } from 'providers/EventProvider';
import useLocalStorage from '@lib/hooks/useLocalStorage';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';
import { useUserPortal } from '@lib/userportal';
import { useUI } from '@components/ui';
import s from './index.module.css';
import { SIGN_STATUS, EventType } from '../type';

type EventObj = {
  title: string;
  description: string;
};
type EventMappingType = {
  [key in EventType]?: EventObj;
};

type UserType = {
  name: string;
  email: string;
};

const events: EventMappingType = {
  'Exit Intent': {
    title: 'Before You Go...',
    description: `Make your inbox the most valuable shopping tool at your disposal! Our newsletter subscribers are the first to hear about everyday value, new low prices and new stock, because we know there's nothing worse than missing out on the products you didn't get`,
  },
  'Timed Pop up': {
    title: 'Get value straight to your inbox',
    description: `We'll share the latest news, products and offers for your favourite store to help you get the most value out of every shop when you sign up today!`,
  },
  ContactUs: {
    title: 'Before You Go...',
    description: `We'll share the latest news, products and offers for your favourite store to help you get the most value out of every shop when you sign up today!`,
  },
  Blog: {
    title: 'Enjoying this article?',
    description: `We share regular hints, tips and tricks on a (roughly) monthly basis, focused on helping our readers get the most value out of their everyday shop!!.`,
  },
};

const validationSchema = yup.object<UserType>({
  email: yup.string().email().required('Please provide your email address'),
  name: yup.string().required('Please provide your first name'),
});

interface SignupProps {
  popupType: EventType;
  showSignup: boolean;
  closeSignup: () => void;
}

function NewsLetterSignup({ showSignup, popupType, closeSignup }: SignupProps) {
  const { openSidebar, setSidebarView } = useUI();
  const [loading, setLoading] = useState(false);
  const [signStatus, setSignStatus] = useState<SIGN_STATUS>('Init');
  const [storeInfo] = useStoreFinderStorage(null);
  const [userId, saveUserId] = useLocalStorage('userId', null, { withExpiry: false });
  const [{ user }, { setUser, setSignupAlready }] = useUserPortal();
  const { gtm } = useContext(EventContext);
  const { emit } = useContext(LocalisationConnectorContext);
  const resolver = useYupValidationResolver(validationSchema);
  const eventType = popupType as string;
  const showOverlay = eventType === 'Exit Intent' || eventType === 'Timed Pop up';

  const event: EventObj = events[popupType];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserType>({
    resolver,
  });

  const openStoreSidebar = () => {
    setSidebarView('STORE_FINDER_VIEW');
    openSidebar();
    gtm.tagClickChangeStore();
  };

  const handleSignup = (data: UserType) => {
    validationSchema.isValid(data).then(async () => {
      // check email exist on omneo
      setLoading(true);
      const savedStore = storeInfo
        ? {
            id: storeInfo?.id,
            name: storeInfo?.name,
          }
        : null;
      API.getUserAvailability(data.email)
        .then(async (result) => {
          // new ??  sign up here
          if (result?.email?.available) {
            try {
              const response = await API.createUser({
                email: data.email,
                name: data.name,
                preferred_location_id: storeInfo?.id ?? undefined,
              });
              if (response.id && response.email) {
                saveUserId(response.id);
                setUser({
                  ...user,
                  id: response.id,
                  email: data.email,
                  name: data.name,
                  store: savedStore,
                });
                setSignStatus('Success');
              }
              if (dataLayer) {
                gtm.tagSignupSuccess({ label: `NewsLetter_${eventType}` });
              }
            } catch (error) {
              setSignStatus('Failed');
              closeSignup();
              console.error('error', error);
            } finally {
              setLoading(false);
            }
          } else {
            setSignStatus('Already');
            setSignupAlready(true);
            // saveUserId(result?.email?.profile?.id);
            // setUser({
            //   ...user,
            //   id: result?.email?.profile?.id,
            //   email: data.email,
            //   name: data.name,
            //   store: savedStore,
            // });
            // closeSignup();
            setLoading(false);
          }
        })
        .then(() => {
          // if (dataLayer) {
          //   gtm.tagClickSubscribeFlow({ label: 'Signup_Check_Account' });
          // }
        });
    });
  };

  const handleClose = () => {
    closeSignup();
  };

  const handleOverlayClick = () => {
    closeSignup();
  };

  const handleSignupAlreay = () => {
    setSignupAlready(true);
    closeSignup();
  };

  const formStyle = cn({
    [s.signupWidget]: eventType === 'ContactUs',
    [s.signupModal]: eventType !== 'ContactUs',
  });

  return (
    <>
      {showSignup ? (
        <div className={clsx(s.signupContainer)}>
          {showOverlay && <div className={s.overlay} onClick={handleOverlayClick}></div>}
          <form className={formStyle}>
            <div className={s.signupLeft}>
              {signStatus === 'Init' && (
                <div>
                  <h1 className={s.signupTitle}>{event?.title}</h1>
                  <p className={s.signupDesc}>
                    {event?.description} {popupType === 'Exit Intent' && <span>&#128521;</span>}
                  </p>
                  <div>
                    <input
                      type="text"
                      {...register('name')}
                      className={cn(s.signupInput, {
                        [s.signupInputNormal]: !errors?.name,
                        [s.signupInputError]: errors?.name,
                      })}
                      placeholder="First Name"
                    />
                    {errors?.name && <p className={s.signupErrorTips}>{errors?.name.message}</p>}
                  </div>
                  <div>
                    <input
                      type="text"
                      {...register('email')}
                      className={cn(s.signupInput, {
                        [s.signupInputNormal]: !errors?.email,
                        [s.signupInputError]: errors?.email,
                      })}
                      placeholder="Email"
                    />
                    {errors?.email && <p className={s.signupErrorTips}>{errors?.email.message}</p>}
                  </div>
                  <button
                    disabled={loading}
                    className={s.signupButton}
                    onClick={handleSubmit(handleSignup)}
                  >
                    {loading ? 'Sign up, wait...' : 'Sign me up!'}
                  </button>
                  <p className={s.signupTips}>
                    Don't worry, we hate spam as much as you do and promise not to bombard your
                    inbox. Your personal information will be handed in accordance with The Reject
                    Shop's Privacy Policy.
                  </p>
                  {eventType !== 'ContactUs' && (
                    <div className={s.signupAlready}>
                      <button onClick={handleSignupAlreay} className={s.signupAlreadyBtn}>
                        I'm already a member
                      </button>
                    </div>
                  )}
                </div>
              )}
              {(signStatus === 'Already' || signStatus === 'Success') && (
                <div className={s.signupSuccess}>
                  <h1 className={s.signupSuccessTitle}>
                    {signStatus === 'Success'
                      ? 'You’re all signed up!'
                      : 'You’re already signed up!'}
                  </h1>
                  <p className={s.signupSuccessDesc}>
                    We'll share the latest news, products and offers for your favourite store to
                    help you get the most value out of every shop when you sign up today!
                  </p>
                  <h3 className={s.signupSuccessSubtitle}>
                    While you're here let's set your favourite store
                  </h3>
                  <button
                    onClick={() => {
                      closeSignup();
                      openStoreSidebar();
                    }}
                    className={s.signupSuccessButton}
                  >
                    <div className={s.signupSuccessButtonWrapper}>
                      <Store />
                      <p className={s.signupSuccessBtnText}>Find my store</p>
                    </div>
                  </button>
                </div>
              )}
            </div>
            <div className={s.signupRight}>
              <div className={s.signupClose} onClick={handleClose}>
                <Cross width={'22'} height={'22'} fill="#FFFFFF" />
              </div>
              <h1 className={s.signupRightTitle}>Enjoy Real Low Prices Every Day.</h1>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
}

export default NewsLetterSignup;
