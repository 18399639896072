import cn from 'classnames';
import Image from 'next/image';
import { LegacyCross } from '@components/icons';
import { Button } from '@components/ui';
import HelpSupport from '../HelpSupport';
import s from './ServicePortalSidebar.module.css';

const ServicePortalSidebar = ({ onClose }: { onClose: () => void }) => {
  const handleClose = () => onClose();

  return (
    <div className={cn(s.root)}>
      <div className={s.container}>
        <div className="sticky top-0 z-10 block">
          <header className="px-6 py-4 flex items-center justify-start bg-brand-white w-full z-10 border-b border-b-ui-grey-25">
            <Image
              src="/icons/back-arrow.svg"
              onClick={handleClose}
              alt="back-arrow"
              width="16"
              height="16"
              className={s.backArrow}
            />
            <div className="text-text-dark text-body2 md:text-label font-medium text-center flex-1">
              CUSTOMER SERVICE
            </div>
            {handleClose && (
              <button
                onClick={handleClose}
                aria-label="Close"
                className="hover:text-accent-5 bg-transparent transition ease-in-out duration-150 focus:outline-none ml-auto"
              >
                <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3 cursor-pointer" />
              </button>
            )}
          </header>
        </div>

        <div className="md:block">
          <Image
            src="/images/sidecart-banner.svg"
            alt="Profile Banner"
            layout="responsive"
            height={207}
            width={368}
          />
        </div>
        <div className="mt-4">
          <HelpSupport heading="HELP & SUPPORT" />
        </div>
      </div>
    </div>
  );
};

export default ServicePortalSidebar;
