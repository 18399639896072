import React, { useContext } from 'react';
import Image from 'next/image';
import cn from 'classnames';

const SaveToListButton = ({ toggleWishlist, handle, actionText, isInWishlist }) => {
  const listIcon = isInWishlist ? 'heart-grey' : 'heart-empty';
  return (
    <>
      <button
        type="button"
        className="save-to-list"
        onClick={toggleWishlist}
        data-tag="Save product to shopping list"
        data-handle={handle}
        title={actionText}
      >
        <Image
          style={{ display: 'inline-block', width: '24px', height: '24px' }}
          width={24}
          height={24}
          color="#fff"
          src={`/icons/${listIcon}.svg`}
          alt={actionText}
        />
        <span className="saved-list-block-title">Add to shopping list</span>
      </button>
      <style jsx>
        {`
          .save-to-list {
            width: 100%;
            border-radius: 2px;
            border: 1px solid white;
            cursor: pointer;
            color: #fff;
            background: #cc0117;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 1rem;
          }
          .saved-list-block-title {
            padding: 0 8px 0 8px;
            font-size: 13px;
            line-height: 24px;
            font-weight: 450;
          }
        `}
      </style>
    </>
  );
};

export default SaveToListButton;
