import React, { useContext } from 'react';
import Image from 'next/image';
import cn from 'classnames';
import { useWishlist } from 'providers/Wishlist';
import { EventContext } from 'providers/EventProvider';
import SaveToListButton from './SaveToListButton.variant';
import SaveToListOrigin from './SaveToListOrigin.variant';

const SaveToList = (props) => {
  const { handle, title, image, price, sku, variant } = props;
  const { productIsInWishlist, addToWishlist, removeFromWishlist } = useWishlist();
  const { gtm } = useContext(EventContext);

  const toggleWishlist = () => {
    if (productIsInWishlist(handle)) {
      removeFromWishlist(handle);
      gtm.tagRemoveFromWishList({ label: `${sku}: ${title}` });
    } else {
      addToWishlist([{ handle, title, image, price, sku }]);
      gtm.tagSaveToWishList({ label: `${sku}: ${title}` });
    }
  };

  const isInWishlist = productIsInWishlist(handle);
  const actionText = isInWishlist ? 'Remove from List' : 'Save to List';
  const listIcon = isInWishlist ? 'heart-grey' : 'heart-white';

  return (
    <>
      {!variant && (
        <SaveToListOrigin
          toggleWishlist={toggleWishlist}
          handle={handle}
          actionText={actionText}
          isInWishlist={isInWishlist}
        />
      )}
      {variant && variant === 'button' && (
        <SaveToListButton
          toggleWishlist={toggleWishlist}
          handle={handle}
          actionText={actionText}
          isInWishlist={isInWishlist}
        />
      )}
    </>
  );
};

export default SaveToList;
