const Grid2 = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 3C3.22386 3 3 3.22386 3 3.5V10.5C3 10.7761 3.22386 11 3.5 11H10.5C10.7761 11 11 10.7761 11 10.5V3.5C11 3.22386 10.7761 3 10.5 3H3.5Z"
        fill="currentColor"
      />
      <path
        d="M13.5 3C13.2239 3 13 3.22386 13 3.5V10.5C13 10.7761 13.2239 11 13.5 11H20.5C20.7761 11 21 10.7761 21 10.5V3.5C21 3.22386 20.7761 3 20.5 3H13.5Z"
        fill="currentColor"
      />
      <path
        d="M3 13.5C3 13.2239 3.22386 13 3.5 13H10.5C10.7761 13 11 13.2239 11 13.5V20.5C11 20.7761 10.7761 21 10.5 21H3.5C3.22386 21 3 20.7761 3 20.5V13.5Z"
        fill="currentColor"
      />
      <path
        d="M13.5 13C13.2239 13 13 13.2239 13 13.5V20.5C13 20.7761 13.2239 21 13.5 21H20.5C20.7761 21 21 20.7761 21 20.5V13.5C21 13.2239 20.7761 13 20.5 13H13.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Grid2;
