import { getApiBaseUrl } from '@utils/url';
import get from 'lodash/get';
import { transformStoreLocationData } from './reducer';

export function getGeoLocation<T>() {
  return new Promise<T>((resolve, reject) => {
    const success = async (locationResponse: any) => {
      const lat = get(locationResponse, 'coords.latitude');
      const lng = get(locationResponse, 'coords.longitude');

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBJueo_0M63sCgif_bTUV7ueAQOIKISQ60`
      ).then((r) => r.json());

      const address = get(response, 'results.1.formatted_address', response);

      const addressComponent = get(response, 'results.1.address_components', response);
      const postCode =
        addressComponent && addressComponent.filter((c: any) => c?.types?.[0] === 'postal_code');
      const suburb =
        addressComponent && addressComponent.filter((c: any) => c?.types?.[0] === 'locality');
      const state =
        addressComponent &&
        addressComponent.filter((c: any) => c?.types?.[0] === 'administrative_area_level_1');

      const result = {
        lat,
        lng,
        address,
        postcode: postCode?.[0]?.short_name,
        suburb: suburb?.[0]?.short_name,
        state: state?.[0]?.short_name,
      };

      localStorage.setItem('address', JSON.stringify(result));

      // @ts-ignore
      resolve(result as T);
    };

    // @ts-ignore
    const error = () => resolve(null);

    // use browser navigator to get current lat and lang
    // on success fetch address data from google geo location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      reject('Geolocation is not supported by your browser');
    }
  });
}

export async function getNearestStore<T>({ lat, lng }: { lat: number; lng: number }): Promise<T> {
  try {
    const storeLocations = await fetch(`${getApiBaseUrl()}/api/locations/nearest-store`, {
      method: 'POST',
      body: JSON.stringify({
        location: {
          lat,
          lng,
        },
      }),
    }).then((r) => r.json());
    return storeLocations?.map((store) => transformStoreLocationData(store)) ?? [];
  } catch (error) {
    console.log('error', error);
  }
}
