/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { SearchLight } from '@components/icons';
import { LoadingDots } from '@components/ui';
import { getApiBaseUrl } from '@utils/url';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { useUserPortal } from '../context';
import PlacesAutocomplete from '../utils/PlacesAutocomplete';

interface State {
  address: string | undefined;
}

let previousController: any = null;

const fetchNearStores = (lat: number, lng: number, controller) => {
  const url = new URL(`/api/locations/nearest-store`, getApiBaseUrl());
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ location: { lat, lng }, returnItems: 5 }),
    signal: controller.signal,
  }).then((r) => r.json());
};

interface StoreSearchInputProps {
  onSelectStore: (store: any) => void;
  isValid?: boolean;
}

export function StoreSearchInput({ onSelectStore }: StoreSearchInputProps) {
  const [searchLocation, setSearchLocation] = React.useState<State>({ address: '' });
  const [isSearchingNearStore, setSearchingNearStores] = useState(false);

  const [
    {
      storeSearchResults,
      user: { store },
    },
    { setStoreSearchResult },
  ] = useUserPortal();

  const handleChange = (address: string) => {
    setSearchLocation({ address });
  };

  const handleSearchNearStores = (suggestions) => {
    // If there is an ongoing API call, abort it
    if (previousController) {
      previousController.abort();
    }

    const suggestion = suggestions?.[0];

    const controller = new AbortController();
    previousController = controller;

    setSearchingNearStores(true);
    setStoreSearchResult(null, []);

    geocodeByAddress(suggestion.description).then((results) => {
      const { lat, lng } = {
        // @ts-ignore
        lat: results[0]?.geometry.location.lat(),
        // @ts-ignore
        lng: results[0]?.geometry.location.lng(),
      };

      fetchNearStores(lat, lng, controller)
        .then((nearStores) => {
          setStoreSearchResult(results[0].formatted_address, nearStores);
          setSearchingNearStores(false);
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Previous search was aborted');
          } else {
            console.log(error);
          }
        });
    });
  };

  const handleSelect = (store: any) => {
    onSelectStore(store);
    setStoreSearchResult(null, []);
  };

  useEffect(() => {
    if (store) {
      setSearchLocation({
        address: store.name,
      });
    }
  }, [store]);

  return (
    <PlacesAutocomplete
      // @ts-ignore
      value={searchLocation?.address}
      onSuggestions={handleSearchNearStores}
      onChange={handleChange}
      searchOptions={{
        componentRestrictions: {
          country: ['au'],
        },
      }}
    >
      {({ getInputProps, loading }) => {
        return (
          <div>
            <div className="border-gray-300 flex items-center bg-white px-4 py-[14px] border border-solid h-12 text-text-subdued rounded-sm focus-within:border-ui-dark-grey">
              <SearchLight />
              <input
                {...getInputProps({
                  placeholder: 'Search location ...',
                })}
                className="ml-2 bg-white w-full focus:outline-none text-darkest-grey"
              />
              <div>{loading && <div>...</div>}</div>
            </div>
            <div
              className={cn({
                'flex flex-col bg-white border text-text-subdued border-solid absolute mt-1 w-full rounded-sm':
                  storeSearchResults?.results?.length > 0 || isSearchingNearStore,
              })}
            >
              {isSearchingNearStore ? (
                <div className="flex justify-center items-center p-4">
                  <LoadingDots />
                </div>
              ) : (
                storeSearchResults?.results?.map((store, k) => {
                  return (
                    <div className="px-4 py-2 cursor-pointer hover:bg-ui-light-grey" key={k}>
                      <button
                        type="button"
                        onClick={() => handleSelect(store)}
                        className="w-full text-left"
                      >
                        {store.name}
                      </button>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
}
