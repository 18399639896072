const MinusV2 = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33337 10C3.33337 9.76988 3.51992 9.58334 3.75004 9.58334H16.25C16.4802 9.58334 16.6667 9.76988 16.6667 10C16.6667 10.2301 16.4802 10.4167 16.25 10.4167H3.75004C3.51992 10.4167 3.33337 10.2301 3.33337 10Z"
        fill="#333333"
      />
    </svg>
  );
};

export default MinusV2;
