import useSWR from 'swr';
import { StoreLocation } from '../types';

const useGetLocation = () => {
  return useSWR<{ locations: StoreLocation[] }>('getLocation', () =>
    fetch('/api/locations?limit=5').then((r) => r.json())
  );
};

export default useGetLocation;
