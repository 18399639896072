/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { EventContext } from 'providers/EventProvider';
import Button from '../components/Button';
import { Header } from '../components/Header';
import HeroSection from '../components/HeroSection';
import PromotionAccordion from '../components/PromotionAccordion';
import { useUserPortal } from '../context';
import { useYupValidationResolver } from '@lib/hooks/useYupValidationResolver';
import API from '../services/api';

import { User } from '../types';
import dataLayer from '../utils/dataLayer';

const validationSchema = yup.object<User>({
  email: yup
    .string()
    .email()
    .matches(/[^\s@]+@[^\s@]+\.[^\s@]+/gi, {
      message: 'Please check your email address is correct',
    })
    .required('Please provide your email address'),
});

export default function GetStarted() {
  const resolver = useYupValidationResolver(validationSchema);
  const [{ user, promotions, pageHeaders }, { setPage, setUser }] = useUserPortal();
  const [loading, setLoading] = useState(false);
  const { gtm } = useContext(EventContext);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
    reset,
  } = useForm<User>({
    resolver,
  });

  const handleNext = (data: User) => {
    try {
      setLoading(true);

      API.getUserAvailability(data.email)
        .then((result) => {
          setLoading(false);
          if (!result?.email?.available) {
            setError('email', {
              message: 'taken',
            });
            return;
          }
          setUser(data);
          setPage('JOIN_MEMBER');
        })
        .then(() => {
          if (dataLayer) {
            gtm.tagClickSubscribeFlow({ label: 'Getting_Started' });
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(user);
  }, [user]);

  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex flex-col px-5 py-10">
        <HeroSection
          title="SIGN UP TO OUR NEWSLETTERS!"
          subtitle={pageHeaders.getstarted_page_subtitle}
        />
        <section className="flex flex-col gap-6 my-8">
          {promotions?.map((promotion, key) => {
            return <PromotionAccordion promotion={promotion} key={key} />;
          })}
        </section>
        <footer className="flex flex-col gap-8">
          <div className="flex flex-col">
            <input
              type="text"
              className={clsx(
                'appearance-none h-12 font-13 leading-5 focus:outline-none focus:shadow-outline border border-solid p-4 w-full rounded-sm',
                {
                  'border-gray-300': !errors?.email,
                  'border-brand-red': !!errors?.email,
                }
              )}
              placeholder="Your email address"
              {...register('email')}
              disabled={loading}
            />
            {errors?.email && (
              <p className="text-brand-red text-xs italic">
                {errors?.email?.message === 'taken' ? (
                  <>
                    Looks like you&apos;ve already signed up, please{' '}
                    <a
                      href="https://my.rejectshop.com.au/"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    >
                      Login Here
                    </a>{' '}
                    to manage your account
                  </>
                ) : (
                  errors?.email?.message
                )}
              </p>
            )}
          </div>
          <Button disabled={loading} onClick={handleSubmit(handleNext)}>
            {loading ? `Checking...please wait` : `Get started`}
          </Button>
        </footer>
      </div>
    </div>
  );
}
