const Level = (props) => {
  const {
    className,
    direction,
    justify = 'start',
    align = 'center',
    spaceItems,
    onClick,
    children,
    style = {},
  } = props;

  let classNames = ['level'];
  if (direction) {
    classNames.push(`direction--${direction}`);
  }
  if (justify) {
    classNames.push(`justify--${justify}`);
  }
  if (align) {
    classNames.push(`align--${align}`);
  }
  if (spaceItems) {
    classNames.push(`space-items--${spaceItems}`);
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} onClick={onClick} style={style} role="navigation">
      {children}
    </div>
  );
};

export default Level;
