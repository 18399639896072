/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { transformStoreLocationData } from '@lib/store-finder-sidebar/reducer';
import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from 'react';
import { INITIAL_STATE, reducer } from './reducer';
import { APIGetCurrentUserResponse } from './services/api';
import { IAction, IHandlers, IState, InitialProps, Page, UseContenxt, User } from './types';

export const UserPortalContext = React.createContext<UseContenxt>([
  INITIAL_STATE,
  {
    setUserStore: (store: any) => {},
    setAuthenticatedUser: (user: APIGetCurrentUserResponse) => {},
    setUserInterests: (interest: string) => {},
    setUser: (user: User) => {},
    setSignupAlready: (sign: boolean) => {},
    setSignupCount: (count: number) => {},
    setPage: (page: Page) => {},
    onCloseSidebar: () => {},
    setStoreSearchResult: (address: string, stores: any[]) => {},
  },
]);

export const useUserPortal = () => {
  return useContext(UserPortalContext);
};

export const UserPortalProvider = ({
  children,
  onCloseSidebar,
  promotions,
  pageHeaders,
}: PropsWithChildren<InitialProps>) => {
  const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
    ...INITIAL_STATE,
    promotions,
    pageHeaders,
  });

  const { user, signupCount, signupAlready } = state;
  const setUser = (user: User) => dispatch({ type: 'SET_USER', payload: user });
  const setUserStore = (store: { id: string | number; name: string }) =>
    dispatch({ type: 'SET_USER_STORE', payload: store });
  const setSignupCount = (signCount: number) => {
    dispatch({ type: 'SET_SIGNUP_COUNT', payload: signCount });
  };
  const setSignupAlready = (signAlready: boolean) => {
    dispatch({ type: 'SET_SIGNUP_ALREADY', payload: signAlready });
  };

  useEffect(() => {
    if (!window) {
      return;
    }

    const storeFinderStr: string | null = window.localStorage.getItem('store-finder');
    if (storeFinderStr && storeFinderStr !== 'null') {
      const store = JSON.parse(storeFinderStr);
      setUserStore({ id: store.value.id, name: store.value.name });
    }

    const userString: string | null = window.localStorage.getItem('userId');
    if (userString && userString !== 'null') {
      setUser({ ...user, id: userString });
    }
    let signCount = window.sessionStorage.getItem('signup_popup_count');
    if (signCount && !isNaN(+signCount)) {
      setSignupCount(+signCount);
    }
    let signAlready = window.sessionStorage.getItem('signup_already');
    setSignupAlready(signAlready && signAlready === 'true');
  }, []);

  useEffect(() => {
    if (!window) {
      return;
    }
    const { id } = user;
    window.localStorage.setItem('userId', id);
  }, [user]);
  useEffect(() => {
    if (!window) {
      return;
    }
    window.sessionStorage.setItem('signup_popup_count', `${signupCount}`);
  }, [signupCount]);
  useEffect(() => {
    if (!window) {
      return;
    }
    window.sessionStorage.setItem('signup_already', signupAlready ? 'true' : 'false');
  }, [signupAlready]);

  const handlers: IHandlers = {
    setUser: setUser,
    setSignupCount,
    setSignupAlready,
    setUserStore,
    setUserInterests: (interest: string) =>
      dispatch({
        type: 'SET_USER_INTERESTED',
        payload: interest,
      }),
    setPage: (page: Page) =>
      dispatch({
        type: 'SET_PAGE',
        payload: page,
      }),
    setAuthenticatedUser: (user: APIGetCurrentUserResponse) =>
      dispatch({
        type: 'SET_AUTHENTICATED_USER',
        payload: user,
      }),
    onCloseSidebar,
    setStoreSearchResult: (address: string, stores: any[]) => {
      // dispatch({
      //   type: 'SET_SEARCH_STORE_RESULT',
      //   payload: {
      //     address: null,
      //     results: [],
      //   },
      // });
      dispatch({
        type: 'SET_SEARCH_STORE_RESULT',
        payload: {
          address,
          results: stores.map((store) => transformStoreLocationData(store)),
        },
      });
    },
  };

  return (
    <UserPortalContext.Provider value={[state, handlers]}>{children}</UserPortalContext.Provider>
  );
};
