const ClickAndCollect = ({ ...props }) => {
  return (
    <svg width={18} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m17.879 4.953-2.571-4.5A.904.904 0 0 0 14.526 0H3.473a.904.904 0 0 0-.782.453l-2.57 4.5A.899.899 0 0 0 .9 6.3h.9v7.158c0 .52.402.942.9.942h7.2c.497 0 .9-.422.9-.942V6.3h4.5v7.875c0 .124.1.225.224.225h.45a.226.226 0 0 0 .226-.225V6.3h.9a.899.899 0 0 0 .779-1.347Zm-7.98 8.505c0 .025-.008.04-.005.042l-7.178.006s-.016-.014-.016-.048V9.9h7.2v3.558ZM9.9 9H2.7V6.3h7.2V9ZM.903 5.4 3.473.9h11.053L17.1 5.4H.902Z"
        fill="#464544"
      />
    </svg>
  );
};

export default ClickAndCollect;
