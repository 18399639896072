import { useUI } from '@components/ui/context';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { ChevronRight } from '@components/icons';
import s from './ProfileSidebarItem.module.css';

export enum LinkType {
  EMAIL,
  PHONE,
  CHAT,
  INTERNAL,
  OTHERS,
}

const ProfileSidebarItem = ({
  icon,
  link,
  title,
  className = '',
  underline = true,
  showChevRight = true,
  onPress,
  linkType = LinkType.OTHERS,
}: {
  icon: any;
  title: string;
  link?: string;
  className?: string;
  underline?: boolean;
  showChevRight?: boolean;
  onPress?: () => void;
  linkType?: LinkType;
}) => {
  const { closeSidebarIfPresent } = useUI();
  const { push } = useRouter();
  const Icon = icon;

  const item = (
    <div className={cn('flex flex-col', underline ? 'py-4 border-b border-b-ui-grey-25 ' : 'mb-3')}>
      <div
        className={cn('flex flex-row justify-between items-center cursor-pointer')}
        onClick={() => {
          if (onPress) onPress();
        }}
      >
        <div className="flex flex-row space-x-4 items-center">
          <Icon />
          <span className="font-normal text-body2 text-text-subdued">{title}</span>
        </div>
        {showChevRight && <ChevronRight />}
      </div>
    </div>
  );
  if (!title) return null;

  const goToLink = () => {
    alert(link);
    closeSidebarIfPresent();
    push(link || '/');
  };

  if (linkType !== LinkType.OTHERS) {
    return <div className={cn(s.item, className)}>{link ? <a href={link}>{item}</a> : item}</div>;
  }

  return (
    <div className={cn(s.root, className)}>
      {link ? <button onClick={goToLink}>{item}</button> : item}
    </div>
  );
};

export default ProfileSidebarItem;
