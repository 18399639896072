import { Footer, Navbar } from '@components/Common';
import { LocalisationConnector } from '@containers/LocalisationConnector';
import { StoreSidebarProvider } from '@lib/store-finder-sidebar';
import config from 'brandConfig';
import PreviewMode from 'components/PreviewMode';
import { ManagedUIContext, useUI } from 'components/ui/context';
import PropTypes from 'prop-types';
import { WishlistProvider } from 'providers/Wishlist';
import { useEffect } from 'react';
import aa from 'search-insights';
import Head from './Head';
import Header from './Header';
import SidebarUI from './Sidebar';
import NewsLetterSignup from '@containers/Signup/NewsLetterSignup';
import { UserPortalProvider } from '@lib/userportal';
import { SubscribeAndSaveFloatingBar } from '@components/SubscribeAndSaveFloating';
import useSignupPopup from '@lib/hooks/useSignupPopup';
import Script from 'next/script';

// initiating aloglia search insights api
aa('init', {
  appId: config.brand.algoliaApplicationID,
  apiKey: config.brand.algoliaApiToken,
});

const LayoutContainer = ({
  menuItems,
  siteHeader,
  footer,
  children,
  handleClose,
  stores,
  preview,
}) => {
  const { showSignup, popupType, closeSignup } = useSignupPopup();
  return (
    <>
      <WishlistProvider>
        <Header headerData={siteHeader} />
        <Navbar links={[]} navItems={menuItems} />
        <main>{children}</main>
      </WishlistProvider>
      <StoreSidebarProvider>
        <SidebarUI menuItems={menuItems} stores={stores} closeSidebar={handleClose} />
      </StoreSidebarProvider>
      <Footer footer={footer} />
      <PreviewMode preview={preview} />
      <SubscribeAndSaveFloatingBar slices={menuItems} />
      <NewsLetterSignup showSignup={showSignup} popupType={popupType} closeSignup={closeSignup} />
    </>
  );
};

const UserProviderContainer = ({ mainNav, siteHeader, footer, children, stores, preview }) => {
  const { closeSidebar, setSideBarIsClosing } = useUI();
  const menuItems = mainNav;
  const getPromotions = () => {
    const slices = menuItems?.data?.slices3?.filter((d) => d.slice_type === 'accordion') ?? [];
    return slices?.map((slice) => ({
      id: slice.id,
      title: slice.primary.title?.[0]?.text,
      description: slice.primary.description?.[0]?.text,
      thumbnail: slice?.primary?.thumbnail?.url,
      items: slice?.items.map((item) => ({
        ...item,
        externallink: item?.externallink?.url,
        thumbnail: item?.thumbnail?.url,
      })),
    }));
  };

  const getPageHeaderLocalisations = () => {
    if (!menuItems?.data?.subscription_pageheaders?.[0]) {
      return {
        extrainfo_page_subtitle: [],
        getstarted_page_subtitle: [],
        join_page_subtitle: [],
        welcome_page_subtitle: [],
      };
    }
    const {
      extrainfo_page_subtitle,
      getstarted_page_subtitle,
      join_page_subtitle,
      welcome_page_subtitle,
    } = menuItems?.data?.subscription_pageheaders?.[0];
    return {
      extrainfo_page_subtitle,
      getstarted_page_subtitle,
      join_page_subtitle,
      welcome_page_subtitle,
    };
  };

  const handleClose = () => {
    setSideBarIsClosing(true);
    setTimeout(() => {
      setSideBarIsClosing(false);
      closeSidebar();
    }, 200);
  };

  return (
    <UserPortalProvider
      onCloseSidebar={handleClose}
      pageHeaders={getPageHeaderLocalisations()}
      promotions={getPromotions()}
    >
      <LayoutContainer
        menuItems={menuItems}
        siteHeader={siteHeader}
        footer={footer}
        stores={stores}
        preview={preview}
        handleClose={handleClose}
      >
        {children}
      </LayoutContainer>
    </UserPortalProvider>
  );
};

const Layout = ({ children, globalProps, preview }) => {
  const { mainNav, siteHeader, footer, stores } = globalProps || {};

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.zendeskKey}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }, []);

  return (
    <LocalisationConnector>
      <Head />
      <ManagedUIContext>
        <UserProviderContainer
          mainNav={mainNav}
          siteHeader={siteHeader}
          footer={footer}
          stores={stores}
          preview={preview}
        >
          {children}
        </UserProviderContainer>
      </ManagedUIContext>
      <Script
        id="klaviyo"
        strategy="lazyOnload"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RcQMwL`}
      />
    </LocalisationConnector>
  );
};

Layout.propTypes = {
  preview: PropTypes.bool,
};

Layout.defaultProps = {
  preview: false,
};

export default Layout;
