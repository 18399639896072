import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Link from 'next/link';
import { useContext } from 'react';
import { EventContext } from 'providers/EventProvider';

const BrandLogo = ({ className, color, style }) => {
  const { gtm } = useContext(EventContext);

  const classNames = ['brand-logo'];
  if (color) {
    classNames.push(`color-${color}`);
  }
  if (className) {
    classNames.push(className);
  }

  const handleClick = () => gtm.tagClickBrandLogo({ label: 'the reject shop' });

  return (
    <Link href="/" as="/" prefetch={false}>
      <div className={classNames.join(' ')} style={style} onClick={handleClick}>
        <Icon icon="trs-logo-lg" className="logo_rejectshop" />
        <Icon icon="trs-logo-mobile" className="logo_rejectshop_mobile" />
      </div>
    </Link>
  );
};

BrandLogo.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

BrandLogo.defaultProps = {
  className: '',
  color: 'red',
  style: {},
};

export default BrandLogo;
