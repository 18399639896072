import { ShopifyClientContext } from '@lib/shopify/client';
import { SearchPanelProducts } from '@lib/shopify/types/SearchPanelResults';

import { ProductSortKeys } from 'shopify-storefront-api-typings';

import getClient from '@lib/shopify/shopify-gql-client-setting/ShopifyGQL';
import defaultProductSearchFilter from '@lib/shopify/filters/default-product-search-filter';
import productsByQuery from '@lib/shopify/queries/products-by-query';

const getProductSearchResults = async (
  clientSettings: ShopifyClientContext,
  term: string,
  filters: any[] = defaultProductSearchFilter,
  first: number = 10,
  after: string | null = null,
  sortKey: ProductSortKeys | null = null,
  reverse: boolean = false
): Promise<SearchPanelProducts | null> => {
  try {
    const client = (context: ShopifyClientContext) => {
      return getClient(context.shopifyDomain, context.storefrontAccessToken);
    };

    const shopify = client(clientSettings);
    const query = decodeURI(term);

    // Add tag exclusion filter for 'availability:discontinued'
    const updatedFilters = [
      ...filters,
      {
        tag: { not: 'availability:discontinued' },
      },
    ];

    const variables = {
      query,
      first,
      filters,
      after,
      sortKey,
      reverse,
      next: { tags: ['search', 'products'] },
    };

    const searchResponse = await shopify(productsByQuery, variables);

    if (!searchResponse?.search) return null;
    const { search } = searchResponse;
    const searchPanelProductResults: SearchPanelProducts = {
      products: {
        edges: search.edges,
        pageInfo: search.pageInfo,
        filters: search.productFilters,
      },
    };
    return searchPanelProductResults;
  } catch (error) {
    console.log(`Could not fetch results for search: ["${term}"]`, error);
    return null;
  }
};

export default getProductSearchResults;
