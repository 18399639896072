/* eslint-disable react/button-has-type */
import React from 'react';
import Level from 'components/Level';
import Icon from 'components/Icon';

const ButtonIcon = (props) => {
  const { icon } = props;
  if (typeof icon === 'string') {
    return <Icon icon={icon} />;
  }

  return <Icon {...icon} />;
};

const Loader = () => {
  return <Level justify="center" className="buttonx-loader" />;
};

const Success = () => {
  return (
    <Level justify="center" className="buttonx-success">
      <Icon icon="check" />
    </Level>
  );
};

const Button = (props) => {
  const {
    className,
    iconBefore,
    iconAfter,
    children,
    onClick,
    size,
    isDisabled,
    isPressed,
    isLoading,
    isFullWidth,
    isSuccess,
    isCircle,
    cta,
    primary,
    secondary,
    banner,
    subtle,
    secondaryLarge,
    tertiary,
    appearance,
    label,
    dataAttributes,
    customizedStyles,
  } = props;

  let classNames = ['buttonx'];
  if (className) {
    classNames.push(className);
  }

  if (cta) {
    classNames.push('appearance-cta');
  }

  if (primary) {
    classNames.push('appearance-primary');
  }
  if (secondary) {
    classNames.push('appearance-secondary');
  }
  if (banner) {
    classNames.push('appearance-banner');
  }
  if (subtle) {
    classNames.push('appearance-subtle');
  }
  if (secondaryLarge) {
    classNames.push('appearance-secondaryLarge');
  }
  if (tertiary) {
    classNames.push('appearance-tertiary');
  }

  if (appearance) {
    classNames.push(`appearance-${appearance}`);
  }

  if (size) {
    classNames.push(`size-${size}`);
  }
  if (!isSuccess && isDisabled) {
    classNames.push(`is-disabled`);
  }
  if (isPressed) {
    classNames.push(`is-pressed`);
  }
  if (isLoading) {
    classNames.push(`is-loading is-pressed`);
  }
  if (isSuccess) {
    classNames.push(`is-success is-pressed`);
  }
  if (isFullWidth) {
    classNames.push(`is-fullwidth`);
  }
  if (isCircle) {
    classNames.push(`is-circle`);
  }

  return (
    <button
      className={classNames.join(' ')}
      onClick={onClick}
      data-order-online-button={dataAttributes && dataAttributes[0]['order-online-button']}
    >
      {isSuccess && <Success />}
      {isLoading && <Loader />}
      <Level className="buttonx-content" spaceItems="md">
        {iconBefore && <ButtonIcon icon={iconBefore} />}
        {label && <span style={customizedStyles}>{label}</span>}
        {children}
        {iconAfter && <ButtonIcon icon={iconAfter} />}
      </Level>
    </button>
  );
};

export default React.memo(Button);
