import React from 'react';
import { formatPrice } from 'utils';

const getValueByType = (array, type) => {
  if (!array || array.length === 0) return null;

  const found = array.find((e) => e.type === type);

  return found && found.value;
};

const CompareAtPrice = ({ compareAtPrice = 0 }) => {
  if (Number(compareAtPrice) === 0) {
    return null;
  }

  const valueParts = formatPrice(compareAtPrice) || [];
  const currency = getValueByType(valueParts, 'currency');
  const integer = getValueByType(valueParts, 'integer');
  const fraction = getValueByType(valueParts, 'fraction');

  return (
    <>
      <div className="product-card-competitor-price">
        {integer === 0 ? (
          <>
            <span>{fraction}</span>
            <span>¢</span>
          </>
        ) : (
          <>
            <span>{currency}</span>
            <span>{integer}</span>
            <span>.{fraction}</span>
          </>
        )}
      </div>
      <style jsx>
        {`
          .product-card-competitor-price {
            font-size: 13px;
            line-height: 24px;
            color: #939393;
            font-weight: 500;
            padding-left: 8px;
            text-decoration: line-through;
          }
        `}
      </style>
    </>
  );
};

export default React.memo(CompareAtPrice);
