/* eslint-disable no-unused-expressions */
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';
import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { getGeoLocation, getNearestStore } from './geoLocation';
import useGetLocation from './hooks/useGetLocations';
import useIsomorphicLayoutEffect from './hooks/useIsomorphicLayoutEffect';

import reducer, { INITIAL_STATE } from './reducer';
import { Action, State, StoreLocation } from './types';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';

type SearchByLocation = { latLng: { lat: number; lng: number }; address: string };
interface StoreSidebarProviderContextProps extends State {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  sortByNearestStore: () => void;
  sortByLocation: (value: SearchByLocation) => void;
  setMyStore: (store: StoreLocation) => void;
}

export const StoreSidebarContextProvider = React.createContext<StoreSidebarProviderContextProps>({
  ...INITIAL_STATE,
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  sortByNearestStore: () => {},
  sortByLocation: (value: SearchByLocation) => {},
  setMyStore: (store: StoreLocation) => {},
});

function StoreSidebarProvider({ children }: PropsWithChildren<any>) {
  const { data } = useGetLocation();

  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(reducer, INITIAL_STATE);
  const [isOpen, setIsOpen] = React.useState(false);
  const [storeFromStorage, setStorage] = useStoreFinderStorage(state?.myStore);
  
  const stores = data?.locations || [];

  const initialStores = useMemo(() => {
    if (stores.length === 0) return [];
    if (!storeFromStorage) return stores;
    if (stores.find((r) => r.id === storeFromStorage.id)) return stores;
    return [storeFromStorage, ...stores];
  }, [stores]);

  useIsomorphicLayoutEffect(() => {
    if (initialStores.length === 0) return;
    dispatch({
      type: 'LOAD_STORE',
      payload: initialStores,
    });
  }, [initialStores]);

  useEffect(() => {
    if (storeFromStorage) {
      dispatch({ type: 'SET_MY_STORE', payload: storeFromStorage });
    }
  }, [initialStores, storeFromStorage]);

  const handleOnOpen = () => setIsOpen(true);

  const handleOnClose = () => setIsOpen(false);

  const sortByNearestStore = async () => {
    dispatch({
      type: 'REQUEST_SORT_STORE',
    });

    const geoLocation = await getGeoLocation<StoreLocation>();

    if (!geoLocation) return;

    const sortedStoreLocations = await getNearestStore<StoreLocation[]>(geoLocation);

    dispatch({
      type: 'SORT_STORE',
      payload: sortedStoreLocations,
    });
  };

  const sortByLocation = async (value: SearchByLocation) => {
    dispatch({
      type: 'REQUEST_SORT_STORE_BY_LOCATION',
    });

    const geoLocation = value.latLng;
    const sortedStoreLocations = await getNearestStore<StoreLocation[]>(geoLocation);

    dispatch({
      type: 'SEARCH_BY_LOCATION',
      payload: value.address,
    });

    dispatch({
      type: 'SORT_STORE',
      payload: sortedStoreLocations,
    });
  };

  const { emit } = useContext(LocalisationConnectorContext);

  const setMyStore = (store: StoreLocation) => {
    if (emit) {
      emit('store_finder_sidebar_set_store', store);
    }

    dispatch({ type: 'SET_MY_STORE', payload: store });
    setStorage(store);
  };

  const values: StoreSidebarProviderContextProps = {
    ...state,
    isOpen,
    onOpen: handleOnOpen,
    onClose: handleOnClose,
    sortByNearestStore,
    sortByLocation,
    setMyStore,
  };

  return (
    <StoreSidebarContextProvider.Provider value={values}>
      {children}
    </StoreSidebarContextProvider.Provider>
  );
}

export default StoreSidebarProvider;
