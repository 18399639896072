import { triggerGTMEvent } from '../..';

const tagClickSearchBox = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'search_box',
    label: 'search box',
  });
};
export default tagClickSearchBox;
