import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import fetch from 'isomorphic-unfetch';
import PropTypes from 'prop-types';
import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';
import { getProductsFromAlgolia } from './helpers';
import { EventContext } from 'providers/EventProvider';

const Context = createContext(null);

const getPrefillProducts = async (list) => {
  const prefillValuesArray = list.split(',');

  const algoliaProduct = await getProductsFromAlgolia(prefillValuesArray, [
    'csr',
    'front-end',
    'wishlist',
  ]);

  return algoliaProduct;
};

const sendList = async (email, list, url) => {
  const now = Date.now();
  const route = '/api/wishlist';
  const body = { recipient: email, list, url };
  const signature = hmacSignature(process.env.NEXT_PUBLIC_API_KEY, now, route, body);
  const response = await fetch(route, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'x-authentication': `${now}:${signature}`,
    },
    body: JSON.stringify(body),
  });

  const responseObject = await response.json();

  return responseObject.sendMessageResult;
};

function WishlistProvider({ children }) {
  const [wishlist, setWishlist] = useState([]);
  const [prefillValues, setPrefillValues] = useState([]);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [initialised, setInitialised] = useState(false);
  const [listUIActive, setListUIActive] = useState(false);
  const { gtm } = useContext(EventContext);

  const {
    query: { listPrefill },
    pathname,
  } = useRouter();

  useEffect(() => {
    (async () => {
      if (!window) {
        return;
      }

      const listFromStorage = JSON.parse(window.localStorage.getItem('savedList'));

      const savedList = listFromStorage || [];

      setWishlist(savedList);
      setInitialised(true);

      if (listPrefill !== undefined && listPrefill) {
        const loadedPrefillValues = await getPrefillProducts(listPrefill, pathname);

        if (loadedPrefillValues.length) {
          setListUIActive(true);
        }

        setPrefillValues(loadedPrefillValues);
      }
    })();
  }, [listPrefill]);

  useEffect(() => {
    if (prefillValues.length) {
      addPrefillToWishlist();

      window.history.replaceState(null, null, window.location.pathname);
    }
  }, [prefillValues]);

  useEffect(() => {
    if (initialised) {
      window.localStorage.setItem('savedList', JSON.stringify(wishlist));
      setWishlistCount(wishlist.length);
    }
  }, [wishlist]);

  const toggleListUI = () => {
    const label = listUIActive ? 'OFF' : 'ON';
    gtm.tagToggleWishList({ label });

    setListUIActive(!listUIActive);
    if (!listUIActive) {
      gtm.tagViewWishList();
    }
  };

  const productIsInWishlist = (handle) => wishlist.find((product) => product.handle === handle);

  const addToWishlist = (items) => {
    const newItems = items
      .filter((item) => !productIsInWishlist(item.handle))
      .map((item) => {
        return item;
      });

    setWishlist([...newItems, ...wishlist]);
  };

  const removeFromWishlist = (handle) => {
    const newList = wishlist.filter((product) => product.handle !== handle);
    setWishlist(newList);
  };

  const clearWishlist = () => {
    setWishlist([]);
  };

  const hasPrefillValues = () => !!prefillValues.length;
  const addPrefillToWishlist = () => addToWishlist(prefillValues);
  const clearPrefillList = () => setPrefillValues([]);
  const saveList = (email, url) => sendList(email, wishlist, url);

  const provides = {
    wishlist,
    wishlistCount,
    hasPrefillValues,
    addPrefillToWishlist,
    clearPrefillList,
    productIsInWishlist,
    addToWishlist,
    removeFromWishlist,
    clearWishlist,
    listUIActive,
    toggleListUI,
    saveList,
  };

  return <Context.Provider value={{ ...provides }}>{children}</Context.Provider>;
}

WishlistProvider.propTypes = {
  children: PropTypes.array.isRequired,
};

const useWishlist = () => useContext(Context);

export { WishlistProvider, useWishlist };
