/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import Image from 'next/image';
import Accordion from '../components/Accordion';
import { ShopItem } from '../components/ShopItem';

import { Promotion } from '../types';

interface Props {
  promotion: Promotion;
}

export default function PromotionAccordion({ promotion }: Props) {
  return (
    // @ts-ignore
    <Accordion label={promotion.id}>
      <div className="flex flex-row">
        <div>
          <Image src={promotion.thumbnail} width="32" height="32" alt={promotion.title} />
        </div>
        <div className="flex flex-col flex-1 gap-1 pl-2 items-start justify-start text-left leading-normal whitespace-normal">
          <p className="text-darkest-grey text-13 uppercase font-semibold">{promotion.title}</p>
          <p className="text-text-subdued font-normal text-13">{promotion.description}</p>
        </div>
      </div>
      {promotion?.items?.length > 0 && (
        <div className="flex flex-col gap-4 w-full">
          {promotion?.items?.map((item, itemKey) => {
            return <ShopItem {...item} key={itemKey} />;
          })}
        </div>
      )}
    </Accordion>
  );
}
