/* eslint-disable import/extensions */

import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';

interface Props {
  title: string;
  subtitle?: RichTextField | string;
}

export default function HeroSection({ title, subtitle }: Props) {
  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-20 uppercase text-dark font-semibold leading-relaxed">{title}</h4>
      {typeof subtitle === 'string' || typeof subtitle === 'undefined' ? (
        <p className="text-16 text-text-subdued">{subtitle}</p>
      ) : (
        <div className="text-16 text-text-subdued">
          <PrismicRichText field={subtitle} />
        </div>
      )}
    </div>
  );
}
