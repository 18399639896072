const FilterArrowUp = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0209 5.06258L10.1251 5.95841L6.00008 1.83341L1.87508 5.95841L0.979248 5.06258L6.00008 0.041748L11.0209 5.06258Z"
        fill="#272727"
      />
    </svg>
  );
};

export default FilterArrowUp;
