import React from 'react';
import { formatPrice } from 'utils';

const getValueByType = (array, type) => {
  if (!array || array.length === 0) return null;

  const found = array.find((e) => e.type === type);

  return found && found.value;
};

const Price = ({
  className,
  classNameMain = '',
  classNameSecondary = '',
  style = {},
  price = 0,
}) => {
  if (Number(price) === 0) {
    return null;
  }

  const valueParts = formatPrice(price) || [];

  const currency = getValueByType(valueParts, 'currency');
  const integer = getValueByType(valueParts, 'integer');
  const fraction = getValueByType(valueParts, 'fraction');

  const classNames = ['product-price'];
  if (className) {
    classNames.push(className);
  }

  return (
    <>
      <div className={classNames.join(' ')} style={style}>
        {integer == 0 ? (
          <>
            <span className={`product-price-main ${classNameMain}`}>{fraction}</span>
            <span className={classNameSecondary}>¢</span>
          </>
        ) : (
          <>
            <span className={classNameSecondary}>{currency}</span>
            <span className={`product-price-main ${classNameMain}`}>{integer}</span>
            <span className={classNameSecondary}>.{fraction}</span>
          </>
        )}
      </div>
      <style jsx>
        {`
          .wishlist-secondary-price {
            font-size: 13px;
            position: relative;
            top: 3px;
          }
          .pdp-price-secondary {
            font-size: 46px;
          }
          .pdp-price-main {
            font-size: 84px;
          }
        `}
      </style>
    </>
  );
};

export default React.memo(Price);
