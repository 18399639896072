import LinkNoPrefetch from '@components/LinkNoPrefetch';
import { Facebook, Instagram } from '@components/icons';

interface Props {
  width?: number;
  height?: number;
  fill?: string;
}

const SocialIcons = ({ width, height, fill }: Props) => {
  const socialData = {
    facebook: {
      link: 'https://www.facebook.com/rejectshop/',
    },
    instagram: {
      link: 'https://www.instagram.com/therejectshop/',
    },
  };
  const { facebook, instagram } = socialData;

  return (
    <div className="flex gap-4">
      {facebook?.link && (
        <LinkNoPrefetch
          href={facebook?.link || '/'}
          target="_blank"
          rel="noreferrer"
          className="hover:opacity-75"
        >
          <Facebook width={width} height={height} fill={fill} />
        </LinkNoPrefetch>
      )}

      {instagram?.link && (
        <LinkNoPrefetch
          href={instagram?.link || '/'}
          target="_blank"
          rel="noreferrer"
          className="hover:opacity-75"
        >
          <Instagram width={width} height={height} fill={fill} />
        </LinkNoPrefetch>
      )}
    </div>
  );
};
export default SocialIcons;
