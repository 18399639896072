const Minus = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="2"
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.723858 0.223858 0.5 0.5 0.5H15.5C15.7761 0.5 16 0.723858 16 1C16 1.27614 15.7761 1.5 15.5 1.5H0.5C0.223858 1.5 0 1.27614 0 1Z"
        fill="#464544"
      />
    </svg>
  );
};

export default Minus;
