import LinkNoPrefetch from '@components/LinkNoPrefetch';
import SocialIcons from '@components/ui/SocialIcons';
import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';
import { SubscribeContainer } from '../SubscribeForm';
import s from './Footer.module.css';
import AcknowledgementOfCountry from '@components/AcknowledgementOfCountry/AcknowledgementOfCountry';

interface Props {
  className?: string;
  footer?: any;
}

interface footerItems {
  items: footerItem[];
  primary: {
    heading: string;
  };
}

interface footerItem {
  link?: string;
  linkTitle?: string;
}

const Footer: FC<Props> = ({ className, footer }) => {
  const footerItemsSlices = footer?.data?.slices;
  const rootClassName = cn(s.root, className);

  return (
    <footer className={rootClassName}>
      <div className="bg-brand-dark-red text-body2 w-full">
        <div className={cn('wrapper-large', s.mobilePaddingLeft)}>
          <div className="grid grid-cols-2 py-10 lg:py-12 lg:grid-cols-6 lg:gap-3">
            <div className="hidden lg:block pt-8 pb-1 lg:pt-0">
              <div className="pb-4">
                <Image src="/icons/logo-footer.svg" width={192} height={20} alt="logo" />
              </div>
              <SocialIcons width={16} height={16} fill="#ffffff" />
            </div>
            {footerItemsSlices?.map((item: footerItems, k: number) => {
              return (
                <div key={k}>
                  <div className="text-body3 font-medium  uppercase text-white pt-8 pb-1 lg:pt-0 first: row-span-2 last:col-span-1">
                    {item.primary.heading}
                  </div>
                  {item?.items.map((navItem: any, v: number) => {
                    return (
                      <LinkNoPrefetch
                        key={v}
                        href={navItem?.link || '/'}
                        className="block text-body2 mt-4 text-white cursor-pointer hover:opacity-75"
                      >
                        {navItem.linkTitle}
                      </LinkNoPrefetch>
                    );
                  })}
                </div>
              );
            })}
            {/* welcome country section - mobile */}
            <AcknowledgementOfCountry variant="small" />

            {/* end welcome country section - mobile */}
            <SubscribeContainer/>
          </div>

          {/* welcome country section - desktop */}
          <AcknowledgementOfCountry variant="large" />

          {/* end welcome country section - desktop */}

          <div className="flex flex-col md:inline-flex md:w-full md:justify-between items-center mb-8 lg:pb-0 lg:flex-row">
            <div className="lg:order-1 order-3 flex items-center text-body2 text-white py-6">
              <LinkNoPrefetch
                href="/information/privacy-policy"
                className="block body-2 text-white cursor-pointer hover:opacity-75"
              >
                Privacy Policy
              </LinkNoPrefetch>
              <div className="mx-4">|</div>
              <LinkNoPrefetch
                href="/information/privacy-policy"
                className="block text-body2 text-white cursor-pointer hover:opacity-75"
              >
                Terms & Conditions
              </LinkNoPrefetch>
              <div className="mx-4">|</div>
              <LinkNoPrefetch
                href="/information/cookie-policy"
                className="block text-body2 text-white cursor-pointer hover:opacity-75"
              >
                Cookie Policy
              </LinkNoPrefetch>
            </div>
            <div className="order-4 text-body2 text-white lg:ml-auto lg:order-2">
              &#169; {` ${new Date().getFullYear()} The Reject Shop`}
            </div>
            <div className="pt-2 pb-6 lg:order-4 order-1 lg:hidden block">
              <SocialIcons width={16} height={16} fill="#464544" />
            </div>
            <div className="flex gap-2 pb-6 lg:pb-0 lg:order-3 order-2 lg:ml-auto">
              <Image src="/icons/mastercard-footer.svg" alt="master card" width={38} height={24} />
              <Image src="/icons/visa-footer.svg" alt="visa card" width={38} height={24} />
              <Image src="/icons/amex-footer.svg" alt="amex" width={38} height={24} />
              <Image src="/icons/afterpay-footer.svg" alt="afterpay" width={38} height={24} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
