import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import throttle from 'lodash.throttle';
import { Navigation } from '@components/Common';
import { groupNavItems } from '@lib/helpers/group-nav-items';
import Message from '@containers/Layout/Header/Message';
import MegaMenu from '../MegaMenu';
import NavbarRoot from './NavbarRoot';

interface LinkProps {
  href: string;
  label: string;
}

interface NavbarProps {
  links?: LinkProps[];
  navItems: any;
  announcement?: Partial<{ title: string; description: string }>;
}

const Navbar: FC<NavbarProps> = ({ navItems }) => {
  const { announcement_message: announcementMessage, announcement_link: announcementLink } =
    navItems?.data || {};
  const groupedNavItems: any[] = groupNavItems(navItems?.data?.slices);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [megaMenuIndex, setMegaMenuIndex] = useState(-1);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [iconsColour, setIconsColour] = useState('#ffffff');

  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0;
      const { scrollTop } = document.documentElement;
      const scrolled = scrollTop > offset;

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled);
      }
    }, 200);

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  const hideMegaMenu = () => {
    setShowMegaMenu(false);
    setMegaMenuIndex(-1);
  };

  const setMegaMenuVisibility = (value: boolean) => {
    setShowMegaMenu(value);
  };

  const setMegaMenuIndexHandler = (index: number) => {
    setMegaMenuIndex(index);
  };

  return (
    <>
      <NavbarRoot>
        <div className="navigation bg-brand-dark-red lg:hover:text-ui-dark-grey">
          <div className="relative wrapper-large">
            <div className="flex justify-between max-h-14 lg:max-h-12">
              <div className="navItem hidden lg:block">
                <Navigation
                  menuItems={groupedNavItems}
                  handleMegaMenuVisibility={setMegaMenuVisibility}
                  handleMegaMenuIndex={setMegaMenuIndexHandler}
                  currentVisibility={showMegaMenu}
                  currentIndex={megaMenuIndex}
                  linkColor={iconsColour}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn('absolute z-10 w-full mega-nav-transition', {
            'mega-nav-transition-show':
              showMegaMenu && groupedNavItems[megaMenuIndex]?.type === 'MegaNav',
          })}
        >
          <div className="bg-overlay-dark h-screen bg-opacity-40">
            <div onMouseLeave={hideMegaMenu}>
              <MegaMenu
                handleMegaMenuVisibility={setMegaMenuVisibility}
                menuItems={groupedNavItems[megaMenuIndex]?.items}
              />
            </div>
          </div>
        </div>
        <style jsx>
          {`
            .navigation {
              border-top: 1px solid rgba(255, 255, 255, 0.25);
            }
            .mega-nav-transition {
              visibility: hidden;
              transition: all 0.3s ease-in-out;
              opacity: 0;
            }
            .mega-nav-transition-show {
              visibility: visible;
              opacity: 100;
            }
            @media (min-width: 1024px) {
              .navItem {
                display: flex;
                justify-content: center;
              }
              .navItem:first-child > span {
                margin-right: auto;
              }
              .navItem:last-child > span {
                margin-left: auto;
              }
            }
          `}
        </style>
      </NavbarRoot>
      {announcementMessage && <Message message={announcementMessage} link={announcementLink} />}
    </>
  );
};

export default Navbar;
