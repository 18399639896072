import React, { FC, Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Button, useUI } from '@components/ui';
import { ArrowRightWhite, Check } from '@components/icons';

interface Props {
  variant?: 'dark' | 'light';
}

const SubscribeForm: FC<Props> = ({ variant }) => {
  const { locale } = useRouter();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { formFocused, formUnfocused } = useUI();

  const handleOnChange = (e: React.SyntheticEvent<EventTarget>) => {
    const value = (e.target as HTMLInputElement).value;
    setEmail(value);
  };

  const handleSubscription = async (e) => {
    setLoading(true);
    setError(false);
    setMessage('');

    if (
      email &&
      email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ) {
      try {
        // need to delete these 2 lines once it's implemented.....
        // alert('need to add api subscribe!!!');
        setLoading(false);
        e.preventDefault();
        window.open('https://my.rejectshop.com.au', '_blank');

        // const response = await fetch('/api/customer/subscribe', {
        //   method: 'POST',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ email, locale }),
        // })
        // const result = await response.json()
        // if (result?.acceptsMarketing) {
        //   setMessage('You have been successfully subscribed')
        //   setLoading(false)
        //   setSuccess(true)
        //   //newsletterSubscriptionComplete()
        // } else {
        //   setError(true)
        //   setMessage(
        //     'There was an issue subscribing you. Please try again later'
        //   )
        //   setLoading(false)
        // }
      } catch (err) {
        setError(true);
        setMessage('There was an issue subscribing you. Please try again later');
        setLoading(false);
      }
    } else {
      setError(true);
      setMessage('Please enter a valid email');
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-stretch">
        <input
          className={cn(
            'appearance-none bg-transparent   w-full focus:outline-none text-body2  mr-3',
            {
              'text-text-dark placeholder-[#272727] borderBottomDark': variant === 'dark',
            },
            { 'text-white placeholder-white borderBottom': variant !== 'dark' }
          )}
          type="email"
          placeholder="Your email address"
          value={email}
          onChange={handleOnChange}
        />
        {success ? (
          <div className="rounded px-2 py-2.5 flex justify-center items-center bg-accent-7">
            <Check fill="#fff" />
          </div>
        ) : (
          <Button
            className={cn('rounded-sm  h-10 w-10 ', {
              'bg-ui-darkest-grey hover:bg-ui-dark-grey': variant === 'dark',
              'bg-white hover:bg-brand-light-grey': variant !== 'dark',
            })}
            variant="small"
            onClick={handleSubscription}
            loading={loading}
            disabled={loading}
          >
            {variant === 'dark' ? (
              <ArrowRightWhite fill={loading ? '#ffffff' : '#ffffff'} />
            ) : (
              <ArrowRightWhite fill={loading ? '#272727' : '#464544'} />
            )}
          </Button>
        )}
      </div>
      {message && (
        <div
          className={cn('mt-4 body ', {
            'text-successGreen': success,
            'text-errorRed': error,
          })}
        >
          {message}
        </div>
      )}
      <style jsx>
        {`
          .borderBottom {
            border-bottom: 1px solid #c9c9c9;
          }
          .borderBottomDark {
            border-bottom: 1px solid #272727;
          }
        `}
      </style>
    </>
  );
};

export default SubscribeForm;
