export const parseShopifyProduct = (shopifyProduct) => {
  if (shopifyProduct) {
    return {
      sku: shopifyProduct.node.variants.edges[0].node.sku,
      handle: shopifyProduct.node.handle,
      title: shopifyProduct.node.title,
      compareAtPrice: shopifyProduct.node.variants.edges[0].node.compareAtPriceV2?.amount,
      price: shopifyProduct.node.variants.edges[0].node.priceV2?.amount,
      tags: shopifyProduct.node?.tags,
      imageUrl: shopifyProduct.node?.images.edges[0].node?.transformedSrc,
    };
  } else {
    return null;
  }
};
