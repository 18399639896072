import { useContext } from 'react';
import { StoreSidebarContextProvider } from './StoreSidebarProvider';

export const useStoreSidebar = () => {
  const context = useContext(StoreSidebarContextProvider);

  if (context === null) throw new Error('context is not inside its provider');

  return context;
};
