const DarkPin = (props) => (
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.58337 3.79163C4.86023 3.79163 3.45837 5.19348 3.45837 6.91663C3.45837 8.63977 4.86023 10.0416 6.58337 10.0416C8.30652 10.0416 9.70837 8.63977 9.70837 6.91663C9.70837 5.19348 8.30652 3.79163 6.58337 3.79163ZM6.58337 8.99996C5.43461 8.99996 4.50004 8.06539 4.50004 6.91663C4.50004 5.76786 5.43461 4.83329 6.58337 4.83329C7.73214 4.83329 8.66671 5.76786 8.66671 6.91663C8.66671 8.06539 7.73214 8.99996 6.58337 8.99996ZM6.58337 0.666626C3.13158 0.666626 0.333374 3.46484 0.333374 6.91663C0.333374 9.43658 1.2113 10.1403 5.94106 16.997C6.25141 17.4453 6.91531 17.4454 7.22572 16.997C11.9554 10.1403 12.8334 9.43658 12.8334 6.91663C12.8334 3.46484 10.0352 0.666626 6.58337 0.666626ZM6.58337 16.0941C2.04903 9.53668 1.37504 9.01604 1.37504 6.91663C1.37504 5.52541 1.9168 4.2175 2.90053 3.23378C3.88425 2.25006 5.19216 1.70829 6.58337 1.70829C7.97458 1.70829 9.28249 2.25006 10.2662 3.23378C11.2499 4.2175 11.7917 5.52541 11.7917 6.91663C11.7917 9.01591 11.1181 9.53609 6.58337 16.0941Z"
      fill="#464544"
    />
  </svg>
);

export default DarkPin;
