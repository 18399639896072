import React from 'react';
import SubscribeForm from './SubscribeForm';
import { useUserPortal } from '@lib/userportal';

function SubscribeContainer() {
  const [{ user, signupAlready }] = useUserPortal();
  if ((user && user.id) || signupAlready) {
    return null;
  }

  return (
    <div className="lg:col-start-5 lg:col-span-2 row-start-1 col-span-2 text-white">
      <div className="grid grid-cols-1">
        <div className="font-semibold text-24 leading-8">Subscribe to our newsletter</div>
        <div className="text-body1 mt-2 mb-4">
          Sign up to receive exclusive offers, VIP invites and news
        </div>
        <div className="pb-4">
          <SubscribeForm />
        </div>
      </div>
    </div>
  );
}

export default SubscribeContainer;
