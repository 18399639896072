import NextLink, { LinkProps as NextLinkProps } from 'next/link';

interface Props extends NextLinkProps {
  className?: string;
}

const Link: React.FC<Props> = ({ href, children, ...props }) => {
  return (
    <NextLink href={href} {...props}>
      <div>{children}</div>
    </NextLink>
  );
};

export default Link;
