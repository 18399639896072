const ArrowRightSmall = ({ ...props }) => {
  return (
    <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m5.796.774-.169.169a.286.286 0 0 0 0 .405l4.25 4.246H.928a.286.286 0 0 0-.286.286v.238c0 .157.129.285.286.285h8.947l-4.249 4.25a.286.286 0 0 0 0 .404l.17.169a.286.286 0 0 0 .404 0l5.025-5.023a.286.286 0 0 0 0-.404L6.2.776a.284.284 0 0 0-.405-.002Z"
        fill="#464544"
      />
    </svg>
  );
};

export default ArrowRightSmall;
