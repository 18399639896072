const Check = ({ fill = '464544', ...props }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.09619 12.8744L0.146447 7.92462C-0.0488157 7.72936 -0.0488154 7.41278 0.146447 7.21751C0.341709 7.02225 0.658291 7.02225 0.853553 7.21751L5.44975 11.8137L17.117 0.146447C17.3123 -0.0488155 17.6289 -0.0488155 17.8241 0.146446C18.0194 0.341708 18.0194 0.658291 17.8241 0.853553L5.8033 12.8744C5.60804 13.0696 5.29146 13.0696 5.09619 12.8744Z"
        fill={fill}
      />
    </svg>
  );
};

export default Check;
