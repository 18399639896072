const defaultArticleFilter = [
  {
    id: 'published_status',
    value: 'published',
  },
  {
    id: 'blog_id',
    value: null,
  },
  {
    id: 'author_name',
    value: null,
  },
];

export default defaultArticleFilter;
