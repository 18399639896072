import React from 'react';
import Support from '@components/Support';

const PageError = ({ message }) => {
  console.log('Error Page Message', message);
  return (
    <>
      <div className="pageError">
        <h2 className="pageError__number heading_style__hero">We&#x27;re sorry,</h2>
        <div className="pageError__warning paragraph_style__secondary_bold">
          It's looks like something went wrong while loading this page. We apologise for the
          inconvenience.
        </div>
        <br />
        <br />
        {/* <Support /> */}
      </div>
      <style jsx>
        {`
          .pageError {
            display: flex;
            padding: 120px 0;
            justify-content: space-around;
            text-align: center;
            flex-direction: column;
          }
          .pageError__number {
            padding: 56px 0 40px 0;
          }
          .pageError__warning {
            padding: 10px 0 5px 0;
          }
        `}
      </style>
    </>
  );
};

PageError.defaultProps = {
  message:
    'We&#x27;re sorry - Something went wrong while loading this page. We apologise for the inconvenience.',
};

export default PageError;
