import { repositoryName } from '@lib/prismicio';
import { PrismicPreview } from '@prismicio/next';
import { PrismicProvider } from '@prismicio/react';
import brandConfig from 'brandConfig';
import Layout from 'containers/Layout';
import Link from 'next/link';
import Storefront from 'providers/Storefront';

import 'common/styles/index.scss';

import 'common/styles/theme.light.css';

// import 'nprogress/nprogress.css';
import ErrorBoundary from '@components/ErrorBoundary';
import PageError from '@components/PageError';
import CustomHeads from 'components/CustomHeads';
import ZendeskCustomHead from 'components/ZendeskCustomHead';
import { GoogleMapsAPIProvider } from 'providers/GoogleMapsAPIProvider';
import EventProvider from 'providers/EventProvider';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

export function reportWebVitals(metric) {
  const url = process.env.NEXT_PUBLIC_AXIOM_INGEST_ENDPOINT;

  if (!url) {
    return;
  }

  const body = JSON.stringify({
    route: window.__NEXT_DATA__.page,
    ...metric,
  });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true });
  }
}

const App = (props) => {
  const { Component, pageProps } = props;
  const { preview, globalProps } = pageProps || {};
  const router = useRouter();

  useMemo(() => {
    const prefetch = router.prefetch;
    router.prefetch = async () => {};
    return () => {
      router.prefetch = prefetch;
    };
  }, [router]);

  return (
    <ErrorBoundary fallbackComponent={<PageError />}>
      <EventProvider>
        <Storefront config={brandConfig} globalProps={globalProps} preview={preview}>
          <GoogleMapsAPIProvider>
            <Layout globalProps={globalProps} preview={preview}>
              <ZendeskCustomHead />
              <CustomHeads />
              <PrismicProvider
                internalLinkComponent={({ href, ...props }) => (
                  <Link href={href}>
                    <div {...props} />
                  </Link>
                )}
              >
                <PrismicPreview repositoryName={repositoryName}>
                  <Component {...pageProps} />
                </PrismicPreview>
              </PrismicProvider>
            </Layout>
          </GoogleMapsAPIProvider>
        </Storefront>
      </EventProvider>
    </ErrorBoundary>
  );
};

export default App;
