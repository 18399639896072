import { SearchLight } from '@components/icons';
import cn from 'clsx';
import { EventContext } from 'providers/EventProvider';
import React, { useContext } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

interface State {
  address: string | undefined;
}

interface Props {
  onSelect?: (value: {
    address: string;
    latLng: {
      lat: number;
      lng: number;
    };
  }) => void;
  searchScopes: string[];
}

function StoreSidebarSearchInput({ onSelect, searchScopes = [] }: Props) {
  const [location, setLocation] = React.useState<State>({ address: '' });
  const { gtm } = useContext(EventContext);

  const handleChange = (address: any) => setLocation({ address });

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const { lat, lng } = {
        lat: results[0]?.geometry.location.lat(),
        lng: results[0]?.geometry.location.lng(),
      };
      setLocation({ address: results[0].formatted_address });
      gtm.tagUpdateAddress({ label: results[0].formattted_address });

      onSelect &&
        onSelect({
          latLng: { lat, lng },
          address: results[0].formatted_address,
        });
    } catch (error) {
      // catch error
      console.log('error', error);
    }
  };

  return (
    <PlacesAutocomplete
      value={location?.address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={console.log}
      searchOptions={{
        componentRestrictions: {
          country: searchScopes,
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div className="flex items-center bg-white border-solid rounded-sm py-2 px-4 border border-ui-dark-grey">
            <SearchLight />
            <input
              {...getInputProps({
                placeholder: 'Search for a postcode or suburb',
              })}
              className="ml-2 bg-white w-full focus:outline-none text-darkest-grey"
            />
            <div>{loading && <div>...</div>}</div>
          </div>
          <div
            className={cn({
              'flex flex-col bg-white border-l border-b border-r border-ui-dark-grey text-dark-grey border-solid':
                suggestions.length > 0,
            })}
          >
            {suggestions.map((suggestion, k) => {
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  className="px-4 py-2"
                  key={k}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default StoreSidebarSearchInput;
