/* eslint-disable react/button-has-type */
import { ArrowLeft, LegacyCross } from '@components/icons';
import { FC } from 'react';
import AppDeliveryOption from './AppDeliveryOption';
import AppLayout from './AppLayout';
import AppList from './AppList';

interface Props {
  onClose: () => void;
}

const StoreFinderSidebar: FC<Props> = ({ onClose }) => {
  return (
    <div className="flex flex-col w-full bg-white">
      <div className="p-4 w-full flex justify-between items-center sticky top-0 bg-white">
        <button
          aria-label="Close"
          className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none bg-transparent cursor-pointer"
          onClick={onClose}
        >
          <ArrowLeft className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
        </button>
        <span className="font-semibold text-13 leading-6 text-dark-grey">FIND YOUR STORE</span>
        <button
          aria-label="Close"
          className="hover:text-accent-5 transition ease-in-out duration-150 focus:outline-none bg-transparent cursor-pointer"
          onClick={onClose}
        >
          <LegacyCross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
        </button>
      </div>
      <AppLayout listComponent={AppList} deliveryOptionListComponent={AppDeliveryOption} />
    </div>
  );
};

export default StoreFinderSidebar;
