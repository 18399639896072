import { getApiBaseUrl } from '@utils/url';

export const getIpGeoLocation = () => {
  return new Promise((resolve) => {
    fetch(`${getApiBaseUrl()}/api/geo`)
      .then(async (res) => {
        const body = await res.json();
        resolve(body);
      })
      .catch((e) => {
        resolve(null);
      });
  });
};
export default getIpGeoLocation;
