import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagMouseOverMenu = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'menu_item',
    label: label?.toLowerCase(),
  });
};
export default tagMouseOverMenu;
