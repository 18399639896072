import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagUpdateAddress = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'store_option',
    label: label?.toLowerCase(),
  });
};
export default tagUpdateAddress;
