import css from 'styled-jsx/css';

const styles = css`
  .your-list-control-button {
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
  }
  .your-list-control-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
  }
  .your-list-control-wrapper:hover {
    opacity: 0.75;
  }
  .your-list-control-icon {
    position: relative;
  }
  .your-list-control-number {
    color: rgb(39, 39, 39);
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    height: 18px;
    width: 20px;
    left: 8px;
    top: -3px;
    border-radius: 50%;
    background: rgb(255, 237, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -1px;
  }
`;

export default styles;
