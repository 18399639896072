import { UserPortalPager } from '@lib/userportal';
import s from './ProfilePortal.module.css';

const ProfilePortalSidebar = () => {
  return (
    <div className={s.container}>
      <UserPortalPager />
    </div>
  );
};

export default ProfilePortalSidebar;
