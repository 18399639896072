import { useEffect, useState } from 'react';
import useTimeout from './useTimeout';
import { useRouter } from 'next/router';
import useLocalStorage from './useLocalStorage';
import { useUserPortal } from '@lib/userportal';
import useSyncAuthFromLink from './useSyncAuthFromLink';
import { EventType } from '@containers/Signup/type';
import { useUI } from '@components/ui';

const TIME_POPUP_SIGNUP = [5, 90];
const BLOG_POP_SIGNUP = [20, 90];
const BLOG_SLUG = ['blog', 'blogs'];

function isTouchDevice() {
  const globalWindow = window as any;
  const globalNavigator = navigator as any;

  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (globalWindow.DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof globalWindow.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || globalNavigator.msMaxTouchPoints)
    )
  );
}

export function useSignupPopup() {
  const { displayModal, displaySidebar, openModal, closeModal, setModalView } = useUI();
  const [{ signupCount, signupAlready, user }, { setSignupCount }] = useUserPortal();
  const [signupPopupTime, setSignupPopupTime] = useLocalStorage('signup_exit_intent_time', null, {
    withExpiry: false,
  });
  const [showSignup, setShowSignup] = useState(false);
  const [signSuccess, setSignSuccess] = useState(false);
  const [popupType, setPopupType] = useState<EventType>('');
  useSyncAuthFromLink();

  const { asPath } = useRouter();
  const slugPath = asPath.split('?')[0];
  const firstSlug = slugPath.substring(1).split('/')[0];

  const getSpacing = (spacingList) => {
    const maxCount = spacingList.length;
    let signTimeCount = signupCount > maxCount ? maxCount : signupCount;
    const signIdx = Math.min(signTimeCount, maxCount - 1);
    const spacing = spacingList[signIdx];
    return [spacing, maxCount];
  };
  const [timeSpacing, maxCount] = getSpacing(TIME_POPUP_SIGNUP);
  const [blogSpacing] = getSpacing(BLOG_POP_SIGNUP);

  const displaySignup = (eventName: string, spacing?: number) => {
    if (user && user.id) {
      return;
    }
    if (signupAlready) {
      return;
    }
    if (showSignup) {
      return;
    }
    if (slugPath.includes('/contact-us') && !signSuccess) {
      return;
    }

    if (eventName === 'Timed Pop up' || eventName === 'Blog') {
      if (signupCount >= maxCount) {
        return;
      }
      if (signupPopupTime) {
        const nowTime: number = Date.now();
        let signupPopupCloseTime = parseInt(signupPopupTime);
        const noShowPopup: boolean = nowTime <= signupPopupCloseTime + spacing * 1000;
        if (noShowPopup) {
          return;
        }
      }
    } else if (eventName === 'Scroll Intent') {
      if (signupCount >= 3) {
        return;
      }
    }
    if (displayModal) {
      return;
    }
    if (displaySidebar) {
      return;
    }

    setShowSignup(true);
    openModal(true);
    setModalView('SIGNUP_VIEW');
    setPopupType(eventName as EventType);
    if (eventName !== 'ContactUs') {
      document.body.style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    if (signSuccess) {
      displaySignup('ContactUs');
    }
  }, [displayModal, displaySidebar, signSuccess]);

  const closeSignup = () => {
    setShowSignup(false);
    setSignupPopupTime(Date.now());
    setSignupCount(signupCount + 1);
    closeModal();
    if (popupType !== 'ContactUs') {
      document.body.style.overflow = 'auto';
    }
  };

  useTimeout(
    () => {
      if (BLOG_SLUG.includes(firstSlug)) {
        return;
      }
      displaySignup('Timed Pop up', timeSpacing);
    },
    timeSpacing * 1000,
    [
      signupCount,
      displayModal,
      displaySidebar,
      signupAlready,
      showSignup,
      slugPath,
      user,
      timeSpacing,
    ]
  );

  useTimeout(
    () => {
      if (!BLOG_SLUG.includes(firstSlug)) {
        return;
      }
      displaySignup('Blog', blogSpacing);
    },
    blogSpacing * 1000,
    [
      signupCount,
      displayModal,
      displaySidebar,
      signupAlready,
      user,
      showSignup,
      slugPath,
      blogSpacing,
    ]
  );

  useEffect(() => {
    let myScrollGenerator = () => {
      let lastPosition,
        newPosition,
        timer,
        delta,
        delay = 50;
      function clear() {
        lastPosition = null;
        delta = 0;
      }

      clear();
      return () => {
        newPosition = window.scrollY;
        if (lastPosition != null) {
          delta = newPosition - lastPosition;
        }
        lastPosition = newPosition;
        clearTimeout(timer);
        timer = setTimeout(clear, delay);
        return delta;
      };
    };
    let myScrollCheck = myScrollGenerator();
    const scrollEventHandler = (event) => {
      if (!isTouchDevice()) {
        return;
      }
      const delta = myScrollCheck();
      if (delta < -100) {
        setTimeout(function () {
          displaySignup('Scroll Intent');
        }, 300);
      }
    };

    window.addEventListener('scroll', scrollEventHandler, false);
    return () => {
      window.removeEventListener('scroll', scrollEventHandler, false);
    };
  }, [signupCount, displayModal, displaySidebar, signupAlready, user, slugPath, showSignup]);

  return {
    showSignup,
    setShowSignup,
    signSuccess,
    setSignSuccess,
    closeSignup,
    popupType,
    signupCount,
    setPopupType,
  };
}

export default useSignupPopup;
