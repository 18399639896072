import React from 'react';
import PropTypes from 'prop-types';

const getHeightRatio = (ratio) => {
  let value = 1;
  try {
    const [w, h] = ratio.split(':');
    value = parseFloat(h) / parseFloat(w);
  } catch (e) {
    value = 1;
  }

  return `${value * 100}%`;
};

const Image = (props) => {
  const { className, style, ratio, src, children } = props;

  let classNames = ['product-image'];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} style={style}>
      <div
        className="product-image-source"
        style={{
          paddingTop: getHeightRatio(ratio),
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain',
        }}
      />
      {children}
    </div>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  ratio: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.element,
};

Image.defaultProps = {
  className: '',
  style: {},
  ratio: '1:1',
  src: null,
  children: null,
};

export default React.memo(Image);
