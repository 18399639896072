import React from 'react';
import Image from 'next/image';
import cn from 'classnames';
function SaveToListOrigin({ toggleWishlist, handle, actionText, isInWishlist }) {
  const listIcon = isInWishlist ? 'heart-grey' : 'heart-white';

  return (
    <div>
      <button
        type="button"
        className="save-to-list"
        onClick={toggleWishlist}
        data-tag="Save product to shopping list"
        data-handle={handle}
        title={actionText}
      >
        <div
          className={cn('save-to-list-image', {
            'saved-list-block': isInWishlist,
          })}
        >
          {isInWishlist && <span className="saved-list-block-title">Saved</span>}
          <Image width={32} height={32} src={`/icons/${listIcon}.svg`} alt={actionText} />
        </div>
      </button>
      <style jsx>
        {`
          .save-to-list {
            cursor: pointer;
            background: transparent;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 12px;
            padding-bottom: 12px;
          }
          .saved-list-block {
            background-color: rgb(250, 250, 250);
            color: rgb(39, 39, 39);
            border-radius: 16px;
            display: flex;
            align-items: center;
          }
          .saved-list-block-title {
            padding: 0 8px 0 16px;
            font-size: 13px;
            line-height: 24px;
            font-weight: 450;
          }
        `}
      </style>
    </div>
  );
}

export default SaveToListOrigin;
