import { triggerGTMEvent } from '../..';

const tagClickChangeStore = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'store',
    label: 'show store sidebar',
  });
};
export default tagClickChangeStore;
