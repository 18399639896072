export const setQueryStringParameter = (name, value, router) => {
  const params = new URLSearchParams(window.location.search);
  params.set(name, value);
  const newUrl = decodeURIComponent(`${window.location.pathname}?${params}`);
  router.replace(`${router.route}?${params}`, newUrl, { shallow: true });
};

export const getApiBaseUrl = () => {
  let baseUrl = process.env.NEXT_PUBLIC_SITE_URL;

  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return baseUrl;
};
