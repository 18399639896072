/* eslint-disable no-undef */
import { MinusV2, PlusIcon } from '@components/icons';
import clsx from 'clsx';
import { PropsWithChildren, cloneElement, useRef, useState } from 'react';

function Accordion({ label, children }: PropsWithChildren<{ label: string }>) {
  const [open, setOpen] = useState();
  const contentRef = useRef<HTMLDivElement>(null);

  // @ts-ignore
  const toggle = () => setOpen((prev) => !prev);

  return (
    <div className="flex flex-col w-full">
      <button
        type="button"
        className="transition-all ease-in appearance-none"
        onClick={toggle}
        data-collapse-target={label}
      >
        <div className="flex justify-between">
          {children?.[0]}
          {children?.[1] && <div>{open ? <MinusV2 /> : <PlusIcon />}</div>}
        </div>
      </button>
      {children?.[1] && (
        <div
          data-collapse={label}
          className={clsx(
            'flex flex-row transition-all duration-300 ease-in-out overflow-hidden mt-4',
            {
              'h-0': !open,
              'h-auto': open,
            }
          )}
        >
          {cloneElement(children?.[1], {
            ref: contentRef,
          })}
        </div>
      )}
    </div>
  );
}

export default Accordion;
