const Rectangle = ({ ...props }) => {
  return (
    <svg
      width="1"
      height="40"
      viewBox="0 0 1 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="1" height="40" fill="#C9C9C9" />
    </svg>
  );
};

export default Rectangle;
