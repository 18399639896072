const WhiteCheck = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4084 1.05382L3.8587 7.55095C3.68795 7.72032 3.41222 7.71923 3.24285 7.54848L0.126312 4.40676C-0.043055 4.23601 -0.0419662 3.96028 0.12878 3.79091L0.437973 3.48422C0.608719 3.31485 0.884453 3.31594 1.05382 3.48669L3.55698 6.01006L9.48831 0.126312C9.65905 -0.0430547 9.93479 -0.0419666 10.1042 0.128779L10.4108 0.437973C10.5802 0.608719 10.5791 0.884453 10.4084 1.05382Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteCheck;
