const articlesByQuery = `
  query articlesByQuery(
    $query: String!, 
    $first: Int!, 
    $after: String, 
    $sortKey: ArticleSortKeys, 
    $reverse: Boolean
  ) {
    articles(
      first: $first, 
      query: $query, 
      after: $after, 
      sortKey: $sortKey, 
      reverse: $reverse
    ) {
      edges {
        node {
          id
          title
          contentHtml
          publishedAt
          handle
          authorV2 {
            name
          }
          blog {
            title
            handle
          }
          image {
            altText
            originalSrc
            transformedSrc
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

export default articlesByQuery;
