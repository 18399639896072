import memoize from 'memoizee';
import pluck from './pluck';

const pluckBy = (data, handle, value, path) => {
  try {
    let workingData = path ? pluck(data, path) : data;
    if (typeof workingData !== 'object') {
      throw new Error('Data is not an object, consider using "pluck" instead');
    }
    if (!Array.isArray(workingData)) {
      for (let key of Object.keys(workingData)) {
        const item = workingData[key];
        if (typeof item !== 'object') {
          continue;
        }
        if (item[handle] == value) {
          return item;
        }
      }
      throw new Error('No matching value found');
    }
    for (let item of workingData) {
      if (typeof item !== 'object') {
        continue;
      }
      if (item[handle] == value) {
        return item;
      }
    }
    throw new Error('No matching value found');
  } catch (e) {
    return null;
  }
};

export default memoize(pluckBy);
