import { Context, Language } from '@lib/store-context/Context';
import { getContext } from '@lib/store-context/get-context';
import { ShopifyClientContext } from './client';

export function getShopifyClientSettings(countryCode: string): ShopifyClientContext {
  const context: Context = getContext(countryCode as Language);
  return {
    shopifyDomain: context.shopifyDomain,
    storefrontAccessToken: context.shopifyStorefrontToken,
    languageCode: context.language,
    countryCode: context.countryCode,
  };
}
