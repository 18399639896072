import { StoreFinderSidebar } from '@lib/store-finder-sidebar';
import { FC } from 'react';

interface Props {
  onClose: () => void;
  stores?: any[];
}

const StoreFinderSidebarFunctionView: FC<Props> = ({ onClose }) => {
  return <StoreFinderSidebar onClose={onClose} />;
};

const StoreFinderSidebarView: FC<Props> = ({ onClose }) => {
  return <StoreFinderSidebarFunctionView onClose={onClose} />;
};

StoreFinderSidebarView.displayName = 'StoreFinderSidebarView';

export default StoreFinderSidebarView;
