import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickFilterOptions = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'search',
    category: 'filter_option',
    label: label?.toLowerCase(),
  });
};
export default tagClickFilterOptions;
