import { domToReact } from 'html-react-parser';
import SSRLink from './SSRLink';

export const toTitleCase = (str) =>
  str &&
  str.replace(
    /([^\W[_]]+[^\s-]*) */g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const toSnakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

export const getLinkType = (link) => {
  if (!link) return 'External';
  if (link.indexOf('/c/l/') !== -1) return 'Collection Landing Page';
  if (link.indexOf('/c/') !== -1) return 'Collection';
  if (link.indexOf('/blog') !== -1) return 'Blog';
  if (link.indexOf('/about/') !== -1) return 'About';
  if (link.indexOf('/page/') !== -1) return 'Page';

  return 'External';
};

export const toTitleCaseHypenDelimited = (str) =>
  str &&
  str
    .split('-')[0]
    .replace(/_/g, ' ')
    .replace(
      /([^\W_]+[^\s-]*) */g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

export const toTitleCaseRemoveHypenAndUnderscore = (str) =>
  str &&
  str
    .replace(/[_-]/g, ' ')
    .replace(
      /([^\W_]+[^\s-]*) */g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

export const htmlParseOptions = {
  replace: (domNode) => {
    if (domNode.name && domNode.name === 'a') {
      return (
        <SSRLink
          linkUrl={domNode.attribs.href}
          linkType="Collection"
          styles={{ textDecoration: 'underline', display: 'inline-block' }}
        >
          {domToReact(domNode.children, htmlParseOptions)}
        </SSRLink>
      );
    }
    return domNode;
  },
};

export function getStringType(input) {
  // Remove any hyphens and check if the remaining string is alphabetic
  const isSlug = input.replace(/-/g, '').match(/^[A-Za-z]+$/) !== null;
  // Check if the input is a number
  const isNumber = !isNaN(Number(input));
  if (isSlug) {
    return 'slug';
  } else if (isNumber) {
    return 'number';
  } else {
    return 'unknown';
  }
}

export const toSearchString = (slug) => {
  return slug
    .split('-')
    .map((word) => `%${word}%`)
    .join(' ');
};

export const toSlug = (input) => {
  return input
    .toLowerCase()
    .replace(/[\(\)]/g, '')
    .replace(/[^a-z0-9\s-]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
};
