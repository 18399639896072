export default {
  currencies: [
    {
      currencyCode: 'AUD',
      currencySymbol: '$',
      currencyIconUrl: '/icons/dollar.svg',
      contexts: ['AU'],
    },
  ],
  contexts: [
    {
      country: 'Australia',
      code: 'AU',
      title: 'AU',
      routePrefix: '/',
      language: 'en-AU',
      storefrontUrl: 'https://rejectshop.com.au',
      shopifyHandle: 'therejectshop',
      shopifyStorefrontToken: '49911a9ce0c164fb103d497efe652eac',
      storefrontGqlUrl: 'https://checkout.rejectshop.com.au/api/graphql',
      defaultCurrencyCode: 'AUD',
      default: true,
    },
  ],
  brand: {
    sitePassword: 'arkade2020',
    // prismicApiEndpoint: 'https://therejectshop.cdn.prismic.io/api/v2',
    // prismicAccessToken:
    //   'MC5YdFhvREJJQUFDSUFMOFo2.fe-_ve-_vW1c77-977-9Tu-_vUjvv70E77-9Te-_vWvvv73vv73vv712J1vvv73vv73vv70j77-9e2Xvv73vv712',
    prismicApiEndpoint: 'https://trs-storefront.cdn.prismic.io/api/v2',
    prismicAccessToken:
      'MC5ZejBSZnhFQUFDRUFnTjg5.Mu-_ve-_vUzvv70pKxsL77-9XO-_vUdvdlcTGu-_vVIwee-_ve-_vUlt77-977-9M--_vWEq',
    algoliaApplicationID: 'AMPAW8YCCP',
    algoliaApiToken: '1f7344dde030cba3e03b7c2fccfe1b30',
    algoliaAnalyticsApiKey: '3ac43f4060a6caf433ba259aba9fb8ea',
    algoliaProductsIndex: 'products',
    algoliaArticlesIndex: 'articles',
    algoliaPagesIndex: 'pages',
    defaultProductIndexSortingSuffix: '_published_at_desc',
    hitsPerPage: 36,
    hitsLimit: 9999,
    regenerateTimeout: 3600,
    googleApiKey: 'AIzaSyBJueo_0M63sCgif_bTUV7ueAQOIKISQ60',
  },
  domains: {
    production: ['rejectshop.com.au'],
    staging: ['rejectshop.now.sh'],
  },
  zendeskKey: '733c7a50-1509-4fa8-8caf-578c0f48dc3d',
};
