/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { APIGetCurrentUserResponse } from './services/api';

export const selectBarcode = (result: APIGetCurrentUserResponse) =>
  // result?.identities?.find((i) => i.handle === 'card')?.identifier;
  result?.identities?.find((i) => i?.identifier)?.identifier;

export const selectStore = (result: APIGetCurrentUserResponse) => ({
  id: result.preferred_location_id,
  name: result.preferred_location.name,
});

export const selectUserInterests = (result: APIGetCurrentUserResponse) =>
  result.custom_attributes.comms?.user_interests;

export const selectAllInterests = (result: APIGetCurrentUserResponse) =>
  result.custom_attributes.comms?.interests;
