/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { CheckIcon } from '@components/icons';
import clsx from 'clsx';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import omit from 'lodash/omit';
import * as yup from 'yup';
import Button from '../components/Button';
import { Header } from '../components/Header';
import HeroSection from '../components/HeroSection';
import { StoreSearchInput } from '../components/StoreSearchInput';
import { useUserPortal } from '../context';
import { useYupValidationResolver } from '@lib/hooks/useYupValidationResolver';
import API from '../services/api';
import { User } from '../types';
import dataLayer from '../utils/dataLayer';
import { EventContext } from 'providers/EventProvider';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';

const validateEmail = (value: string) => value.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi);

const validationSchema = yup.object<User>({
  email: yup.string().email().required('Please provide your email address'),
  name: yup.string().required('Please provide your first name'),
  store: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required('Please tell us your preferred store'),
});

type UserFieldKeys = keyof User;

export default function Join() {
  const resolver = useYupValidationResolver(validationSchema);
  const { emit } = useContext(LocalisationConnectorContext);
  const [updating, setUpdating] = useState(false);
  const [storeInfo, setStoreInfo] = useState(null);

  const [
    { user, isAuthenticated, pageHeaders },
    { setPage, setUser, setAuthenticatedUser, setStoreSearchResult, setUserStore },
  ] = useUserPortal();
  const { gtm } = useContext(EventContext);
  const [, setStore] = useStoreFinderStorage();
  const [creating, setCreatingUser] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm<User>({
    resolver,
  });

  const handleBack = useCallback(() => {
    if (isAuthenticated) return;
    setPage('GET_STARTED');
  }, [isAuthenticated]);

  const updateStorageAndView = () =>{
    if(!storeInfo) {
      return;
    }
    const store = storeInfo;
    setStore(store)
    if (emit) {
      emit('store_finder_page_set_store', store);
    }
  }

  const handleSave = useCallback(
    (data: User) => {
      try {
        setUpdating(true);
        API.updateProfile(user.id, {
          ...omit(data, ['store']),
          preferred_location_id: data.store?.id,
        }).then(() => {
          updateStorageAndView();
          setUser(data);
          setPage('ADDITIONAL_INFO');
        });
      } catch (error) {
        console.log('error', error);
        // catch error
        setUpdating(false);
      }
    },
    [user]
  );

  const handleLaterNext = () => setPage('ADDITIONAL_INFO');

  const handleChange = (field: UserFieldKeys) => (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return;

    if (field !== 'email') {
      setValue(field, event.target.value);
      setUser(getValues());
    }

    // If field email is updating
    if (!validateEmail(event.target.value)) {
      return setError('email', {
        message: 'Please check your email address is correct',
      });
    }

    API.getUserAvailability(event.target.value)
      .then((response) => {
        if (!response.email.available) {
          setError('email', {
            message: `${event.target.value} is taken`,
          });
        }
        return true;
      })
      .then(() => {
        setValue(field, event.target.value);
        setUser(getValues());
      });
  };

  const handleJoin = (data: User) => {
    validationSchema
      .isValid(data)
      .then(async () => {
        try {
          setCreatingUser(true);
          const response = await API.createUser({
            email: data.email,
            name: data.name,
            preferred_location_id: data.store.id,
          });
          updateStorageAndView();
          setAuthenticatedUser(response);
          setPage('ADDITIONAL_INFO');
        } catch (error) {
          console.error('error', error);
        } finally {
          setCreatingUser(false);
        }
      })
      .then(() => {
        if (dataLayer) {
          gtm.tagClickSubscribeFlow({ label: 'Create_Account' });
        }
      });
  };

  const handleSelectStore = (store: any) => {
    setValue('store', store);
    setStoreInfo(store)
    setStoreSearchResult(null, []);
    setUserStore({
      id: store.id,
      name: store?.name || store?.title,
    });
  };

  useEffect(() => {
    reset(user);
  }, [user]);

  return (
    <div className="flex flex-col">
      <Header hideBack={isAuthenticated} onBack={handleBack} />
      <div className="flex flex-col gap-8 px-5 py-10">
        {isAuthenticated ? (
          <HeroSection title="YOUR PROFILE" subtitle={pageHeaders?.join_page_subtitle} />
        ) : (
          <HeroSection title="YOU’RE ONE STEP AWAY" subtitle={pageHeaders?.join_page_subtitle} />
        )}
        <section className="flex flex-col gap-3">
          <div className="flex flex-col relative">
            <input
              type="text"
              className={clsx(
                'appearance-none leading-tight p-4 w-full focus:outline-none focus:shadow-outline border border-solid rounded',
                {
                  'border-gray-300': !errors?.email,
                  'border-brand-red': !!errors?.email,
                }
              )}
              placeholder="Your email address"
              {...register('email', {
                disabled: creating || updating,
                onBlur: handleChange('email'),
              })}
            />
            {errors?.email && (
              <p className="text-brand-red text-xs italic">{errors?.email.message}</p>
            )}
            {!errors?.email && (
              <div
                className="absolute"
                style={{
                  right: '14px',
                  top: '17px',
                }}
              >
                <CheckIcon />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <input
              type="text"
              className={clsx(
                'appearance-none leading-tight focus:outline-none focus:shadow-outline border border-solid p-4 w-full h-12 rounded-sm',
                {
                  'border-gray-300': !errors?.name,
                  'border-brand-red': !!errors?.name,
                }
              )}
              placeholder="First Name"
              {...register('name', {
                disabled: creating || updating,
                onBlur: handleChange('name'),
              })}
            />
            {errors?.name && (
              <p className="text-brand-red text-xs italic">{errors?.name.message}</p>
            )}
          </div>
          <div className="mt-4 flex flex-col">
            <p className="my-2 text-13">YOUR LOCAL STORE</p>
            <div className="flex flex-col relative">
              <StoreSearchInput onSelectStore={handleSelectStore} />
              {errors?.store && (
                <p className="text-brand-red text-xs italic">{errors?.store.message}</p>
              )}
            </div>
          </div>
        </section>
        <footer className="flex flex-col">
          <label className="pb-8">
            <p className="text-body2 font-normal text-text-subdued flex flex-1 flex-col">
              By entering your email address you agree to receive marketing and communications from
              The Reject shop
              <a target="_blank" href="/information/privacy-policy" className="underline">
                View terms
              </a>
            </p>
          </label>
          {isAuthenticated ? (
            <div className="flex flex-col gap-y-4">
              <Button disabled={updating} onClick={handleSubmit(handleSave)}>
                {updating ? 'Saving...please wait' : 'Save'}
              </Button>
              <Button variant="outline" onClick={handleLaterNext}>
                I’ll do this later
              </Button>
            </div>
          ) : (
            <Button disabled={creating} onClick={handleSubmit(handleJoin)}>
              {creating ? 'Creating...please wait' : 'Join now'}
            </Button>
          )}
        </footer>
      </div>
    </div>
  );
}
