import React, { useContext } from 'react';
import SSRLink from 'utils/SSRLink';
import Price from 'components/Product/Price';
import { breakpointSmall } from 'common/styles/breakpoints';
import { EventContext } from 'providers/EventProvider';

const ListItem = (props) => {
  const {
    item: { handle, title, image, price },
    removeAction,
    item,
  } = props;

  const { gtm } = useContext(EventContext);

  const removeItemFromList = (handle, item) => {
    removeAction(handle);
    gtm.tagRemoveFromWishList({ label: `${item.sku}: ${item.title}` });
  };

  return (
    <>
      <article className="your-list-item">
        {image && (
          <div className="your-list-item-column">
            <SSRLink linkType="product" linkUrl={`/p/${handle}`}>
              <div className="your-list-item-image">
                <img src={image} alt={`${title} product image thumb`} />
              </div>
            </SSRLink>
          </div>
        )}

        <div className="your-list-item-column">
          <div className="your-list-item-row">
            <SSRLink linkType="product" linkUrl={`/p/${handle}`}>
              <div className="your-list-item-title">{title}</div>
            </SSRLink>
          </div>
          <div className="your-list-item-row">
            <div className="your-list-item-price">
              <Price price={price} classNameSecondary="wishlist-secondary-price" />
            </div>
          </div>
        </div>

        <div className="your-list-item-column">
          {/* <a onClick={() => removeAction(handle)} className="your-list-item-remove">
            Remove
          </a> */}
          <a onClick={() => removeItemFromList(handle, item)} className="your-list-item-remove">
            Remove
          </a>
        </div>
      </article>
      <style jsx>
        {`
          .your-list-item {
            padding: 15px 0;
            display: flex;
            align-content: center;
            border-bottom: 1px solid #e6e6e6;
            font-size: 0.9em;
          }
          @media (max-width: ${breakpointSmall}) {
            .your-list-item {
              display: flex;
              flex: 1 0 100%;
            }
          }
          .your-list-item-column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-sizing: border-box;
            justify-content: flex-start;
          }
          .your-list-item-column:first-child {
            flex: 4;
            align-items: flex-start;
          }
          .your-list-item-column:last-child {
            flex: 1;
            align-items: flex-end;
            padding: 10px 0 0;
          }
          .your-list-item-column:nth-child(2) {
            flex: 8;
            padding: 10px 35px 0 10px;
          }
          .your-list-item-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex: 1;
            margin: 0 0 5px;
          }
          .your-list-item-title {
            font-weight: 100;
            font-size: 1.1em;
          }
          .icon {
            width: 15px;
          }
          .your-list-item-sku {
            font-size: 0.8em;
          }

          .your-list-item-price {
            font-size: 1.5em;
          }
          .product-price-main {
            line-height: initial;
          }
          .your-list-item-remove {
            text-decoration: underline;
            cursor: pointer;
          }
          .your-list-item-image img {
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default ListItem;
