const compareOfferEndsDate = (offerEnds) => {
  // convert offer ends ("DD/MM/YY -> YYYY-MM-DD") to compare with today to hide when the offer ended
  const offerEndsArray = offerEnds?.split('/');
  const offerEndsConverted = offerEnds
    ? `20${offerEndsArray[2]}-${offerEndsArray[1]}-${offerEndsArray[0]}`
    : null;
  const offerEndsDate = offerEndsConverted ? new Date(offerEndsConverted) : null;
  offerEndsDate?.setDate(offerEndsDate.getDate() + 1);

  const today = new Date();

  return offerEndsDate > today;
};

export default compareOfferEndsDate;
