import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagToggleWishList = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'wishlist_toggle',
    label: label?.toLowerCase(),
  });
};

export default tagToggleWishList;
