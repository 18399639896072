import { breakpointSmall } from 'common/styles/breakpoints';
import { useWishlist } from 'providers/Wishlist';
import ListItem from './listItem';
import SaveList from './saveList';

const YourList = () => {
  const { wishlistCount, wishlist, clearWishlist, removeFromWishlist, listUIActive, toggleListUI } =
    useWishlist();

  return (
    <div className="your-list">
      {listUIActive && (
        <>
          <div className="your-list-close" onClick={toggleListUI} />
          <div className="your-list-list">
            <header>
              <h3>My List</h3>
            </header>
            {!!wishlistCount && <SaveList />}
            {!!wishlistCount && (
              <section>
                {wishlist.map((item) => (
                  <ListItem
                    key={`list-item_${item.handle}`}
                    item={item}
                    removeAction={removeFromWishlist}
                  />
                ))}
              </section>
            )}
            {!wishlistCount && (
              <section>
                <p>Your list is empty.</p>
                <p>Save items by pressing "heart icon" on any product or collection page.</p>
              </section>
            )}
            
            {!!wishlistCount && (
              <section className="your-list-list-controls">
                <a onClick={clearWishlist} className="your-list-list-clear-list">
                  Clear list
                </a>
              </section>
            )}
          </div>
          <style jsx>
            {`
              @media (max-width: ${breakpointSmall}) {
                .your-list {
                  flex: 1 0 100%;
                }
                .your-list-title {
                  display: none;
                }
                .your-list-list {
                  z-index: 50;
                  display: flex;
                  flex: 1 0 100%;
                  flex-direction: column;
                  flex-wrap: wrap;
                }
              }

              .your-list-close {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
              }

              .your-list-item {
                padding: 15px 0;
                display: flex;
                align-content: center;
                border-bottom: 1px solid #e6e6e6;
                font-size: 0.9em;
              }

              .your-list-list header {
                margin: 15px 0 25px;
              }
              .your-list-list h3 {
                text-align: center;
              }
              .your-list section {
                margin: 10px 0;
              }
              .your-list-button {
                background-color: transparent;
                flex-direction: row;
                display: flex;
                align-items: center;
              }
              .your-list-title {
                color: white;
                margin-right: 18px;
                font-size: 16px;
              }
              .your-list-wrapper {
                position: relative;
              }

              .your-list-number {
                color: red;
                position: absolute;
                top: 10px;
                left: 0;
                right: 0;
                font-weight: 700;
                font-size: 16px;
              }

              .your-list-list {
                position: relative;
                padding: 15px 20px 15px 15px;
                background-color: white;
                border-radius: 4px;
                color: black;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                z-index: 2;
              }
              @media (min-width: ${breakpointSmall}) {
                .your-list-list {
                  position: absolute;
                  top: 65px;
                  right: 30px;
                  max-width: 400px;
                  max-height: 700px;
                  overflow-y: auto;
                }
              }
              .your-list-list-controls {
                display: flex;
                justify-content: center;
                margin: 10px 0;
              }

              .your-list-list-clear-list {
                text-decoration: underline;
                font-weight: 300;
                cursor: pointer;
              }
            `}
          </style>
        </>
      )}
    </div>
  );
};

export default YourList;
