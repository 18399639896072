/* eslint-disable no-underscore-dangle */
import { LoadingDots } from '@components/ui';
import { useStoreSidebar } from '@lib/store-finder-sidebar';
import groupBy from 'lodash/groupBy';
import { FC } from 'react';
import { DoorDashItem } from './components/DeliveryOptStoreCard';
import StoreCard from './components/StoreCard';
interface Props {
  onClose?: () => void;
}

const AppDeliveryOption: FC<Props> = () => {
  const { stores, isFetching, searchLocation } = useStoreSidebar();

  const deliverableStore = stores?.filter((s) => s?.tags.some((x) => x.search('enabled')));

  const doordashGroup = groupBy(deliverableStore, (store) =>
    store?.tags.filter((tag) => tag.includes('doordash-enabled'))
  );

  const allDeliveryOptions = [
    doordashGroup,
    // uberEats
  ];

  if (!searchLocation) return <div />;

  return (
    <div className="flex flex-col mt-4">
      {isFetching ? (
        <LoadingDots />
      ) : (
        allDeliveryOptions.map((option, k) =>
          Object.keys(option).map((key, i) => (
            <div key={k}>
              {key === 'doordash-enabled' && (
                <div key={i} className="flex flex-col">
                  <DoorDashItem />
                  <p className="mt-2 mb-3">
                    Select one of these stores in the DoorDash app for orders to your address
                  </p>
                  <div className="flex flex-col gap-2">
                    {option['doordash-enabled'].map((store, y) => (
                      <div key={y}>
                        <StoreCard {...store} selectable={false} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="h-1 w-full border-b border-solid border-gray-100 my-6" />
            </div>
          ))
        )
      )}
    </div>
  );
};

export default AppDeliveryOption;
