import brandConfig from 'brandConfig';
import algoliasearch from 'algoliasearch';
import qs from 'qs';

export const searchClient = algoliasearch(
  brandConfig.brand.algoliaApplicationID,
  brandConfig.brand.algoliaApiToken
);
export const createURL = (state) => `?${qs.stringify(state)}`;
export const pathToSearchState = (path) =>
  path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {};
