import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickImageWithLink = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'product_image_link',
    label: `Image ${label.toLowerCase()}`,
  });
};

export default tagClickImageWithLink;
