import { gql } from 'graphql-request';

export const SEARCH_PRODUCT_FIELDS = gql`
  fragment SearchProductFields on Product {
    id
    title
    handle
    tags
    images(first: 25) {
      edges {
        node {
          altText
          height
          transformedSrc
        }
      }
    }
    variants(first: 15) {
      edges {
        node {
          id
          sku
          title
          availableForSale
          quantityAvailable
          image {
            transformedSrc
          }
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`;
