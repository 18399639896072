import { createClient } from 'urql';
import { pluckBy } from 'utils';

const getDefaultShopContext = (config, code) => {
  let context;
  if (code) {
    context = pluckBy(config.contexts, 'code', code);
    if (context) {
      return context;
    }
  }

  return pluckBy(config.contexts, 'default', true);
};

const getCurrency = (config, context) =>
  pluckBy(config.currencies, 'currencyCode', context.defaultCurrencyCode);
const getClient = (ctx) => {
  const { shopifyStorefrontUrl, shopifyStorefrontToken } = ctx;
  return createClient({
    url: `${shopifyStorefrontUrl}/api/graphql`,
    fetchOptions: () => {
      return {
        headers: {
          'X-Shopify-Storefront-Access-Token': shopifyStorefrontToken,
        },
      };
    },
  });
};

export const getConfiguration = (config, code) => {
  const context = getDefaultShopContext(config, code);
  const currency = getCurrency(config, context);
  const client = getClient(context);

  return [
    {
      config,
      primaryContext: context,
      primaryCurrency: currency,
    },
    client,
  ];
};
