import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { toTitleCaseRemoveHypenAndUnderscore } from 'utils/string';

const ProductAvailability = ({ tag }) => {
  const availabilityTag = typeof tag === 'object' ? tag[0] : tag;
  const tagMessage = toTitleCaseRemoveHypenAndUnderscore(availabilityTag);

  let availabilityImg;
  if (availabilityTag.toLowerCase() === 'active') return null;

  switch (availabilityTag.toLowerCase()) {
    case 'in-stock':
      availabilityImg = 'available-icon-green';
      break;
    case 'low-stock':
      availabilityImg = 'available-icon-yellow';
      break;
    default:
      availabilityImg = 'available-icon-red';
  }

  const inStoreText = availabilityTag.toLowerCase() === 'discontinued' ? '' : ' - In Stores Only';
  const tagText = tagMessage + inStoreText;

  return (
    <div className="product-availability-level-container">
      <Image width={8} height={8} alt="available-icon" src={`/icons/${availabilityImg}.svg`} />
      <span className="product-availability-level">{tagText}</span>
    </div>
  );
};

ProductAvailability.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ProductAvailability.defaultProps = {
  tag: null,
};

export default ProductAvailability;
