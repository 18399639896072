/* eslint-disable no-undef */
import { MegaNavLink } from '@components/Common/types';
import LinkNoPrefetch from '@components/LinkNoPrefetch';
import { ChevronDown, ChevronUp } from '@components/icons';
import { useUI } from '@components/ui/context';
import cn from 'classnames';
import Image from 'next/image';
import { EventContext } from 'providers/EventProvider';
import { FC, useContext, useState } from 'react';

interface props {
  title: string;
  link: string;
  links?: MegaNavLink[];
  handleCloseMegaMenu: Function;
}

const NavColumnList: FC<props> = ({ title, link, links, handleCloseMegaMenu }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { closeSidebar } = useUI();
  const { gtm } = useContext(EventContext);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeMenu = (label: string) => {
    gtm.tagClickMenuItem({ label });
    handleCloseMegaMenu();
    closeSidebar();
  };

  return (
    <>
      <div className="hidden lg:block text-body2 font-medium uppercase text-darkest-grey mb-5">
        {link ? (
          <div onClick={() => closeMenu(title)}>
            <LinkNoPrefetch href={link}>{title}</LinkNoPrefetch>
          </div>
        ) : (
          title
        )}
      </div>
      <button
        type="button"
        className="lg:hidden text-body2 pb-2 font-normal bg-white text-dark-grey w-full flex justify-between focus:outline-none"
        onClick={toggleDrawer}
      >
        <div>{title}</div>
        <div className="lg:hidden mt-2">{isDrawerOpen ? <ChevronUp /> : <ChevronDown />}</div>
      </button>
      <ul className={cn('space-y-3', { 'hidden lg:block': !isDrawerOpen })}>
        {links?.map((subMenuLink) => {
          return (
            subMenuLink.show_in_nav && (
              <li
                key={subMenuLink.label}
                className="text-body2 text-dark-grey pl-6 lg:pl-0 hover:opacity-75"
                onClick={() => closeMenu(subMenuLink.label)}
              >
                <LinkNoPrefetch href={subMenuLink.link}>{subMenuLink.label}</LinkNoPrefetch>
              </li>
            )
          );
        })}
        {links.length && link && (
          <li
            className="flex items-center text-body2 font-medium text-dark-grey pl-6 pt-4 lg:pl-0 hover:opacity-75"
            onClick={() => closeMenu(`view all: ${title}`)}
          >
            <LinkNoPrefetch href={link} className="flex items-center">
              <span className="pr-3">View all</span>
              <Image
                src="/icons/arrow-right-grey.svg"
                alt="shop all"
                height={13.33}
                width={13.27}
              />
            </LinkNoPrefetch>
          </li>
        )}
      </ul>
    </>
  );
};

export default NavColumnList;
