import { useEffect, useRef } from 'react'

export function useTimeout(callback: () => void, delay: number | null, dependencies: any[]) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  let timerId = null;
  const reset = () =>{
    if(timerId) {
      clearTimeout(timerId)
      timerId = null;
    }
  }

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified. Note: 0 is a valid value for delay
    if (!delay && delay !== 0) {
      return
    }
    timerId = setTimeout(() => savedCallback.current(), delay)
    return () => reset()
  }, [delay, ...dependencies])

  return { timerId, reset }
}

export default useTimeout;