import { Context, Environment, Language } from './Context';
import production from '@lib/store-context/production';
// import staging from 'nlib/store-context/staging';

const environment: Environment = production;
// process.env.NEXT_PUBLIC_STAGING_ENV === 'true' ? staging : production;

export function getContext(language: Language | null): Context {
  const { contexts } = environment;
  const defaultContext: Context = contexts[0];
  const countryContext = contexts.find((context) => context.language === language);

  return countryContext || defaultContext;
}
