import { triggerGTMEvent } from '../..';

const tagViewWishList = () => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'view_wishlist',
    label: 'show wish list items',
  });
};
export default tagViewWishList;
