const LocationPin = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.9375 5.625C2.62812 5.625 2.375 5.37187 2.375 5.0625C2.375 3.5093 3.63359 2.25 5.1875 2.25C5.49687 2.25 5.75 2.50172 5.75 2.8125C5.75 3.12328 5.49687 3.375 5.1875 3.375C4.25586 3.375 3.5 4.13086 3.5 5.0625C3.5 5.37187 3.24688 5.625 2.9375 5.625ZM10.25 5.0625C10.25 7.66758 8.28125 9.81563 5.75 10.0934V17.4375C5.75 17.7469 5.49687 18 5.1875 18C4.87813 18 4.625 17.7469 4.625 17.4375V10.0934C2.09375 9.81563 0.125 7.66758 0.125 5.0625C0.125 2.26652 2.39152 0 5.1875 0C7.98242 0 10.25 2.26652 10.25 5.0625ZM5.1875 9C7.36367 9 9.125 7.23867 9.125 5.0625C9.125 2.88773 7.36367 1.125 5.1875 1.125C3.01133 1.125 1.25 2.88773 1.25 5.0625C1.25 7.23867 3.01133 9 5.1875 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LocationPin;
