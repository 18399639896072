import Router from 'next/router';
import NProgress from 'nprogress';

export default () => {
  if (process.browser) {
    NProgress.configure({ showSpinner: false });

    Router.onRouteChangeStart = () => {
      NProgress.start();
    };

    Router.onRouteChangeComplete = () => {
      NProgress.done();
    };

    Router.onRouteChangeError = () => {
      NProgress.done();
    };
  }
};
