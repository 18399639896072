/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
const ArrowLeft = ({ fill, ...props }: { fill?: string; className?: string }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.23437 0.160892L9.48787 0.414392C9.65568 0.582203 9.65568 0.853556 9.48787 1.02137L3.11465 7.39102H16.5359C16.7716 7.39102 16.9644 7.58382 16.9644 7.81947V8.17651C16.9644 8.41216 16.7716 8.60496 16.5359 8.60496H3.11465L9.48787 14.9782C9.65568 15.146 9.65568 15.4173 9.48787 15.5852L9.23437 15.8387C9.06656 16.0065 8.79521 16.0065 8.6274 15.8387L1.09021 8.30505C0.922403 8.13724 0.922403 7.86589 1.09021 7.69807L8.6274 0.164462C8.79878 -0.00691858 9.06656 -0.00691877 9.23437 0.160892Z"
        fill={fill ?? '#464544'}
      />
    </svg>
  );
};

export default ArrowLeft;
