import { searchClient } from 'utils/algolia';

const productIndex = searchClient.initIndex('products');

// eslint-disable-next-line import/prefer-default-export
export const getProductsFromAlgolia = async (skus, tags = []) => {
  const queryString = `sku:${skus.join(' OR sku:')}`;

  let algoliaProducts = [];
  await productIndex
    .browseObjects({
      attributesToRetrieve: ['title', 'handle', 'sku', 'price', 'product_image'],
      filters: queryString,
      analyticsTags: [...tags, 'products-prefill'],
      batch: (batch) => {
        algoliaProducts = algoliaProducts.concat(batch);
      },
    })
    .then(() => algoliaProducts)
    .catch((error) => {
      console.error(error);
    });

  return algoliaProducts.map((product) => ({
    ...product,
    image: product.product_image,
  }));
};
