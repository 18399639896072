import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';

import { distanceMeters } from 'containers/StoreFinder/Search/utils';
import { getConfiguration, initProgressBar, initShopify } from './utils';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';

const Context = React.createContext({
  deliveryStore: null,
  isStoreClickAndCollectEnabled: false,
  deliveryPostcode: {},
  isStoreWithinRange: false,
});

const StorefrontProvider = (props) => {
  const { config, globalProps, children } = props;

  // Init current context and
  const getInitialState = useCallback(() => getConfiguration(config), [config]);
  const [initialState] = getInitialState();
  const [storeFinder] = useStoreFinderStorage();
  
  // Init Shopify
  const getShopify = useCallback(() => initShopify(initialState));

  useEffect(() => {
    if (process.browser) {
      initProgressBar();
      window.dispatchEvent(new Event('storefront.start'));
    }
  }, []);

  const [deliveryStore, setDeliveryStore] = useState({});

  const [deliveryPostcode, setDeliveryPostcode] = useState(
    Cookies.get('postcode') ? JSON.parse(Cookies.get('postcode')) : {}
  );
  const [openSelectPostcode, setOpenSelectPostcode] = useState(false);
  const [openSelectStore, setOpenSelectStore] = useState(false);
  const [isStoreWithinRange, setIsStoreWithinRange] = useState(false);
  const [isStoreClickAndCollectEnabled, setIsStoreClickAndCollectEnabled] = useState(false);

  useEffect(() => {
    const storeCookie = Cookies.get('store') || null;
    const store = JSON.parse(storeCookie) || storeFinder;

    setDeliveryStore(store);
  }, [storeFinder]);

  useEffect(() => {
    // Check if selected store is within 10km of selected delivery postcode
    const distance = distanceMeters(
      deliveryPostcode?.geo?.lat,
      deliveryPostcode?.geo?.lng,
      deliveryStore?.geo?.lat,
      deliveryStore?.geo?.lng
    );
    if (distance <= 10000) {
      setIsStoreWithinRange(true);
    } else {
      setIsStoreWithinRange(false);
    }

    // check if the selected store is enabled for Click & Collect (onmeo location tags)
    setIsStoreClickAndCollectEnabled(deliveryStore?.storeTags?.includes('cnc-enabled'));
  }, [deliveryPostcode, deliveryStore]);

  const updateDeliveryStore = (store) => {
    Cookies.set('store', JSON.stringify(store), {
      expires: 6,
    });
    setDeliveryStore(store);
  };

  const updateDeliveryPostcode = (postcode) => {
    Cookies.set('postcode', JSON.stringify(postcode), {
      expires: 6,
    });
    setDeliveryPostcode(postcode);
  };

  const updateOpenSelectPostcode = (value) => {
    setOpenSelectPostcode(value);
  };

  const updateOpenSelectStore = (value) => {
    setOpenSelectStore(value);
  };

  return (
    <Context.Provider
      value={{
        shopify: getShopify(),
        deliveryStore,
        updateDeliveryStore,
        deliveryPostcode,
        updateDeliveryPostcode,
        openSelectPostcode,
        updateOpenSelectPostcode,
        openSelectStore,
        updateOpenSelectStore,
        isStoreWithinRange,
        isStoreClickAndCollectEnabled,
        globalProps,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StorefrontProvider;
export { Context as StorefrontContext };
