import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagRemoveFromWishList = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'select_content',
    category: 'remove_wishlist',
    label: label?.toLowerCase(),
  });
};
export default tagRemoveFromWishList;
