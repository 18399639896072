/* eslint-disable no-undef */
import { canUseDOM } from '@apollo/client/utilities';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

interface WithExpired<T> {
  value: T;
  expiry: number;
}

export type SetValue<T> = Dispatch<SetStateAction<T>>;

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}

interface StorageOptions {
  ttl?: number;
  withExpiry?: boolean;
}

const defaultStorageOption: StorageOptions = {
  ttl: 14400,
  withExpiry: true,
};

/**
 * @param ttl default 4 hours
 */
function useLocalStorage<T>(
  key: string,
  initialValue?: T,
  storageOption: StorageOptions = defaultStorageOption
): [T, SetValue<T>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const theStorageOption = { ...defaultStorageOption, ...storageOption };
  const { ttl, withExpiry } = theStorageOption;

  const readValue = useCallback((): T => {
    const now = new Date();

    // Prevent build error "window is undefined" but keeps working
    if (!canUseDOM) {
      return initialValue;
    }

    try {
      const itemStr = window.localStorage.getItem(key);
      let item = parseJSON<T | WithExpired<T>>(itemStr);
      if (!item) return initialValue;

      if(withExpiry) {
        item = item as WithExpired<T>
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
          // If the item is expired, delete the item from storage
          // and return null
          localStorage.removeItem(key);
          return null;
        }
        return item?.value;
      }else{
        return item as T;
      }
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = useCallback((value) => {
    const now = new Date();
    // Prevent build error "window is undefined" but keeps working
    if (!canUseDOM) {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      let item: T | WithExpired<T> | null = null;
      if (withExpiry) {
        item = {
          value: newValue,
          expiry: now.getTime() + ttl * 1000,
        };
      } else {
        item = newValue;
      }
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(item));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  }, []);

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}

export default useLocalStorage;
