const MapDirection = ({ ...props }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.42929 15.8263L8.5 15.897L8.57071 15.8263L15.8263 8.57071L15.897 8.5L15.8263 8.42929L8.57071 1.17371L8.5 1.103L8.42929 1.17371L1.17371 8.42929L1.103 8.5L1.17371 8.57071L8.42929 15.8263ZM10.2084 9.03275H4.44491V7.96724H10.2084H10.4499L10.2791 7.79653L7.73447 5.25186L8.5 4.48633L12.5137 8.5L8.5 12.5137L7.73447 11.7481L10.2791 9.20346L10.4499 9.03275H10.2084ZM7.66376 16.5918L0.40818 9.33624C0.205512 9.13357 0.1 8.85863 0.1 8.5C0.1 8.14137 0.205512 7.86643 0.40818 7.66376L7.66376 0.40818C7.86643 0.205512 8.14137 0.1 8.5 0.1C8.85863 0.1 9.13357 0.205512 9.33624 0.40818L16.5918 7.66376C16.7945 7.86643 16.9 8.14137 16.9 8.5C16.9 8.85863 16.7945 9.13357 16.5918 9.33624L9.33624 16.5918C9.13357 16.7945 8.85863 16.9 8.5 16.9C8.14137 16.9 7.86643 16.7945 7.66376 16.5918Z"
        fill="black"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default MapDirection;
