import cn from 'classnames';
import { FC, MouseEvent, useContext, useState } from 'react';
import Link from '@components/ui/Link';
import { useUI } from '@components/ui';
import { EventContext } from 'providers/EventProvider';

interface Props {
  children?: any;
  menuItems?: any[] | undefined;
  transparentHeader?: boolean;
  handleMegaMenuVisibility: (visible: boolean) => void;
  handleMegaMenuIndex: (index: number) => void;
  currentVisibility: boolean;
  currentIndex: number;
  linkColor: string;
}

const Navigation: FC<Props> = ({
  menuItems,
  transparentHeader,
  handleMegaMenuIndex,
  handleMegaMenuVisibility,
  currentIndex,
  linkColor,
}) => {
  const [navHovered, setNavHovered] = useState(false);
  const { closeSidebar } = useUI();
  const { gtm } = useContext(EventContext);

  const handleCloseMegaMenu = () => {
    if (handleMegaMenuVisibility) {
      handleMegaMenuVisibility(false);
    }
  };

  const closeMenu = (navLabel: string) => {
    handleCloseMegaMenu();
    closeSidebar();
    gtm.tagClickMenuItem({ label: navLabel });
  };

  return (
    <>
      <ul className="flex items-center label text-white text-body2 desktop:text-body1">
        {menuItems?.map((nav, index) => {
          const setMegaNav = () => {
            if (nav?.items?.length) {
              handleMegaMenuVisibility(true);
              handleMegaMenuIndex(index);
            }
            setNavHovered(true);
          };

          const resetMegaNav = (e: MouseEvent) => {
            if (nav.link) setNavHovered(false);
            if (e.pageY < 155) {
              handleMegaMenuIndex(-1);
              handleMegaMenuVisibility(false);
              setNavHovered(false);
            }
          };

          return (
            <li
              key={nav.label}
              className="first:pl-0 pl-3 pr-3 relative whitespace-nowrap"
              onMouseOver={setMegaNav}
              onMouseLeave={resetMegaNav}
            >
              <div
                className={cn('mt-0.5 py-3 cursor-pointer hover:text-brand-yellow', {
                  'text-brand-yellow': currentIndex === index && navHovered,
                })}
              >
                {nav?.link ? (
                  <Link href={nav.link} legacyBehavior prefetch={false}>
                    <a onClick={() => closeMenu(nav.label)}>{nav.label}</a>
                  </Link>
                ) : (
                  <div className="cursor-pointer">{nav.label}</div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Navigation;
