import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const SimpleLink = ({ children, linkUrl, title, styles, className, onClick, testId }) => {
  if (!linkUrl) return null;
  const externalLink = !!linkUrl.match(/https?:\/\//g);

  if (externalLink) {
    return (
      <Link href={linkUrl} legacyBehavior>
        <a
          title={title || ''}
          style={styles}
          className={className}
          onClick={onClick}
          rel="noreferrer"
          target="_blank"
          data-testid={testId}
        >
          {children}
        </a>
      </Link>
    );
  }
  return (
    <Link href={linkUrl}>
      <div
        title={title || ''}
        style={styles}
        className={className}
        onClick={onClick}
        data-testid={testId}
      >
        {children}
      </div>
    </Link>
  );
};

SimpleLink.propTypes = {
  children: PropTypes.any,
  linkUrl: PropTypes.string,
  title: PropTypes.string,
  styles: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

SimpleLink.defaultProps = {
  children: null,
  linkUrl: null,
  title: null,
  styles: null,
  className: null,
  onClick: null,
  target: 'self',
  testId: null,
};

export default SimpleLink;
