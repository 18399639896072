import React, { createContext } from 'react';
import GTMTags, { IGTMTags } from './gtm';

interface EventTags {
  gtm: IGTMTags;
}

interface EventProviderProp {
  children: React.ReactChildren;
}

export const EventContext = createContext<EventTags | null>(null);

const EventProvider = ({ children }: EventProviderProp) => {
  const value = {
    gtm: GTMTags,
  };

  return (
    <EventContext.Provider value={value}>
      <>{children}</>
    </EventContext.Provider>
  );
};

export default EventProvider;
