import MegaMenu from '@components/Common/MegaMenu';
import { ArrowLeft, ChevronRight, CrossGrey } from '@components/icons';
import SimpleLink from '@components/SimpleLink';
import { useUI } from '@components/ui/context';
import SubscribeAndSave from '@containers/Layout/Header/SubscribeAndSave';
import { groupNavItems } from '@lib/helpers/group-nav-items';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';

interface Props {
  menuItems?: any;
}

// const additionalMenus = [
//   { title: 'Find Your Nearest Store', link: '/stores' },
//   { title: 'Price Guarantee', link: '/customerservice/lowest-price-guarantee' },
//   { title: 'Delivery FAQs', link: '/customerservice/online-same-day-delivery-faq' },
//   { title: 'Order Online', link: '/doordash' },
// ];

const MenuSidebarView: FC<Props> = ({ menuItems = [] }) => {
  const { closeSidebar, toggleSidebar, setSidebarView } = useUI();
  const router = useRouter();
  const groupedMenuItems = groupNavItems(menuItems?.data?.slices);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [megaMenuIndex, setMegaMenuIndex] = useState(-1);

  const additionalMenus = menuItems?.data?.slices2[0]?.items ?? [];

  const hideMegaMenu = () => {
    setMegaMenuIndex(-1);
    setShowMegaMenu(false);
  };

  const setMegaMenuVisibility = (value: boolean) => {
    setShowMegaMenu(value);
  };

  const handleOpenSubscribeFlow = () => {
    setSidebarView('USER_PORTAL_VIEW');
  };

  const toggleMenu = () => {
    setSidebarView('MOBILE_MENU_VIEW');
    toggleSidebar();
  };

  return (
    <div className="flex flex-col">
      <div className="transition-all duration-200 p-2 bg-brand-dark-red">
        <div className="bg-[#9508104D] w-full">
          <SubscribeAndSave onToggle={handleOpenSubscribeFlow} />
        </div>
      </div>
      <div className="wrapper pb-10 bg-white w-full">
        <nav>
          {!showMegaMenu && (
            <>
              <div className="borderBottom relative items-center flex">
                <span className="flex w-full justify-center py-4 text-body2 text-ui-darkest-grey uppercase">
                  MENU
                </span>
                <button
                  onClick={toggleMenu}
                  aria-label="Close"
                  className="absolute right-0 bg-white hover:text-accent-5 transition ease-in-out duration-150 pr-4"
                >
                  <CrossGrey className="hover:text-accent-3" />
                </button>
              </div>
              <ul className="space-y-4 px-5 py-6">
                {groupedMenuItems.map((nav, index) => {
                  const hasSubMenu = nav.type === 'MegaNav';

                  const openMegaMenu = () => {
                    if (hasSubMenu) {
                      setMegaMenuIndex(index);
                      setShowMegaMenu(true);
                    } else {
                      closeSidebar();
                      router.push(nav.link);
                    }
                  };

                  return (
                    <li
                      className="text-body2 text-ui-dark-grey flex justify-between"
                      key={nav.label}
                      onClick={openMegaMenu}
                    >
                      <div>{nav.label}</div>
                      {hasSubMenu && (
                        <div className="mt-1 bg-white">
                          <ChevronRight />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
              <ul className="borderTop space-y-3 px-5 py-6">
                {additionalMenus.length &&
                  additionalMenus.map((menu) => {
                    return (
                      <li
                        className="text-body2 text-ui-dark-grey flex justify-between"
                        key={menu.title}
                        onClick={toggleMenu}
                      >
                        <SimpleLink linkUrl={menu.link}>{menu.title}</SimpleLink>
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
          {showMegaMenu && (
            <div>
              <div className="borderBottom relative wrapper flex justify-between bg-white">
                <button className="bg-white" onClick={hideMegaMenu}>
                  <ArrowLeft />
                </button>
                <div className="mx-auto body-3-bold py-4 text-ui-dark-grey uppercase">
                  {groupedMenuItems[megaMenuIndex]?.label}
                </div>
                <button
                  onClick={toggleMenu}
                  aria-label="Close"
                  className="bg-white hover:text-accent-5 transition ease-in-out duration-150"
                >
                  <CrossGrey className="hover:text-accent-3" />
                </button>
              </div>
              <MegaMenu
                handleMegaMenuVisibility={setMegaMenuVisibility}
                menuItems={groupedMenuItems[megaMenuIndex]?.items}
              />
            </div>
          )}
        </nav>
        <style jsx>
          {`
            .borderBottom {
              border-bottom: 1px solid #fafafa;
            }
            .borderTop {
              border-top: 1px solid #fafafa;
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default MenuSidebarView;
