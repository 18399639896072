import { IGTMTagsProps, triggerGTMEvent } from '../..';

const tagClickSuggestedSearchTerm = ({ label }: IGTMTagsProps) => {
  triggerGTMEvent({
    event: 'search',
    category: 'suggested_search_term',
    label: label?.toLowerCase(),
  });
};

export default tagClickSuggestedSearchTerm;
